import React, { useEffect } from 'react';
import { Image15, Image16, Image17 } from '../../../assets/images/web-isn';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../home.action';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../../utils/params';

const HomeAboutUs = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const { t } = useTranslation();
  const history = useHistory();

  const gotoAboutUs = () => {
    history.push(`/${language}/about-us`);
  };

  useEffect(() => {
    props.fetchAboutUsRequest({ language });
    //eslint-disable-next-line
  }, []);

  const { aboutUsContent } = props.home || {};

  function createMarkup(data) {
    if (data) {
      return { __html: data };
    }
  }

  return (
    <div className="section-block">
      <div className="container">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6 about-us-path text-left">
            <h3 className="about-title d-none d-lg-block">{t('home_page.label_about_us')}</h3>
            <div className="under-line d-none d-lg-block mt-3 mb-4">
              <span className="middle-line"></span>
            </div>
            <p className="about-content mt-3 mt-lg-0" dangerouslySetInnerHTML={createMarkup(aboutUsContent ? aboutUsContent.overview : '')}></p>
            <div className="d-flex">
            <button onClick={() => gotoAboutUs()} className="btn-about mx-auto mx-lg-0 mt-5">
              {t('home_page.btn_learnmore')}
            </button>
            </div>
          </div>
          <div className="col-12 col-lg-6 px-0">
            <div className="d-flex align-items-center">
              <div className="col-5">
                <img className="about-icon" src={Image15} alt="about"></img>
              </div>
              <div className="col-7 d-flex flex-column pl-0">
                <img className="about-icon" src={Image17} alt="about"></img>
                <img className="mt-3" style={{ width: '70%' }} src={Image16} alt="about"></img>
              </div>
            </div>
          </div>
          <div className="col-12 service-apartment d-block d-lg-none about-us-path">
            <h3 className={`service-title ${language === 'jp' ? 'font-noto-serif-cjk-jp' : 'SVN-Quiche Sans'}`}>{t('home_page.label_about_us')}</h3>
            <div className="under-line mt-3 mb-5 mx-auto">
              <span className="middle-line"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAboutUsRequest: actions.fetchAboutUsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeAboutUs);
