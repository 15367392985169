import React from 'react';
import ReactTooltip from 'react-tooltip';

const Input = ({
  register,
  name,
  label,
  errors,
  placeholder,
  id,
  class_name = 'form-control',
  type = 'text',
  required,
  src,
  tooltip,
  maxLength
}) => {
  return (
    <>
      <div className="form-input-group">
        {!!label && (
          <label>
            {label}
            {!!required && <span className="link-red">*</span>}
          </label>
        )}
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          ref={register}
          className={`${class_name} ${(!!errors && 'input-valid') || ''}`}
          data-tip={tooltip}
          maxLength={maxLength}
        />
        {errors && (
          <span className="error">
            <small>{errors.message}</small>
          </span>
        )}
        <img src={src} alt={`${src}-icon`}></img>
      </div>
      <ReactTooltip />
    </>
  );
};

export default Input;
