import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import loadingReducer from '../layouts/loading/loading.reducer';

import { homeReducer } from '../modules/homePage';
import { layoutReducer } from '../layouts';
import { forOwnersReducer } from '../modules/forOwnersPage';
import { favoritesReducer } from '../modules/favoriteHistoryPage';
import { specialReducer } from '../modules/specialPage';
import { forgotPasswordReducer } from '../modules/forgotPassword';
import { signUpReducer } from '../modules/signUp';
import { loginReducer } from '../modules/loginPage';
import { detailApartmentReducer } from '../modules/detailApartmentPage';
import accountPageReducer from '../modules/accountPage/accountPage.reducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),

    home: homeReducer,
    layout: layoutReducer,
    loading: loadingReducer,
    forOwners: forOwnersReducer,
    favorites: favoritesReducer,
    special: specialReducer,
    loginData: loginReducer,
    forgotPassword: forgotPasswordReducer,
    signUp: signUpReducer,
    detailApartmentData: detailApartmentReducer,
    accountInfo: accountPageReducer,
  });

export default rootReducer;
