const ARROW_LEFT = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline></svg>`;
const ARROW_RIGHT = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline></svg>`;

const ICON_LEFT = `<svg xmlns="http://www.w3.org/2000/svg"
                    id="prefix__Icon_chevron-left" width="24" height="24" viewBox="0 0 24 24">
                    <path id="prefix__Rectangle_679" fill="none" d="M0 0H24V24H0z" data-name="Rectangle 679"/>
                    <path id="prefix__chevron-left" fill="none" stroke="#131313" stroke-linecap="round"
                    stroke-linejoin="round" d="M15 18l-6-6 6-6" transform="translate(.5 .5)"/></svg>`;
const ICON_RIGHT = `<svg xmlns="http://www.w3.org/2000/svg" id="prefix__Icon_chevron-left"
                    width="24" height="24" viewBox="0 0 24 24" style="transform: rotate(180deg);">
                    <path id="prefix__Rectangle_679" fill="none" d="M0 0H24V24H0z" data-name="Rectangle 679"/>
                    <path id="prefix__chevron-left" fill="none" stroke="#131313" stroke-linecap="round"
                    stroke-linejoin="round" d="M15 18l-6-6 6-6" transform="translate(.5 .5)"/></svg>`;

const NAV_LEFT = `<svg width="66" height="100" viewBox="0 0 66 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path d="M20.5894 51.6371L41.0438 78.9089C41.9478 80.114 43.6569 80.358 44.862 79.4544C46.0671 78.5506 46.3113 76.8411 45.4074 75.6362L26.1803 50.0006L45.4075 24.3638C46.3113 23.1589 46.0669 21.4493 44.862 20.5457C44.3715 20.1777 43.7973 20 43.2278 20C42.3986 20 41.5797 20.3768 41.0438 21.0911L20.5894 48.3644C19.8622 49.334 19.8622 50.6675 20.5894 51.6371Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d" x="0.0429688" y="0" width="65.9092" height="100" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`;

const PROJECT_NAV_LEFT = `<svg width="17" height="40" viewBox="0 0 17 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4655 19.2637L3.22164 1.60567C2.63632 0.825396 1.52972 0.667412 0.749455 1.2525C-0.0308152 1.8377 -0.188918 2.94453 0.396285 3.72469L12.8455 20.3233L0.396285 36.9227C-0.188918 37.7028 -0.0306975 38.8098 0.749455 39.3948C1.06707 39.6331 1.43884 39.7481 1.80755 39.7481C2.34449 39.7481 2.87471 39.5042 3.22164 39.0417L16.4655 21.3827C16.9364 20.7549 16.9364 19.8915 16.4655 19.2637Z" fill="white"/>
</svg>`

const PROJECT_NAV_RIGHT = `<svg width="17" height="40" viewBox="0 0 17 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.397773 20.7363L13.6416 38.3943C14.227 39.1746 15.3336 39.3326 16.1138 38.7475C16.8941 38.1623 17.0522 37.0555 16.467 36.2753L4.01776 19.6767L16.467 3.07735C17.0522 2.29719 16.894 1.19024 16.1138 0.605157C15.7962 0.366885 15.4244 0.251869 15.0557 0.251869C14.5188 0.251869 13.9886 0.495792 13.6416 0.958327L0.397773 18.6173C-0.0731196 19.2451 -0.0731197 20.1085 0.397773 20.7363Z" fill="white"/>
</svg>`

const NAV_RIGHT = `<svg width="66" height="100" viewBox="0 0 66 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path d="M45.4106 48.3629L24.9562 21.0911C24.0522 19.886 22.3431 19.642 21.138 20.5456C19.9329 21.4494 19.6887 23.1589 20.5926 24.3638L39.8197 49.9994L20.5926 75.6362C19.6887 76.8411 19.9331 78.5507 21.138 79.4543C21.6285 79.8223 22.2027 80 22.7722 80C23.6014 80 24.4203 79.6232 24.9562 78.9089L45.4106 51.6356C46.1379 50.666 46.1379 49.3325 45.4106 48.3629Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d" x="0.046875" y="0" width="65.9092" height="100" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`;

const NEXT_SERVICED_BANNER = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="24" transform="rotate(-180 24 24)" fill="black" fill-opacity="0.8"/>
<path d="M20 14L30 24L20 34" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const PRE_SERVICED_BANNER = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="24" fill="black" fill-opacity="0.8"/>
<path d="M28 34L18 24L28 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export {
  ARROW_LEFT,
  ARROW_RIGHT,
  ICON_LEFT,
  ICON_RIGHT,
  isMobile,
  NAV_LEFT,
  NAV_RIGHT,
  NEXT_SERVICED_BANNER,
  PRE_SERVICED_BANNER,
  PROJECT_NAV_LEFT,
  PROJECT_NAV_RIGHT
};
