import { put, takeEvery, call } from 'redux-saga/effects';
import { get } from 'lodash';

import * as actions from './favorite.action';
import * as types from './favorite.types';
import * as API from './favorite.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';
import Service from '../../services';

export function* fetchFavorites(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const favorites = yield call(API.fetchFavorites, getQueryString(params));
    yield put(actions.fetchFavoritesSuccess({ favorites }));
  } catch (error) {
    yield put(actions.fetchFavoritesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* postContactFavorite(action) {
  const { formSubmit, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));
    yield Service.isnPost(`/api/contacts`, formSubmit, status => {
      if (status === 200) onSuccess();
    });
  } catch (error) {
    const messageError = get(error, 'response.data.message', null);
    if (messageError) onError(messageError);
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchHistories(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const histories = yield call(API.fetchFavorites, getQueryString(params));
    yield put(actions.fetchHistoriesSuccess({ histories }));
  } catch (error) {
    yield put(actions.fetchHistoriesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* postContactHistory(action) {
  const { formSubmit, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));
    yield Service.isnPost(`/api/contacts`, formSubmit, status => {
      if (status === 200) onSuccess();
    });
  } catch (error) {
    const messageError = get(error, 'response.data.message', null);
    if (messageError) onError(messageError);
  } finally {
    yield put(setLoading(false));
  }
}

export function* favoritesSaga() {
  yield takeEvery(types.FETCH_FAVORITES_REQUEST, fetchFavorites);
  yield takeEvery(types.FETCH_HISTORIES_REQUEST, fetchHistories);
  yield takeEvery(types.POST_CONTACT_FAVORITE_REQUEST, postContactFavorite);
  yield takeEvery(types.POST_CONTACT_HISTORY_REQUEST, postContactHistory);
}
