import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { getLanguage } from 'utils/params';
import { postContactUsRequest } from '../detailApartment.api';
import { Modal } from 'react-bootstrap';
import { InputDate } from 'components';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { Close } from '../../../assets/images/web-isn';
import IconContact from '../../../assets/images/Group2676.svg';
import { toast } from 'react-toastify';
import moment from 'moment';

const ContactUs = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const [isShowSubmit, setIsShowSubmit] = useState(false);
  const [responseForm, setResponseForm] = useState(null);
  const [date, setDate] = useState({
    dateFrom: moment().format('L'),
    dateTo: moment().format('L'),
  });
  const dateFormat = language === 'en' ? 'MM/dd/yyyy ' : language === 'vi' ? 'dd/MM/yyyy' : 'yyyy/MM/dd';
  const today = new Date();
  const [captchaToken, setCaptchaToken] = useState(null);
  const recaptchaRef = useRef();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [inputData, setInputData] = useState({
    email: '',
    phone: '',
    fullName: '',
    companyName: '',
    description: '',
    apartmentName: '',
  });
  const [errors, setErrors] = useState({});
  const userInfo = useSelector(state => state.loginData.userInfo);
  const apartmentName = localStorage.getItem('apartmentName');

  useEffect(() => {
    const authUserData = JSON.parse(localStorage.getItem('authUserData'));
    let user = userInfo ?? authUserData?.userInfo;

    if (user) {
      setInputData({
        ...inputData,
        fullName: user?.fullName ? user.fullName : '',
        email: user?.email ?? user?.userEmail ?? '',
        phone: user?.phoneNumber ?? user?.userPhone ?? '',
        companyName: user?.companyName ?? '',
      });
    } else {
      setInputData({
        email: '',
        phone: '',
        fullName: '',
        companyName: '',
        description: '',
        apartmentName: '',
      });
    }
    setResponseForm(false);
    setErrors(null);
    // eslint-disable-next-line
  }, [userInfo, props]);

  const handleChangeInput = e => {
    if (responseForm) {
      setResponseForm(false);
    }
    let { name } = e.target;
    let { value } = e.target;
    if (name === 'phone') {
      value = value.replace(/[^\d/+-]/g, '');
      value = value.replace(/^([+0-9]{3})([0-9]{3})([0-9]{4})$/, '$1$2$3');
    }
    setInputData({
      ...inputData,
      [name]: value,
    });
    if (name === 'fullName') {
      if (value.trim().length === 0) {
        setErrors({
          ...errors,
          fullName: t('about_isn.contact_us.err_fullname'),
        });
      } else {
        resetError(name);
      }
    }
    if (name === 'email') {
      if (value.trim().length === 0) {
        setErrors({
          ...errors,
          email: t('about_isn.contact_us.err_email'),
        });
      } else {
        resetError(name);
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          setErrors({
            ...errors,
            email: t('about_isn.contact_us.err_email_format'),
          });
        }
      }
    }
    if (name === 'phone') {
      if (value.trim().length === 0) {
        setErrors({
          ...errors,
          phone: t('about_isn.contact_us.err_phone_number'),
        });
      } else {
        resetError(name);
        if (value.length < 7 || value.length > 15) {
          setErrors({
            ...errors,
            phone: t('about_isn.contact_us.err_phone_format'),
          });
        }
      }
    }
  };

  const checkValidate = () => {
    let error = null;
    if (inputData.fullName.trim().length === 0) {
      error = { ...error, fullName: t('about_isn.contact_us.err_fullname') };
    }
    if (inputData.email.trim().length === 0) {
      error = { ...error, email: t('about_isn.contact_us.err_email') };
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputData.email)) {
        error = { ...error, email: t('about_isn.contact_us.err_email_format') };
      }
    }
    if (inputData.phone.trim().length === 0) {
      error = { ...error, phone: t('about_isn.contact_us.err_phone_number') };
    } else {
      if (inputData.phone.length < 7 || inputData.phone.length > 15) {
        error = { ...error, phone: t('about_isn.contact_us.err_phone_format') };
      }
    }
    setErrors(error);
    return error == null;
  };

  const resetError = name => {
    let errorNew = errors;
    if (errors && errors[name]) {
      delete errorNew[name];
    }
    setErrors({ ...errorNew });
  };

  const resetForm = () => {
    const authUserData = JSON.parse(localStorage.getItem('authUserData'));
    let user = userInfo ?? authUserData?.userInfo;
    setInputData({
      email: user?.email ?? user?.userEmail ?? '',
      phone: user?.phoneNumber ?? user?.userPhone ?? '',
      companyName: user?.companyName ?? '',
      fullName: user?.fullName ? user.fullName : '',
      description: '',
      apartmentName: '',
    });
    setDate({
      dateFrom: moment().format('L'),
      dateTo: moment().format('L'),
    });
    setIsShowSubmit(false);
    recaptchaRef.current.reset();
  };

  const messageSuccess = (
    <>
      <div>{t('about_isn.contact_us.message_send_success')}</div>
    </>
  );

  const onSubmit = values => {
    if (checkValidate()) {
      let FormData = require('form-data');
      let data = new FormData();
      data.append('CommonId', props.commonId ?? null);
      data.append('Language', language);
      data.append('FullName', values.fullName);
      data.append('Email', values.email);
      data.append('PhoneNumber', values.phone);
      data.append('CompanyName', values.companyName);
      data.append('ApartmentName', values.apartmentName);
      data.append('MoveIn', date.dateFrom ? date.dateFrom : moment());
      data.append('TourInspection', date.dateTo ? date.dateTo : moment());
      data.append('Content', values.description);
      data.append('CaptchaToken', captchaToken);
      showLoader();
      postContactUsRequest(data).then(res => {
        if (res) {
          setResponseForm(res.status);
          resetForm();
          toast.success(messageSuccess, { autoClose: 5000 });
        }
        hideLoader();
      });
    }
  };

  const onChangeDatePicker = (key, value) => {
    if (responseForm) {
      setResponseForm(false);
    }
    if (key === 'dateFrom') setDate({ ...date, ...{ dateFrom: moment(value).format('L') } });
    else if (key === 'dateTo') setDate({ ...date, ...{ dateTo: moment(value).format('L') } });
  };

  const checkCaptcha = value => {
    if (responseForm) {
      setResponseForm(false);
    }
    if (value) {
      setCaptchaToken(value);
      setIsShowSubmit(true);
    }
  };

  const handleCloseModal = () => {
    props.closeLogin();
  };

  return (
    <>
      <Modal
        dialogClassName={'contact-us-modal'}
        centered
        show={props.showLogin}
        backdrop="static"
        onHide={handleCloseModal}
      >
        <div className="close-btn" onClick={handleCloseModal}>
          <img src={Close} alt="close-icon" />
        </div>
        <div className="container">
          <div className="contact-form">
            <div className="contact-form-head">
              <div className="row">
                <div className="col-9">
                  <div
                    className="contact-form-title jp-bold-600"
                    dangerouslySetInnerHTML={{
                      __html: t('about_isn.contact_us.title_contact'),
                    }}
                  ></div>
                </div>
                <div className="col-3 text-right">
                  <img src={IconContact} alt="" />
                  {/* <LazyLoadImage src={IconContact} alt="" effect="blur" /> */}
                </div>
                <div className="col-9 jp-font-sawarabi-gothic">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('about_isn.contact_us.content_thank_you'),
                    }}
                  ></div>
                  <div
                    className="contact-content"
                    dangerouslySetInnerHTML={{
                      __html: t('about_isn.contact_us.our_person'),
                    }}
                  ></div>
                  <hr className="contact-form-line" />
                </div>
              </div>
            </div>
            <form
              id="contact"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <div className="form-group">
                <label>
                  {t('about_isn.contact_us.full_name')} <span className="link-red-bold ">*</span>
                </label>
                <input
                  type="text"
                  className={'form-control' + (errors?.fullName ? ' is-error' : '')}
                  name="fullName"
                  onChange={e => {
                    handleChangeInput(e);
                  }}
                  value={inputData.fullName}
                  maxLength={255}
                />
                <small className="error">{errors?.fullName && errors.fullName}</small>
              </div>

              <div className="form-group">
                <label>
                  {t('about_isn.contact_us.email')} <span className="link-red-bold ">*</span>
                </label>
                <input
                  type="text"
                  className={'form-control' + (errors?.email ? ' is-error' : '')}
                  name="email"
                  value={inputData.email}
                  onChange={handleChangeInput}
                  maxLength={255}
                />
                <small className="error">{errors?.email && errors?.email}</small>
              </div>

              <div className="form-group">
                <label>
                  {t('about_isn.contact_us.phone_number')} <span className="link-red-bold ">*</span>
                </label>
                <input
                  type="text"
                  className={'form-control' + (errors?.phone ? ' is-error' : '')}
                  name="phone"
                  value={inputData.phone}
                  onChange={handleChangeInput}
                  maxLength={255}
                />
                <small className="error">{errors?.phone && errors?.phone}</small>
              </div>

              <div className="form-group">
                <label>{t('about_isn.contact_us.company_name')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  value={inputData.companyName}
                  onChange={handleChangeInput}
                  maxLength={255}
                />
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <InputDate
                    class_name="form-control datetime"
                    formName="dateFrom"
                    name="dateA"
                    label={t('about_isn.contact_us.check_in_date')}
                    onChange={onChangeDatePicker}
                    dateFormat={dateFormat}
                    minDate={today}
                  />
                </div>
                <div className="col-12 col-md-6 ">
                  <InputDate
                    class_name="form-control datetime"
                    formName="dateTo"
                    name="dateB"
                    label={t('about_isn.contact_us.inspection_date')}
                    onChange={onChangeDatePicker}
                    dateFormat={dateFormat}
                    minDate={today}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>{t('detailApartment.apartment_name')}</label>
                <input
                  type="text"
                  className={'form-control'}
                  name="apartmentName"
                  value={apartmentName}
                  maxLength={255}
                  disabled
                />
              </div>

              <div className="form-group">
                <label>{t('about_isn.contact_us.other_requirements')}</label>
                <textarea
                  className={'form-control area-requirement' + (errors?.description ? ' is-error' : '')}
                  name="description"
                  onChange={handleChangeInput}
                  value={inputData.description}
                ></textarea>
              </div>

              <div className="row">
                <div className="col-12 pb-2">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                    onChange={checkCaptcha}
                    onExpired={() => {
                      setIsShowSubmit(false);
                    }}
                    hl={`${language === 'vi' ? 'vi' : language === 'en' ? 'en' : 'ja'}`}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 button-handle d-flex justify-content-center">
                  <button
                    type="submit"
                    id="send"
                    className="btn-booknow"
                    disabled={!isShowSubmit}
                    onClick={() => {
                      onSubmit(inputData);
                    }}
                  >
                    {t('about_isn.contact_us.send')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {loader}
    </>
  );
};
const mapStateToProps = state => ({
  ...state,
});

export default connect(mapStateToProps)(ContactUs);
