const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

const FETCH_REFRESH_TOKEN_REQUEST = 'FETCH_REFRESH_TOKEN_REQUEST';
const FETCH_REFRESH_TOKEN_SUCCESS = 'FETCH_REFRESH_TOKEN_SUCCESS';
const FETCH_REFRESH_TOKEN_FAILURE = 'FETCH_REFRESH_TOKEN_FAILURE';

const FETCH_LOGIN_BY_GOOGLE_SUCCEEDED = 'FETCH_LOGIN_BY_GOOGLE_SUCCEEDED';

const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';

export {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_SUCCESS,
  FETCH_REFRESH_TOKEN_FAILURE,
  FETCH_LOGOUT_SUCCESS,
  FETCH_LOGIN_BY_GOOGLE_SUCCEEDED
};
