import Service from '../../services';

export const fetchVerifyEmailRequested = async params => {
  const response = await Service.isnPost(`/api/user/check-email-is-exist`, params, res => res);
  return response;
};

export const fetchVerifyKeyEmailRequested = async params => {
  const response = await Service.isnPost(`/api/user/verify-key-email`, params, res => res);
  return response;
};

export const fetchCompleteSignUpRequested = async params => {
  const response = await Service.isnPost(`/api/user/register`, params, res => res);
  return response;
};

export const fetchLoginByGoogleRequested = async params => {
  const response = await Service.isnPost(`/api/user/signin-social`, params, res => res);
  return response;
};
