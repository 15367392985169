import { put, call, takeEvery } from 'redux-saga/effects';
import * as types from './signUp.types';
import * as actions from './signUp.action';
import * as API from './signUp.api';
import { setLoading } from '../../layouts/loading/loading.action';

export function* fetchVerifyEmailRequested(action) {
  const { info, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchVerifyEmailRequested, info);
    yield put(actions.fetchVerifyEmailSucceeded(data));
    onSuccess(data.data.isExist);
  } catch (error) {
    yield put(actions.fetchVerifyEmailFailed(error));
    onError(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchVerifyKeyEmailRequested(action) {
  const { info, verifySuccess, verifyFail } = action.params;
  try {
    yield put(setLoading(true));
    const verifyKey = yield call(API.fetchVerifyKeyEmailRequested, info);
    yield put(actions.fetchVerifyKeyEmailSucceeded(verifyKey));
    verifySuccess(verifyKey);
  } catch (error) {
    yield put(actions.fetchVerifyKeyEmailFailed(error));
    verifyFail(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchCompleteSignUpRequested(action) {
  const { userInfo, signupComplete, signupFailed } = action.params;
  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchCompleteSignUpRequested, userInfo);
    yield put(actions.fetchCompleteSignUpSucceeded(data));
    signupComplete(data.data);
  } catch (error) {
    yield put(actions.fetchCompleteSignUpFailed(error));
    signupFailed(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchLoginByGoogleRequested(action) {
  const { info, loginSocialOK, loginSocailFail } = action.params;
  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchLoginByGoogleRequested, info);
    loginSocialOK(data.data);
    yield put(actions.fetchLoginByGoogleSucceeded(data));
  } catch (error) {
    yield put(actions.fetchLoginByGoogleFailed(error));
    loginSocailFail(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* signUpSaga() {
  yield takeEvery(types.FETCH_VERIFY_EMAIL_REQUESTED, fetchVerifyEmailRequested);
  yield takeEvery(types.FETCH_VERIFY_KEY_EMAIL_REQUESTED, fetchVerifyKeyEmailRequested);
  yield takeEvery(types.FETCH_COMPLETE_SIGNUP_REQUESTED, fetchCompleteSignUpRequested);
  yield takeEvery(types.FETCH_LOGIN_BY_GOOGLE_REQUESTED, fetchLoginByGoogleRequested);
}
