import * as types from './detailApartment.types';

const initialState = {
  error: null,
  bannerInfo: {},
  overviewInfo: {},
  amenitiesInfo: {},
  locationInfo: {},
  galleryInfo: {},
  ratingInfo: {},
  reviewInfo: {},
};

const detailApartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    /*<---- Get Banner data---->*/
    case types.GET_BANNER_REQUEST:
      return { ...state };

    case types.GET_BANNER_SUCCESS: {
      return { ...state, bannerInfo: action.data };
    }

    case types.GET_BANNER_FAILURE:
      return { ...state, error: action.error };

    /*<---- Get Overview data---->*/
    case types.GET_OVERVIEW_REQUEST:
      return { ...state };

    case types.GET_OVERVIEW_SUCCESS: {
      return { ...state, overviewInfo: action.data };
    }

    case types.GET_OVERVIEW_FAILURE:
      return { ...state, error: action.error };

    /*<---- Get Amenities data---->*/
    case types.GET_AMENITIES_REQUEST:
      return { ...state };

    case types.GET_AMENITIES_SUCCESS: {
      return { ...state, amenitiesInfo: action.data };
    }

    case types.GET_AMENITIES_FAILURE:
      return { ...state, error: action.error };

    /*<---- Get Location data---->*/
    case types.GET_LOCATION_REQUEST:
      return { ...state };

    case types.GET_LOCATION_SUCCESS: {
      return { ...state, locationInfo: action.data };
    }

    case types.GET_LOCATION_FAILURE:
      return { ...state, error: action.error };

    /*<---- Get Location data---->*/
    case types.GET_GALLERY_REQUEST:
      return { ...state };

    case types.GET_GALLERY_SUCCESS: {
      return { ...state, galleryInfo: action.data };
    }

    case types.GET_GALLERY_FAILURE:
      return { ...state, error: action.error };

    /*<---- Get Rating data---->*/
    case types.GET_RATING_REQUEST:
      return { ...state };

    case types.GET_RATING_SUCCESS: {
      return { ...state, ratingInfo: action.data };
    }

    case types.GET_RATING_FAILURE:
      return { ...state, error: action.error };

    /*<---- Get Review data---->*/
    case types.GET_REVIEW_REQUEST:
      return { ...state };

    case types.GET_REVIEW_SUCCESS: {
      return { ...state, reviewInfo: action.data };
    }

    case types.GET_REVIEW_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default detailApartmentReducer;
