const FETCH_RESET_PASSWORD_REQUESTED = 'FETCH_RESET_PASSWORD_REQUESTED';
const FETCH_RESET_PASSWORD_SUCCEEDED = 'FETCH_RESET_PASSWORD_SUCCEEDED';
const FETCH_RESET_PASSWORD_FAILED = 'FETCH_RESET_PASSWORD_FAILED';

const FETCH_VERIFY_KEY_FORGOT_REQUESTED = 'FETCH_VERIFY_KEY_FORGOT_REQUESTED';
const FETCH_VERIFY_KEY_FORGOT_SUCCEEDED = 'FETCH_VERIFY_KEY_FORGOT_SUCCEEDED';
const FETCH_VERIFY_KEY_FORGOT_FAILED = 'FETCH_VERIFY_KEY_FORGOT_FAILED';

const FETCH_NEW_PASSWORD_REQUESTED = 'FETCH_NEW_PASSWORD_REQUESTED';
const FETCH_NEW_PASSWORD_SUCCEEDED = 'FETCH_NEW_PASSWORD_SUCCEEDED';
const FETCH_NEW_PASSWORD_FAILED = 'FETCH_NEW_PASSWORD_FAILED';

export {
  FETCH_RESET_PASSWORD_REQUESTED,
  FETCH_RESET_PASSWORD_SUCCEEDED,
  FETCH_RESET_PASSWORD_FAILED,
  FETCH_VERIFY_KEY_FORGOT_REQUESTED,
  FETCH_VERIFY_KEY_FORGOT_SUCCEEDED,
  FETCH_VERIFY_KEY_FORGOT_FAILED,
  FETCH_NEW_PASSWORD_REQUESTED,
  FETCH_NEW_PASSWORD_SUCCEEDED,
  FETCH_NEW_PASSWORD_FAILED,
};
