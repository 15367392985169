import Service from '../../services';
import { get } from 'lodash';

export const searchServiceApartment = async params => {
    const response = await Service.isnGet(`/api/properties/submit-search${params}`, res => res);
    return response;
};

export const getTotalUnitServicedApartment = async params => {
    const response = await Service.isnGet(`/api/properties/total-unit${params}`, res => res);
    return response;
};

export const fetchFeaturedProjects = async params => {
  const response = await Service.isnGet(`/api/projects/hot-projects${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchAllProjects = async params => {
  const response = await Service.isnGet(`/api/projects/projects${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};