import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone';
// import { get } from 'lodash';
// import EventBus from 'eventing-bus';
import { toast } from 'react-toastify';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReCAPTCHA from 'react-google-recaptcha';
import Upload from '../../../../assets/images/Upload.svg';
import CloseImage from '../../../../assets/images/Icon_x.svg';
import * as action from '../../forOwners.action';
// import { Select } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getLanguage, getQueryString } from '../../../../utils/params';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { postInformation, fetchCities, fetchBedrooms, fetchBathrooms, fetchPropertyTypes } from 'modules/forOwnersPage/forOwners.api';

const FormOwnerInfomation = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const recaptchaRef = useRef();
  // const { bathrooms, propertyTypes } = props.forOwners;
  const currency = [
    { value: 1, name: 'USD' },
    { value: 2, name: 'VND' },
  ];
  const [cities, setCities] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [propertyTypes, setPropertyTypes] = useState('');
  const [files, setFiles] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [isShowSubmit, setIsShowSubmit] = useState(false);
  const userInfo = useSelector(state => state.loginData.userInfo);
  const authUserData = JSON.parse(localStorage.getItem('authUserData'));
  const user = userInfo ?? authUserData?.userInfo;
  const [initialValues, setInitialValues] = useState({
    fullName: user?.fullName ?? user?.fullName ?? '',
    phone: user?.phoneNumber ?? user?.userPhone ?? '',
    email: user?.email ?? user?.userEmail ?? '',
    cityId: '',
    addressForLease: '',
    propertyType: '',
    numberOfBedroom: '',
    numberOfBadroom: '',
    currency: 1,
    descriptionFacilities: '',
    descriptionAmentities: '',
    leasePrice: '',
  });
  const emailRegex = /^([0-9a-zA-Z]([+\-_.][0-9a-zA-Z])*)+@([a-zA-Z_]+?\.)+[a-zA-Z]{2,4}$/;

  useEffect(() => {
    props.showLoader();
    // props.fetchForOwnersRequest({ language });
    Promise.all([fetchCities(getQueryString({ language })), fetchBedrooms(getQueryString({ language })),
    fetchBathrooms(getQueryString({ language })), fetchPropertyTypes(getQueryString({ language }))]).then((values) => {
      if (values) {
        setCities(values[0].locations);
        setBedrooms(values[1]);
        setBathrooms(values[2]);
        setPropertyTypes(values[3]);
      }
      props.hideLoader();
    });
    setInitialValues({
      ...initialValues
    })
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    //eslint-disable-next-line
  }, [language]);

  // useEffect(() => {
  //   const propsId = get(props, 'layout.cityActive.defaultLocationId', null);
  //   setIdLocation(propsId);
  //   setInitialValues({
  //     ...initialValues
  //   })
  //   // return () => reset();
  //   // eslint-disable-next-line
  // }, [props.layout.cityActive]);

  const onSubmit = (values, resetForm) => {
    if (values) {
      props.showLoader();
      const formData = new FormData();
      formData.append('language', language);
      // formData.append('location', idLocation);
      formData.append('fullName', values.fullName.trim());
      formData.append('phone', values.phone);
      formData.append('email', values.email);
      formData.append('cityId', values.cityId ? values.cityId : 0);
      formData.append('propertyType', values.propertyType ? values.propertyType : 0);
      formData.append('currency', values.leasePrice ? values.currency : 0);
      formData.append('numberOfBedroom', values.numberOfBedroom ? parseInt(values.numberOfBedroom) : 0);
      formData.append('numberOfBadroom', values.numberOfBadroom ? parseInt(values.numberOfBadroom) : 0);
      formData.append('addressForLease', values.addressForLease);
      // if (values.leasableArea) formData.append('leasableArea', values.leasableArea);
      formData.append('descriptionFacilities', values.descriptionFacilities);
      formData.append('descriptionAmentities', values.descriptionAmentities);
      formData.append('leasePrice', values.leasePrice ? parseInt(values.leasePrice.replace(',', '')) : 0);
      files.forEach(file => {
        formData.append('images', file, file.name);
      });
      postInformation(formData).then(res => {
        props.hideLoader();
        if (res.statusCode === 200) {
          onSuccess();
          resetForm();
        } else {
          onError()
        }
      });
    }
  };

  const messageSuccess = (
    <>
      <div>
        {t('for_owners.owner_register.feedback_success')}
      </div>
      {language !== 'vi' ?
        <div>
          {t('for_owners.owner_register.feedback_success1')}
        </div>
        : ''
      }
      {language === 'jp' ?
        <div>
          {t('for_owners.owner_register.feedback_success2')}
        </div>
        : ''
      }

    </>
  );

  const onSuccess = () => {
    toast.success(messageSuccess, { autoClose: 5000 });
    // reset();
    setIsShowSubmit(false)
    setFiles([]);
    setFilePreview([]);
    recaptchaRef.current.reset()
  };
  const onError = err => toast.error(err, { autoClose: 5000, position: "bottom-center", });

  const removeImage = index => {
    let filesCopy = [...files];
    filesCopy.splice(index, 1);

    setFiles(filesCopy);

    const images = filesCopy.map(item => URL.createObjectURL(item));
    setFilePreview(images);
  };

  const checkCaptcha = value => {
    if (value) setIsShowSubmit(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        fullName: Yup.string()
          .required(t('owner_register.full_name_is_required')),
        phone: Yup.string()
          .required(t('owner_register.phone_number_is_required'))
          // .matches(phoneRegExp, 'Phone number is not valid')
          .min(7, t('owner_register.phone_number_min_max'))
          .max(15, t('owner_register.phone_number_min_max')),
        // email: Yup.string()
        //   .email(t('owner_register.email_is_invalid'))
        //   .required(t('owner_register.email_is_required')),
        email: Yup.string().required(t('owner_register.email_is_required')).matches(emailRegex, t('owner_register.email_is_invalid')),
      })}
      onSubmit={
        (values, { resetForm }) => onSubmit(values, resetForm)
      }
      render={({ errors, touched, setFieldValue }) => (
        <Form className="box-white">
          <h3 className="uppercase form-owner-title">{t('for_owners.owner_register.customer_information')}</h3>
          <div className="group">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>
                    {t('for_owners.owner_register.full_name')} <span className="link-red-bold ">*</span>
                  </label>
                  <Field
                    type="text"
                    className={'form-control' + (errors.fullName && touched.fullName ? ' is-error' : '')}
                    // className="form-control"
                    // placeholder="Nguyen Van Anh"
                    name="fullName"
                    maxLength={191}
                  />
                  <ErrorMessage name="fullName" component="small" className="error" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>
                    {t('for_owners.owner_register.phone_number')} <span className="link-red-bold ">*</span>
                  </label>
                  <Field
                    type="text"
                    className={'form-control' + (errors.phone && touched.phone ? ' is-error' : '')}
                    name="phone"
                    onChange={(event) => setFieldValue(event.target.name, event.target.value.replace(/[^\d/+-]/g, '').replace(/^([+0-9]{3})([0-9]{3})([0-9]{4})$/, '$1$2$3'))}
                  />
                  <ErrorMessage name="phone" component="small" className="error" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="email">
                    {t('for_owners.owner_register.email')} <span className="link-red-bold ">*</span>
                  </label>
                  <Field
                    type="text"
                    className={'form-control' + (errors.email && touched.email ? ' is-error' : '')}
                    // className="form-control"
                    name="email"
                    maxLength={191}
                  />
                  <ErrorMessage name="email" component="small" className="error" />
                </div>
              </div>
            </div>
          </div>
          <h3 className="uppercase form-owner-title">{t('for_owners.owner_register.apartment_information')}</h3>
          <div className="group">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.city')}</label>
                  <Field name="cityId" as="select" className='form-control select-control' >
                    <option value="">{t('for_owners.owner_register.select_one')}</option>
                    {cities && cities.length ? cities.map((item, index) =>
                      (<option value={item.cityId} key={index} >{item.cityName}</option>))
                      : []}
                  </Field>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.address')}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t('for_owners.owner_register.address_for_please')}
                    name="addressForLease"
                    maxLength={191}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="group">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.property_type')}</label>
                  <Field name="propertyType" as="select" className='form-control select-control' >
                    <option value="">{t('for_owners.owner_register.select_one')}</option>
                    {propertyTypes && propertyTypes.length ? propertyTypes.map((item, index) =>
                      (<option value={item.id} key={index} >{item.title}</option>))
                      : []}
                  </Field>
                </div>
              </div>
              {/* <div className="col-12 col-md-3">
            <div className="form-group">
              <label>Diện tích / Leasable area</label>
              <input type="text" className="form-control" name="leasableArea" ref={register()} />
            </div>
          </div> */}
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.number_of_bedroom')}</label>
                  <Field name="numberOfBedroom" as="select" className='form-control select-control' >
                    <option value="">{t('for_owners.owner_register.select_one')}</option>
                    {bedrooms && bedrooms.length ? bedrooms.map((item, index) =>
                      (<option value={item.id} key={index} >{item.title}</option>))
                      : []}
                  </Field>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="label-owner">{t('for_owners.owner_register.number_of_bathroom')}</label>
                  <Field name="numberOfBadroom" as="select" className='form-control select-control' >
                    <option value="">{t('for_owners.owner_register.select_one')}</option>
                    {bathrooms && bathrooms.length ? bathrooms.map((item, index) =>
                      (<option value={item.id} key={index} >{item.title}</option>))
                      : []}
                  </Field>
                </div>
              </div>
            </div>
          </div>
          <div className="group">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.description_of_furnitures')}</label>
                  <Field
                    as="textarea"
                    className="form-control w-100 area-owner"
                    name="descriptionFacilities"
                    maxLength={500}
                  ></Field>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.description_of_amenities')}</label>
                  <Field
                    as="textarea"
                    className="form-control w-100 area-owner"
                    name="descriptionAmentities"
                    maxLength={500}
                  ></Field>
                </div>
              </div>
            </div>
          </div>
          <div className="group">
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.lease_price')}</label>
                  <div className="position-relative">
                    <Field
                      type="text"
                      className="form-control"
                      name="leasePrice"
                      maxLength={10}
                      onChange={(event) => {
                        // if (event.target.value.length > 50) return false;
                        setFieldValue(event.target.name, event.target.value.replace(/\,/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
                        
                      }}
                    />
                    <span className="for-owner-lable-price">{t('for_owners.owner_register.per_month')}</span>
                  </div>
                  {/* <small className="error">
                    {errors.leasePrice && errors.leasePrice.message ? errors.leasePrice.message : ''}
                  </small> */}
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.currency')}</label>
                  <Field name="currency" as="select" className='form-control select-control' >
                    {/* <option value="">12323</option> */}
                    {currency && currency.length ? currency.map((item, index) =>
                      (<option value={item.value} key={index} >{item.name}</option>))
                      : []}
                  </Field>
                </div>
              </div>
            </div>
          </div>
          <div className="group">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>{t('for_owners.owner_register.upload_image')}</label>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      let checkErr = false;
                      let selectedFiles = [];

                      acceptedFiles.forEach(file => {
                        if (file.type.includes('image') && file.size < 2.5e6) {
                          selectedFiles.push(file);
                        } else {
                          checkErr = true;
                        }
                      });

                      if (checkErr) toast.error(t('owner_register.file_image_maximum'));

                      let arrayFiles = [];

                      if (selectedFiles.length + files.length <= 10) arrayFiles = [...files, ...selectedFiles];
                      else arrayFiles = [...files, ...selectedFiles].slice(0, 10);

                      setFiles(arrayFiles);

                      const images = arrayFiles.map(item => URL.createObjectURL(item));
                      setFilePreview(images);
                    }}
                    accept='image/jpeg, image/png, image/jpg, image/gif, image/tiff, image/bmp'
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="drag-drop-files" {...getRootProps()}>
                        <div className="image-upload">
                          <label htmlFor="file-input">
                            <img src={Upload} alt="" />
                            {/* <LazyLoadImage src={Upload} alt="" effect="blur" /> */}
                          </label>
                          <input {...getInputProps()} />
                        </div>
                        <label>{t('for_owners.owner_register.click_here')}</label>
                        <small>
                          {t('for_owners.owner_register.supported_image')}
                        </small>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="preview-image">
                  <div className="row">
                    <div className="col-12 col-md-12 image-all">
                      {filePreview.length
                        ? filePreview.map((item, index) => (
                          <div key={index} className="image-selected">
                            <img src={CloseImage} alt="" className="icon-close" onClick={() => removeImage(index)} />

                            <img src={item} alt="" className="image" />

                            {/* <LazyLoadImage
                            src={CloseImage}
                            alt=""
                            className="icon-close"
                            onClick={() => removeImage(index)}
                            effect="blur"
                          />
                          <LazyLoadImage src={item} alt="" className="image" effect="blur" /> */}
                          </div>
                        ))
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group">
            <div className="row justify-content-end">
              <div className="col-12 col-md-10 pb-2">
                <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_KEY_CAPTCHA} onExpired={() => { setIsShowSubmit(false) }} onChange={checkCaptcha} hl={`${language === 'en' ? 'en' : language === 'jp' ? 'ja' : 'vi'}`} />
              </div>
              <div className="col-12 col-md-2">
                <button id="send" type="submit" className="button-red" disabled={!isShowSubmit}>
                  {t('for_owners.owner_register.send')}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForOwnersRequest: action.fetchForOwnersRequest,
      postInformationRequest: action.postInformationRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormOwnerInfomation);
