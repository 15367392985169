import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './forOwners.action';
import * as types from './forOwners.types';
import * as API from './forOwners.api';
import { get } from 'lodash';

import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';
import Service from '../../services';

export function* fetchForOwnersSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const lstInfo = yield call(API.fetchListInfomation, getQueryString(params));
    const cities = yield call(API.fetchCities, getQueryString(params));

    const data = {
      bathrooms: lstInfo.bathrooms,
      bedrooms: lstInfo.bedrooms,
      propertyTypes: lstInfo.propertyTypes,
      cities: cities.locations,
    };
    yield put(actions.fetchForOwnersSuccess(data));
  } catch (error) {
    yield put(actions.fetchForOwnersFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* postInformation(action) {
  const { formData = {}, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));

    yield Service.isnPost(`/api/owners`, formData, status => {
      if (status === 200) onSuccess();
    });
  } catch (error) {
    const messageError = get(error, 'response.data.message', null);
    if (messageError) onError(messageError);
    yield put(actions.postInformationFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getFaqsOwners(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.getFaqsOwners, getQueryString(params));
    yield put(actions.getFaqsOwnersSuccess(data));
  } catch (error) {
    yield put(actions.getFaqsOwnersFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* forOwnersSaga() {
  yield takeEvery(types.FETCH_FOR_OWNERS_REQUEST, fetchForOwnersSaga);
  yield takeEvery(types.POST_INFORMATION_REQUEST, postInformation);
  yield takeEvery(types.GET_FAQS_OWNERS_REQUEST, getFaqsOwners);
}
