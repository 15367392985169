import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { formatMoney } from '../../../utils/objects';
import { HeartIcon } from '../../../layouts/commons/icons';
import { ICON_LEFT, ICON_RIGHT } from '../../../utils/constants';
import imageDefault from '../../../assets/images/loading-image.jpg';
import { ArrowsOut, RedMapPin, RedBed, Flag } from '../../../assets/images/web-isn';
import { useHistory } from 'react-router-dom';
import { LoginComponent } from '../../loginPage/pages';
import { VerifyEmailComponent } from '../../signUp/pages';
import ForgotModal from '../../../modules/forgotPassword/pages/ForgotModal.component';
import { getPathLanguage, getQueryString } from '../../../utils/params';
import { postNewFavorite } from '../../serviceApartment/serviceApartment.api';
import { useSelector } from 'react-redux';
import { getFavoritesAccount, postRemoveFavorites } from 'modules/accountPage/accountPage.api';
import { ContactUs } from '../../../modules/detailApartmentPage/pages';

const BlockResidenceComponent = props => {
  const pathLanguage = getPathLanguage(useLocation());
  const imageThumbnails = useRef(null);
  const { t } = useTranslation();
  const { post } = props;
  const history = useHistory();
  const { language } = useParams();
  const [likeData, setLikeData] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const userInfo = useSelector(state => state.loginData.userInfo);
  const currentCurrency = useSelector(state => state.layout.currentCurrency);
  const [showContact, setShowContact] = useState(false);

  const options = {
    items: 1,
    margin: 0,
    nav: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    dots: true,
    autoplay: false,
    autoHeight: true,
    responsive: {},
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    freeDrag: false,
    loop: false,
  };

  useEffect(() => {
    setLikeData([]);
    const authUserData = JSON.parse(localStorage.getItem('authUserData'));
    if (userInfo || authUserData) {
      getFavoritesAccount(getQueryString({ language, pageSize: 999, pageIndex: 1 })).then(res => {
        if (res.data?.items !== null) {
          let likeArr = [];
          res.data.items.forEach(el => {
            likeArr.push(el.apartmentId);
          });
          setLikeData(likeArr);
        }
      });
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const onClickLike = async () => {
    const authUserData = JSON.parse(localStorage.getItem('authUserData'));
    if (authUserData) {
      let newList = [];
      if (likeData.includes(post.apartmentId)) {
        postRemoveFavorites(getQueryString({ listApartment: post.apartmentId })).then(res => {
          if (res === true) {
            newList = likeData.filter(i => i !== post.apartmentId);
            setLikeData(newList);
          }
        });
      } else {
        await postNewFavorite({ apartmentId: post.apartmentId }).then(res => {
          if (res.status === 'success') {
            newList = [...likeData, post.apartmentId];
            setLikeData(newList);
          }
        });
      }
    } else {
      handleShowLogin();
    }
  };

  const handleShowLogin = () => {
    setShowLogin(true);
    setShowForgot(false);
  };

  const handleShowForgot = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleShowSignUp = () => {
    setShowLogin(false);
    setShowVerifyPopup(true);
  };
  const closeVerifyEmailPopup = () => {
    setShowVerifyPopup(false);
  };

  if (post.name) post.name = post.name.replace('　', ' ');

  const gotoDetailPage = () => {
    history.push(`${pathLanguage}/serviced-apartments/overview/${post.id}/${post.slug}/${post.typeCode}?home`);
  };

  const gotoPreparePage = () => {
    setShowContact(true);
  };

  const handleButtonBook = () => {
    const authUserData = JSON.parse(localStorage.getItem('authUserData'));
    if (authUserData) {
      history.push(`/${language}/payment-summary/${post.apartmentId}`);
    } else {
      handleShowLogin();
    }
  };

  return (
    <>
      <div className="block-inner element-carousel" id="serviceApartment">
        <div className="block-image">
          <div className="thumbail-slides thubnail-custom">
            <OwlCarousel ref={imageThumbnails} options={options}>
              {post.images.map((item, index) => (
                <Link
                  key={index}
                  to={`${pathLanguage}/serviced-apartments/overview/${post.id}/${post.slug}/${post.typeCode}?home`}
                  className="block-carousel-residence"
                >
                  <div
                    style={{
                      backgroundImage: `${process.env.REACT_APP_ISN_IMAGE_URL}${item.url}`
                        ? ''
                        : `url("${imageDefault}")`,
                    }}
                    className="thumbnail"
                  >
                    <LazyLoadImage
                      alt="post 1"
                      src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item.url}`}
                      effect="blur"
                    />
                  </div>
                </Link>
              ))}
            </OwlCarousel>
            <span
              onClick={() => onClickLike()}
              className={`heart circle ${likeData.includes(post.apartmentId) ? 'active-like' : ''}`}
            >
              <HeartIcon />
            </span>
          </div>
        </div>
        <div className="block-text none-split" style={{ height: 280 }}>
          <Link
            to={`${pathLanguage}/serviced-apartments/overview/${post.id}/${post.slug}/${post.typeCode}?home`}
            className="block-carousel-residence"
          >
            <h4 className="project-name">{post.name}</h4>
          </Link>
          <div style={{ height: '70px' }} className="property-info mt-2 d-flex flex-column">
            <div className="apartment-address align-items-start">
              <img src={Flag} alt="location" />
              <div className="project-location mr-3">{post.address}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="icon-district d-flex">
                <img src={RedMapPin} alt="red-mappin-icon"></img>
                {post.district || '-'}
              </div>
              {post.bedRoomName ? (
                <div className="icon-bedroom d-flex justify-content-end">
                  <img src={RedBed} alt="red-bed"></img>
                  <span>&nbsp;{post.bedRoomName}</span>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div className="mt-2">
                <div className="project-price">
                  <span className="focus-price">
                    {currentCurrency?.code === 'USD' && post.priceOffer && post.isOffer === true
                      ? `${currentCurrency?.symbol}${formatMoney(post.priceOffer, 0, 0)}`
                      : ''}
                    {currentCurrency?.code === 'USD' &&
                    post.price &&
                    (post.isOffer === false || (post.isOffer && !post.priceOffer))
                      ? `${currentCurrency?.symbol}${formatMoney(post.price, 0, 0)}`
                      : ''}
                    {currentCurrency?.code === 'VND' && post.priceOfferVi && post.isOffer === true
                      ? `${formatMoney(post.priceOfferVi, 0, 0)}${currentCurrency?.symbol}`
                      : ''}
                    {currentCurrency?.code === 'VND' &&
                    post.priceVi &&
                    (post.isOffer === false || (post.isOffer && !post.priceOfferVi))
                      ? `${formatMoney(post.priceVi, 0, 0)}${currentCurrency?.symbol}`
                      : ''}
                  </span>
                  /
                  {post.typeCode === 'long' ? (
                    <span>{t('service_apartment_isn.label_month')}</span>
                  ) : (
                    <span>{t('service_apartment_isn.label_day')}</span>
                  )}
                </div>
                {post.priceOffer > 0 && post.priceOfferVi > 0 && (
                  <div className="project-price" style={{ color: '#6b6b6b' }}>
                    <span className="focus-price" style={{ textDecoration: 'line-through' }}>
                      {currentCurrency?.code === 'USD' && post.isOffer === true && post.priceOffer > 0
                        ? `${currentCurrency?.symbol}${formatMoney(post.price, 0, 0)}`
                        : ''}
                      {currentCurrency?.code === 'VND' && post.isOffer === true && post.priceOfferVi > 0
                        ? `${formatMoney(post.priceVi, 0, 0)}${currentCurrency?.symbol}`
                        : ''}
                    </span>
                    {post.typeCode === 'long' && post.priceOffer > 0 && post.priceOfferVi > 0 ? (
                      <span style={{ textDecoration: 'line-through' }}>/{t('offers.label_month')}</span>
                    ) : (
                      ''
                    )}
                    {post.typeCode === 'short' && post.priceOffer > 0 && post.priceOfferVi > 0 ? (
                      <span style={{ textDecoration: 'line-through' }}>/{t('offers.label_day')}</span>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
              <div className="size d-flex align-items-center">
                {post.size ? <img src={ArrowsOut} alt="size-icon" className="mr-1"></img> : ''}
                {post.size ? formatMoney(post.size, 0, 0) : ''}
                <span className="d-flex">
                  m<sup style={{ top: '4px' }}>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between extra-btn" style={{ marginTop: 50 }}>
            <button onClick={() => gotoDetailPage()} className="block-button details-block-btn">
              {t('home_page.btn_details')}
            </button>
            {post.typeCode === 'short' ? (
              <button onClick={handleButtonBook} className="block-button booknow-block-btn">
                {t('home_page.btn_booknow')}
              </button>
            ) : (
              ''
            )}
            {post.typeCode === 'long' ? (
              <button onClick={() => gotoPreparePage()} className="block-button booknow-block-btn">
                {t('home_page.btn_contactus')}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        {post.offerItems && post.offerItems.length > 0 ? (
          <div className="special-offers">
            <div className="d-flex">
              <div className="rectangle"></div>
              <div className="triangle-right"></div>
            </div>
            <span className="text">{t('label_special_offers')}</span>
          </div>
        ) : (
          ''
        )}
      </div>
      <LoginComponent
        closeLogin={() => {
          setShowLogin(false);
        }}
        showLogin={showLogin}
        handleShowForgot={handleShowForgot}
        handleShowSignUp={handleShowSignUp}
      />
      <VerifyEmailComponent
        showVerifyEmail={showVerifyPopup}
        hideVerifyEmail={closeVerifyEmailPopup}
      ></VerifyEmailComponent>
      <ForgotModal showModal={showForgot} hideModal={() => setShowForgot(false)} handleShowLogin={handleShowLogin} />
      <ContactUs
        commonId={post.id}
        showLogin={showContact}
        closeLogin={() => {
          setShowContact(false);
        }}
      />
    </>
  );
};

export default BlockResidenceComponent;
