import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Star_1, Star_2, Star_3 } from '../../../assets/images/web-isn';
import { BannerApartment, BreadcumbApartment, MenuApartment } from '../common';
import previousIcon from '../../../assets/images/arrow-left.png';
import nextIcon from '../../../assets/images/arrow-right.png';
import ReactPaginate from 'react-paginate';
import * as actions from '../detailApartment.action';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getLanguage } from '../../../utils/params';
import { User } from 'assets/images/web-isn';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import { LoginComponent } from 'modules/loginPage/pages';
import { VerifyEmailComponent } from 'modules/signUp/pages';
import ForgotModalComponent from 'modules/forgotPassword/pages/ForgotModal.component';
// import 'moment/locale/ja';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ContactUs } from '../pages';

require('moment/min/locales.min');

function RatingReviewComponent(props) {
  const { typeCode } = useParams();
  const { t } = useTranslation();
  const { ratingInfo, reviewInfo } = props.detailApartmentData;
  const [totalColor, setTotalColor] = useState('');
  const [ratingStatus, setRatingStatus] = useState();
  const [averageScore, setAverageScore] = useState();
  const [star5, setStar5] = useState();
  const [star4, setStar4] = useState();
  const [star3, setStar3] = useState();
  const [star2, setStar2] = useState();
  const [star1, setStar1] = useState();
  const [pageCount, setPageCount] = useState();
  let pageIndex = 1;
  const pageSize = 10;
  const location = useLocation();
  const language = getLanguage(location);
  const [showContact, setShowContact] = useState(false);
  // const apartmentId = localStorage.getItem('apartmentId');
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const options = {
    settings: {
      disableWheelControls: true,
    },

    buttons: {
      backgroundColor: 'rgba(30,30,36,0.8)',
      iconColor: 'rgba(255, 255, 255, 0.8)',
      iconPadding: '5px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: '30px',
      with: '100%',
    },
  };
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const history = useHistory();
  const [apartmentId, setApartmentId] = useState(null);

  const handleShowLogin = () => {
    setShowLogin(true);
    setShowForgot(false);
  };

  const handleShowForgot = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleShowSignUp = () => {
    setShowLogin(false);
    setShowVerifyPopup(true);
  };
  const closeVerifyEmailPopup = () => {
    setShowVerifyPopup(false);
  };

  const handleButtonBook = () => {
    if (typeCode === 'long') {
      setShowContact(true);
    } else {
      const authUserData = JSON.parse(localStorage.getItem('authUserData'));
      if (authUserData) {
        history.push(`/${language}/payment-summary/${apartmentId}`);
      } else {
        handleShowLogin();
      }
    }
  };

  const calcStarBar = () => {
    if (ratingInfo.totalRate > 0 && Object.keys(ratingInfo).length !== 0) {
      const totalReviews = ratingInfo.totalRate;
      let total5 = 0;
      let total4 = 0;
      let total3 = 0;
      let total2 = 0;
      let total1 = 0;
      let totalScore = 0;

      //eslint-disable-next-line
      if (ratingInfo.rateTotal) {
        ratingInfo.rateTotal.forEach(item => {
          item.rate === 5
            ? (total5 = item.total)
            : item.rate === 4
            ? (total4 = item.total)
            : item.rate === 3
            ? (total3 = item.total)
            : item.rate === 2
            ? (total2 = item.total)
            : (total1 = item.total);
        });
      }
      totalScore = (total5 * 5 + total4 * 4 + total3 * 3 + total2 * 2 + total1) / totalReviews;
      // totalScore = totalScore.toFixed(1);
      if (totalScore >= 4) {
        setTotalColor('#1ECD6F');
        setRatingStatus(t('detailApartment.excellent_label'));
      } else if (totalScore >= 3 && totalScore < 4) {
        setTotalColor('#FFD935');
        setRatingStatus(t('detailApartment.good_label'));
      } else if (totalScore >= 1 && totalScore < 3) {
        setTotalColor('#FF8C5A');
        setRatingStatus(t('detailApartment.review_score_label'));
      } else {
        setTotalColor('');
        setRatingStatus(t('detailApartment.await_review_label'));
      }

      // if (totalScore.toFixed(1) % 1 === 0) {
      //   console.log(totalScore.toFixed(1) % 1);
      //   setAverageScore(totalScore);
      // } else {
      setAverageScore(totalScore.toFixed(1));
      // }

      let star_5,
        star_4,
        star_3,
        star_2,
        star_1 = 0;

      star_5 = Math.round((total5 / totalReviews) * 100);
      star_4 = Math.round((total4 / totalReviews) * 100);
      star_3 = Math.round((total3 / totalReviews) * 100);
      star_2 = Math.round((total2 / totalReviews) * 100);
      star_1 = Math.round((total1 / totalReviews) * 100);

      if (star_5 + star_4 + star_3 + star_2 + star_1 > 100 && star_1 !== 0) {
        star_1 = 100 - (star_5 + star_4 + star_3 + star_2);
      } else if (star_5 + star_4 + star_3 + star_2 > 100 && star_2 !== 0) {
        star_2 = 100 - (star_5 + star_4 + star_3);
      } else if (star_5 + star_4 + star_3 > 100 && star_3 !== 0) {
        star_3 = 100 - (star_5 + star_4);
      }

      setStar5(star_5);
      setStar4(star_4);
      setStar3(star_3);
      setStar2(star_2);
      setStar1(star_1);
    } else {
      setAverageScore(0);
      setStar5(0);
      setStar4(0);
      setStar3(0);
      setStar2(0);
      setStar1(0);
      setTotalColor('#5CAEE1');
      setRatingStatus(t('detailApartment.await_review_label'));
    }
    // return true;
  };

  const handleStarBar = () => {
    const starBarArray = [];
    for (let i = 5; i >= 1; i--) {
      starBarArray.push(
        <div key={i} className="p-2">
          <div className="col-2 col-sm-2 col-md-2 col-lg-1">
            <span className=" text-span">{i}</span>&nbsp; <img src={Star_2} alt="star" />
          </div>
          <div className="col-8 col-sm-8 col-md-9 col-lg-10 apartment-ratingReview__total--star2__list-star-bar">
            <div
              className={
                i === 5 ? 'bar-5' : i === 4 ? 'bar-4' : i === 3 ? 'bar-3' : i === 2 ? 'bar-2' : i === 1 ? 'bar-1' : ''
              }
              style={{
                height: '100%',
                width:
                  i === 5
                    ? star5 + '%'
                    : i === 4
                    ? star4 + '%'
                    : i === 3
                    ? star3 + '%'
                    : i === 2
                    ? star2 + '%'
                    : i === 1
                    ? star1 + '%'
                    : //eslint-disable-next-line
                      '' + '%',
              }}
            ></div>
          </div>
          <div className="col-2 col-sm-2 col-md-1 col-lg-1 text-span">
            {i === 5 ? star5 : i === 4 ? star4 : i === 3 ? star3 : i === 2 ? star2 : i === 1 ? star1 : ''}%
          </div>
        </div>,
      );
    }
    return starBarArray;
  };

  const handleRatingStar = reviewRating => {
    const imgArray = [];
    for (let i = 1; i <= reviewRating; i++) {
      imgArray.push(<img className="rating-margin-bottom" key={i} src={Star_3} alt="star" />);
    }
    return imgArray;
  };

  useEffect(() => {
    showLoader();
    props.getRatingRequest({ language, apartmentId });
    props.getReviewRequest({ language, apartmentId, pageSize, pageIndex });
    hideLoader();
    //eslint-disable-next-line
  }, [apartmentId]);

  useEffect(() => {
    calcStarBar();
    //eslint-disable-next-line
  }, [ratingInfo]);

  useEffect(() => {
    if (reviewInfo.pagedResult && Object.keys(reviewInfo).length !== 0) {
      setPageCount(Math.ceil(reviewInfo.pagedResult.totalRecords / pageSize));
    }
    //eslint-disable-next-line
  }, [reviewInfo]);

  const handleNameApartment = () => {
    if (Object.keys(reviewInfo).length !== 0) {
      // localStorage.setItem('apartmentName', reviewInfo.apartmentName);
      return localStorage.getItem('apartmentName');
    }
  };

  const handleCommentDate = commentDate => {
    if (language === 'en') {
      moment.locale('en');
      return <div className="review-date mb-1">{moment(commentDate).format('Do MMMM YYYY')}</div>;
    } else if (language === 'jp') {
      moment.locale('ja');
      return <div className="review-date mb-1">{moment(commentDate).format('YYYY[年]MMMMDo')}</div>;
    } else if (language === 'vi') {
      moment.locale('vi');
      return <div className="review-date mb-1">{moment(commentDate).format('DD/MM/YYYY')}</div>;
    }
  };

  const handleReview = () => {
    if (reviewInfo.pagedResult && Object.keys(reviewInfo).length !== 0) {
      return (
        <div className="list-reviews">
          {reviewInfo.pagedResult.items.map((item, index) => {
            return (
              <div key={index} className="pt-4 review-info">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex">
                    <div className="user-avatar">
                      <img src={item.avatarUrl ? item.avatarUrl : User} alt="avatar" />
                    </div>
                    <div className="pl-3 d-flex flex-column justify-content-between">
                      <div className="pl-3 row">
                        <span>{`${item.fullName ? item.fullName : ''}`}</span>
                      </div>
                      <div className="row clear-margin-row mt-1">
                        {/* <div className="user-name">{item.name}</div> */}
                        <div id="imageRatingStar" className="d-flex align-items-end review-star">
                          {handleRatingStar(item.rate)}
                          <div className="pl-1 text-star">{item.rate}.0</div>
                        </div>
                        <div className="d-flex align-items-end">
                          {item.tagNameReviews && item.tagNameReviews.length > 0
                            ? item.tagNameReviews.map((item, index) => {
                                return (
                                  <span key={index} className="review-tags ml-2">
                                    {item.tagName}
                                  </span>
                                );
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">{handleCommentDate(item.createDate)}</div>
                </div>
                <div className="pt-4 review-content">
                  {item.content ? (
                    <ReactReadMoreReadLess
                      charLimit={300}
                      readMoreText={'Read more'}
                      readLessText={'Read less'}
                      readMoreClassName="read-more-less--more"
                      readLessClassName="read-more-less--less"
                    >
                      {item.content}
                    </ReactReadMoreReadLess>
                  ) : (
                    ''
                  )}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper options={{ ...options, buttons: {} }}>
                    <div className="row mt-2">
                      {item.images && item.images.length > 0
                        ? item.images.map((item2, index2) => {
                            return (
                              <div key={'gallery' + index2} className="col-2">
                                <LazyLoadImage
                                  className="w-100"
                                  effect="blur"
                                  src={item2.imageUrl ? item2.imageUrl : ''}
                                  alt={'review' + index2}
                                />
                              </div>
                            );
                          })
                        : ''}
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const handleChangePage = ({ selected }) => {
    pageIndex = selected + 1;
    props.getReviewRequest({ apartmentId: apartmentId, pageSize, pageIndex });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // document.getElementById('breadcrumbApartmentMenu').scrollIntoView({ behavior: 'smooth' });
  };

  const getApartmentId = apartmentId => {
    setApartmentId(apartmentId);
  };

  return (
    <>
      <BreadcumbApartment />
      <BannerApartment />
      <MenuApartment getApartmentId={getApartmentId} />
      <ContactUs
        showLogin={showContact}
        closeLogin={() => {
          setShowContact(false);
        }}
      />
      <div id="detailApartmentRR">
        <div className="container">
          <div className="apartment-ratingReview">
            <div className="detail-apartment-title">{handleNameApartment()}</div>
            <div className="apartment-ratingReview__title">{t('detailApartment.ratings_reviews_label')}</div>

            {/*=============== Ratings ===============*/}
            <div className="row apartment-ratingReview__total">
              <div className="col-12 col-sm-4 flex-column apartment-ratingReview__total--star1">
                <div className="apartment-ratingReview__total--star1__star" style={{ backgroundColor: totalColor }}>
                  {averageScore} &nbsp; <img src={Star_1} alt="star" />
                </div>
                <div className="apartment-ratingReview__total--star1__status">{ratingStatus}</div>
              </div>

              <div className="col-12 col-sm-8 apartment-ratingReview__total--star2">
                <div className="apartment-ratingReview__total--star2__list-star">{handleStarBar()}</div>
              </div>
            </div>

            {/*=============== Reviews ===============*/}
            {handleReview()}
            {reviewInfo && reviewInfo.pagedResult && reviewInfo.pagedResult.totalRecords > 15 ? (
              <div className="pt-5 rating-review-paginate">
                <ReactPaginate
                  previousLabel={<img src={previousIcon} alt="nav-icon" />}
                  nextLabel={<img src={nextIcon} alt="nav-icon" />}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  onPageChange={handleChangePage}
                  disabledClassName={'opacity-disable'}
                  activeClassName={'pagination-active'}
                  pageClassName={'pagination-pager-item'}
                  nextLinkClassName={'next-btn'}
                  previousLinkClassName={'prev-btn'}
                />
              </div>
            ) : (
              <div className="mt-5"></div>
            )}
            {typeCode === 'long' ?
              <div
                style={{ marginTop: `${Object.keys(reviewInfo).length !== 0 ? '' : '143px'}` }}
                className="d-flex justify-content-center"
              >
                <p>{t('detailApartment.contact_us_note')}</p>
              </div> : ''
            }
            <div
              style={{ marginTop: `${Object.keys(reviewInfo).length !== 0 ? '' : '143px'}` }}
              className="d-flex justify-content-center mg-top-bottom"
            >
              <input
                className="btn-booknow"
                type="button"
                onClick={handleButtonBook}
                value={
                  typeCode === 'long' ? t('detailApartment.contact_us_title') : t('detailApartment.book_now_title')
                }
              />
            </div>
          </div>
        </div>
      </div>
      <LoginComponent
        closeLogin={() => {
          setShowLogin(false);
        }}
        showLogin={showLogin}
        handleShowForgot={handleShowForgot}
        handleShowSignUp={handleShowSignUp}
      />
      <VerifyEmailComponent
        showVerifyEmail={showVerifyPopup}
        hideVerifyEmail={closeVerifyEmailPopup}
      ></VerifyEmailComponent>
      <ForgotModalComponent
        showModal={showForgot}
        hideModal={() => setShowForgot(false)}
        handleShowLogin={handleShowLogin}
      />
      {loader}
    </>
  );
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRatingRequest: actions.getRatingRequest,
      getReviewRequest: actions.getReviewRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RatingReviewComponent);
