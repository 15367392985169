import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MasterCard, Messenger, Visa, YouTube, Zalo, Facebook, SignupChecked } from '../../../assets/images/web-isn';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';
import { getLanguage } from '../../../utils/params';
import { Accordion, Toggle } from './Accordion';
import ISN from '../../../assets/Logo_ISN.svg';

const Footer = () => {
  const location = useLocation();
  const language = getLanguage(location);
  const [modeVi, setModeVi] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (language === 'vi') {
      setModeVi(true);
    }
  }, [language]);
  return (
    <>
      <footer className="section-block footer background-black">
        <div className="container">
          <div className="row d-none d-lg-flex">
            {/* logo */}
            <div className="col-3 footer-items">
              <div className="mb-4 font-noto-sans-jp">
                <Link to={`/${language}`}>
                  <LazyLoadImage className="w-100" alt="ISN Vietnam Housing" src={ISN} effect="blur" />
                </Link>
              </div>
              {language === 'en' && (
                <div>
                  <Link to={`/${language}/policy/terms-of-service`}>
                    <p className="mb-3 font-noto-sans-jp">{t('footer.label_term')}</p>
                  </Link>
                </div>
              )}
              {language === 'en' && (
                <div>
                  <Link to={`/${language}/policy/privacy-policy`}>
                    <p className="mb-3 font-noto-sans-jp">{t('footer.label_privacy')}</p>
                  </Link>
                </div>
              )}
              {language === 'jp' && (
                <div >
                  <Link to={`/${language}/policy/terms-of-service`}>
                    <p className="mb-3 font-noto-sans-jp">{t('footer.label_term')}</p>
                  </Link>
                </div>
              )}
              {language === 'jp' && (
                <div>
                  <Link to={`/${language}/policy/privacy-policy`}>
                    <p className="mb-3 font-noto-sans-jp">{t('footer.label_privacy')}</p>
                  </Link>
                </div>
              )}
              {language === 'vi' && (
                <div>
                  <Link to={`/${language}/policy/terms-of-service`}>
                    <p className="mb-3 font-noto-sans-jp">{t('footer.label_term')}</p>
                  </Link>
                </div>
              )}
              {language === 'vi' && (
                <div >
                  <Link to={`/${language}/policy/privacy-policy`}>
                    <p className="mb-3 font-noto-sans-jp">{t('footer.label_privacy')}</p>
                  </Link>
                </div>
              )}
              <div>
                <Link to={`/${language}/policy/cancellation-policy`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_cancel')}</p>
                </Link>
              </div>
            </div>


            <div className="col-3 footer-items">
              <h3 className="mb-4 font-noto-sans-jp">{t('footer.label_find_apartment')}</h3>
              <div>
                <Link to={`/${language}/find-property/by-property`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_by_property')}</p>
                </Link>
              </div>
              <div>
                <Link to={`/${language}/find-property/by-map`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_by_map')}</p>
                </Link>
              </div>
              {/* <div>
                <Link to={`/${language}/preparation-page`}>
              <div>
                <Link to={`/${language}/find-property`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_by_destination')}</p>
                </Link>
              </div> */}
            </div>


            <div className="col-2 footer-items">
              <h3 className="mb-4 font-noto-sans-jp">{t('footer.label_about')}</h3>
              <div>
                <Link to={`/${language}/about-us`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_about_us')}</p>
                </Link>
              </div>
              <div>
                <Link to={`/${language}/blogs/all-blogs?pageIndex=1`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_blogs')}</p>
                </Link>
              </div>
              <div>
                <Link to={`/${language}/offers?pageIndex=1&pageSize=10&orderBy=desc&sortBy=0`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_offers')}</p>
                </Link>
              </div>
            </div>
            <div className="col-2 footer-items">
              <h3 className="mb-4 font-noto-sans-jp">{t('footer.label_help')}</h3>
              <div>
                <Link to={`/${language}/about-us/contact-us`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_contact')}</p>
                </Link>
              </div>
              <div>
                <Link to={`/${language}/useful-information/faqs`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_faq')}</p>
                </Link>
              </div>
              <div>
                <Link to={`/${language}/useful-information/about-renting-an-apartment-office-in-vietnam/area-guide`}>
                  <p className="mb-3 font-noto-sans-jp">{t('footer.label_useful_information')}</p>
                </Link>
              </div>
            </div>
            <div className="col-2 footer-items">
              <h3 className="mb-4 font-noto-sans-jp">{t('footer.label_follow')}</h3>
              <div>
              <a href='https://www.youtube.com/channel/UCQWjcP_0lUy4Jckgmiw-1iw' target='_blank' rel="noreferrer"><LazyLoadImage className="mr-2" src={YouTube} alt="youtube" effect="blur" /></a>
                <LazyLoadImage className="mr-2" src={Zalo} alt="zalo" effect="blur" />
                <a href='https://www.facebook.com/Flesta-ISN-JUTEC-108895621909117' target='_blank' rel="noreferrer"><LazyLoadImage src={Facebook} alt="facebook" effect="blur" height="34px" width="34px"/></a>
              </div>
            </div>
          </div>
          <div className="row d-block d-lg-none">
            <div className="col-12">
              <Accordion>
                <Toggle title={t('footer.label_find_apartment')}>
                  <div>
                    <Link to={`/${language}/preparation-page`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_by_property')}</p>
                    </Link>
                  </div>
                  <div>
                    <Link to={`/${language}/preparation-page`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_by_map')}</p>
                    </Link>
                  </div>
                  {/* <div>
                    <Link to={`/${language}/preparation-page`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_by_destination')}</p>
                    </Link>
                  </div> */}
                </Toggle>
                <Toggle title={t('footer.label_about')}>
                  <div>
                    <Link to={`/${language}/about-us`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_about_us')}</p>
                    </Link>
                  </div>
                  <div>
                    <Link to={`/${language}/blogs/all-blogs?pageIndex=1`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_blogs')}</p>
                    </Link>
                  </div>
                  <div>
                    <Link to={`/${language}/offers?pageIndex=1&pageSize=10&orderBy=desc&sortBy=0`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_offers')}</p>
                    </Link>
                  </div>
                </Toggle>
                <Toggle title={t('footer.label_help')}>
                  <div>
                    <Link to={`/${language}/preparation-page`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_contact')}</p>
                    </Link>
                  </div>
                  <div>
                    <Link to={`/${language}/preparation-page`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_faq')}</p>
                    </Link>
                  </div>
                </Toggle>
                <Toggle title={t('footer.label_follow')}>
                  <div>
                    <a href='https://www.youtube.com/channel/UCQWjcP_0lUy4Jckgmiw-1iw' target='_blank' rel="noreferrer"><LazyLoadImage className="mr-2" src={YouTube} alt="youtube" effect="blur" /></a>
                    <LazyLoadImage className="mr-2" src={Zalo} alt="zalo" effect="blur" />
                    <a href='https://www.facebook.com/Flesta-ISN-JUTEC-108895621909117' target='_blank' rel="noreferrer"><LazyLoadImage src={Facebook} alt="facebook" effect="blur" height="34px" width="34px"/></a>
                  </div>
                </Toggle>
              </Accordion>
              <div className="col-12 footer-items text-center">
                <div className="mb-4 font-noto-sans-jp">
                  <Link to={`/${language}`}>
                    <LazyLoadImage className="w-100" alt="ISN Vietnam Housing" src={ISN} effect="blur" />
                  </Link>
                </div>
                {language === 'en' && (
                  <div>
                    <Link to={`/${language}/policy/terms-of-service`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_term')}</p>
                    </Link>
                  </div>
                )}
                {language === 'en' && (
                  <div>
                    <Link to={`/${language}/policy/privacy-policy`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_privacy')}</p>
                    </Link>
                  </div>
                )}
                {language === 'jp' && (
                  <div >
                    <Link to={`/${language}/policy/terms-of-service`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_term')}</p>
                    </Link>
                  </div>
                )}
                {language === 'jp' && (
                  <div>
                    <Link to={`/${language}/policy/privacy-policy`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_privacy')}</p>
                    </Link>
                  </div>
                )}
                {language === 'vi' && (
                  <div>
                    <Link to={`/${language}/policy/terms-of-service`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_term')}</p>
                    </Link>
                  </div>
                )}
                {language === 'vi' && (
                  <div >
                    <Link to={`/${language}/policy/privacy-policy`}>
                      <p className="mb-3 font-noto-sans-jp">{t('footer.label_privacy')}</p>
                    </Link>
                  </div>
                )}
                <div>
                  <Link to={`/${language}/policy/cancellation-policy`}>
                    <p className="mb-3 font-noto-sans-jp">{t('footer.label_cancel')}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap mt-4 row align-items-end">
            <div className="col-12 col-lg-2 mt-lg-0 text-center "></div>
            <div className="col-12 col-lg-2 mt-lg-0 text-center "></div>
            <div className="col-12 col-lg-2 mt-lg-0 text-center "></div>
            <div
              className={`${(modeVi && 'col-12 col-lg-2 mt-3 mt-lg-0 text-center ') || 'col-12 col-lg-3 mt-3 mt-lg-0 text-center'
                }`}
            >
              <p className="mb-2 font-noto-sans-jp">{t('footer.label_secure')}</p>
              <div className="d-flex justify-content-center">
                <LazyLoadImage src={Visa} alt="visa-icon" effect="blur" />
                <LazyLoadImage className="px-3" src={MasterCard} alt="master-card-icon" effect="blur" />
              </div>
            </div>
            <div className="col-12 col-lg-2 mt-3 mt-lg-0 text-center">
              <p className="mb-2 pr-4 font-noto-sans-jp">{t('footer.label_certification')}</p>
              <div className="d-flex justify-content-center">
                <a href='http://online.gov.vn/Home/WebDetails/94671' target='_blank' rel="noreferrer"><LazyLoadImage className="mr-2 mr-lg-0" src={SignupChecked} alt="signup-icon" effect="blur" height="35px" width="111px"/></a>
              </div>
            </div>
            <div className="mt-5 col-12" style={{ border: '1px solid #EEEEEE' }}></div>
            <div className="mt-4 col-12 text-center text-lg-left">
              <p className="font-noto-sans-jp" dangerouslySetInnerHTML={{ __html: t('footer.text_company_info')}}></p>
              <div className="font-noto-sans-jp" dangerouslySetInnerHTML={{ __html: t('footer.text_copy_right')}}></div>
            </div>
          </div>
          <div className="isn-item-2 messenger-icon">
            <img src={Messenger} alt="message-icon"></img>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
