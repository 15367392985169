const FETCH_LANGUAGES_REQUEST = 'FETCH_LANGUAGES_REQUEST';
const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
const FETCH_LANGUAGES_FAILURE = 'FETCH_LANGUAGES_FAILURE';

const FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST';
const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';

const FETCH_DISTRICTS_BY_CITYID_REQUEST = 'FETCH_DISTRICTS_BY_CITYID_REQUEST';
const FETCH_DISTRICTS_BY_CITYID_SUCCESS = 'FETCH_DISTRICTS_BY_CITYID_SUCCESS';
const FETCH_DISTRICTS_BY_CITYID_FAILURE = 'FETCH_DISTRICTS_BY_CITYID_FAILURE';

const FETCH_CURRENCY_REQUEST = 'FETCH_CURRENCY_REQUEST';
const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS';
const FETCH_CURRENCY_FAILURE = 'FETCH_CURRENCY_FAILURE';

const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

const GET_BANNER_HOME_PAGE_REQUEST = 'GET_BANNER_HOME_PAGE_REQUEST';
const GET_BANNER_HOME_PAGE_SUCCESS = 'GET_BANNER_HOME_PAGE_SUCCESS';
const GET_BANNER_HOME_PAGE_FAILURE = 'GET_BANNER_HOME_PAGE_FAILURE';

const FETCH_LIST_CONDITION_REQUEST = 'FETCH_LIST_CONDITION_REQUEST';
const FETCH_LIST_CONDITION_SUCCESS = 'FETCH_LIST_CONDITION_SUCCESS';
const FETCH_LIST_CONDITION_FAILURE = 'FETCH_LIST_CONDITION_FAILURE';

const FETCH_RANGE_SETTING_REQUEST = 'FETCH_RANGE_SETTING_REQUEST';
const FETCH_RANGE_SETTING_SUCCESS = 'FETCH_RANGE_SETTING_SUCCESS';
const FETCH_RANGE_SETTING_FAILURE = 'FETCH_RANGE_SETTING_FAILURE';

const FETCH_CUSTOMER_CONTACT_REQUEST = 'FETCH_CUSTOMER_CONTACT_REQUEST';
const FETCH_CUSTOMER_CONTACT_SUCCESS = 'FETCH_CUSTOMER_CONTACT_SUCCESS';
const FETCH_CUSTOMER_CONTACT_FAILURE = 'FETCH_CUSTOMER_CONTACT_FAILURE';
const FETCH_CURRENCY_CURRENT = 'FETCH_CURRENCY_CURRENT';

const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';

// const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
// const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
// const GET_UNITS_FAILURE = 'GET_UNITS_FAILURE';

// const FETCH_CITIES_BY_LOCALE_REQUEST = 'FETCH_CITIES_BY_LOCALE_REQUEST';
// const FETCH_CITIES_BY_LOCALE_SUCCESS = 'FETCH_CITIES_BY_LOCALE_SUCCESS';
// const FETCH_CITIES_BY_LOCALE_FAILURE = 'FETCH_CITIES_BY_LOCALE_FAILURE';

// const FETCH_PROJECT_SEARCH_REQUEST = 'FETCH_PROJECT_SEARCH_REQUEST';
// const FETCH_PROJECT_SEARCH_SUCCESS = 'FETCH_PROJECT_SEARCH_SUCCESS';
// const FETCH_PROJECT_SEARCH_FAILURE = 'FETCH_PROJECT_SEARCH_FAILURE';

// const GET_FOOTER_INFORMATION_REQUEST = 'GET_FOOTER_INFORMATION_REQUEST';
// const GET_FOOTER_INFORMATION_SUCCESS = 'GET_FOOTER_INFORMATION_SUCCESS';
// const GET_FOOTER_INFORMATION_FAILURE = 'GET_FOOTER_INFORMATION_FAILURE';

// const DOWNLOAD_MAGAZINE_REQUEST = 'DOWNLOAD_MAGAZINE_REQUEST';
// const DOWNLOAD_MAGAZINE_SUCCESS = 'DOWNLOAD_MAGAZINE_SUCCESS';
// const DOWNLOAD_MAGAZINE_FAILURE = 'DOWNLOAD_MAGAZINE_FAILURE';

// const FETCH_RANGE_REQUEST = 'FETCH_RANGE_REQUEST';
// const FETCH_RANGE_SUCCESS = 'FETCH_RANGE_SUCCESS';
// const FETCH_RANGE_FAILURE = 'FETCH_RANGE_FAILURE';

// const SUBSCRIBE_NEWSLETTER_REQUEST = 'SUBSCRIBE_NEWSLETTER_REQUEST';

const SET_LOADING = 'SET_LOADING';

// const POST_CUSTOMER_SUPPORT_REQUEST = 'POST_CUSTOMER_SUPPORT_REQUEST';

// const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';
// const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
// const GET_PARTNER_FAILURE = 'GET_PARTNER_FAILURE';

export {
  FETCH_LANGUAGES_REQUEST,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_FAILURE,
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_DISTRICTS_BY_CITYID_REQUEST,
  FETCH_DISTRICTS_BY_CITYID_SUCCESS,
  FETCH_DISTRICTS_BY_CITYID_FAILURE,
  FETCH_CURRENCY_REQUEST,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_FAILURE,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  GET_BANNER_HOME_PAGE_REQUEST,
  GET_BANNER_HOME_PAGE_SUCCESS,
  GET_BANNER_HOME_PAGE_FAILURE,
  FETCH_CUSTOMER_CONTACT_REQUEST,
  FETCH_CUSTOMER_CONTACT_SUCCESS,
  FETCH_CUSTOMER_CONTACT_FAILURE,
  SET_LOADING,
  FETCH_LIST_CONDITION_REQUEST,
  FETCH_LIST_CONDITION_SUCCESS,
  FETCH_LIST_CONDITION_FAILURE,
  FETCH_RANGE_SETTING_REQUEST,
  FETCH_RANGE_SETTING_SUCCESS,
  FETCH_RANGE_SETTING_FAILURE,
  FETCH_CURRENCY_CURRENT,
  FETCH_LOGOUT_SUCCESS
  // GET_UNITS_REQUEST,
  // GET_UNITS_SUCCESS,
  // GET_UNITS_FAILURE,
  // FETCH_CITIES_BY_LOCALE_REQUEST,
  // FETCH_CITIES_BY_LOCALE_SUCCESS,
  // FETCH_CITIES_BY_LOCALE_FAILURE,
  // FETCH_PROJECT_SEARCH_REQUEST,
  // FETCH_PROJECT_SEARCH_SUCCESS,
  // FETCH_PROJECT_SEARCH_FAILURE,
  // GET_FOOTER_INFORMATION_REQUEST,
  // GET_FOOTER_INFORMATION_SUCCESS,
  // GET_FOOTER_INFORMATION_FAILURE,
  // SUBSCRIBE_NEWSLETTER_REQUEST,
  // DOWNLOAD_MAGAZINE_REQUEST,
  // DOWNLOAD_MAGAZINE_SUCCESS,
  // DOWNLOAD_MAGAZINE_FAILURE,
  // FETCH_RANGE_REQUEST,
  // FETCH_RANGE_SUCCESS,
  // FETCH_RANGE_FAILURE,
  // POST_CUSTOMER_SUPPORT_REQUEST,
  // GET_PARTNER_REQUEST,
  // GET_PARTNER_SUCCESS,
  // GET_PARTNER_FAILURE,
};
