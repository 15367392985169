import ForgotModalComponent from 'modules/forgotPassword/pages/ForgotModal.component';
import { LoginComponent } from 'modules/loginPage/pages';
import { VerifyEmailComponent } from 'modules/signUp/pages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { BannerApartment, BreadcumbApartment, MenuApartment } from '../common';
import * as actions from '../detailApartment.action';
import { ContactUs } from '../pages';

const GalleryComponent = props => {
  const { id, typeCode } = useParams();
  const { t } = useTranslation();
  const { language } = useParams();
  const { galleryInfo } = props.detailApartmentData;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const history = useHistory();
  const [apartmentId, setApartmentId] = useState(null);
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    showLoader();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    props.getGalleryRequest({ language, commonId: id });
    //eslint-disable-next-line
  }, []);

  const options = {
    settings: {
      disableWheelControls: true,
    },
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      backgroundColor: 'rgba(30,30,36,0.8)',
      iconColor: 'rgba(255, 255, 255, 0.8)',
      iconPadding: '8px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: '30px',
    },
  };

  const handleGallery = () => {
    hideLoader();
    if (Object.keys(galleryInfo).length !== 0) {
      return (
        <div className="apartment-gallery">
          <div className="detail-apartment-title">{galleryInfo.name}</div>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <div className="row">
                {galleryInfo
                  ? galleryInfo.images.map((item, index) => {
                      return (
                        <div className="col-12 col-sm-4 apartment-gallery__media pb-4" key={index}>
                          <img
                            className="apartment-gallery__image"
                            src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item.imageUrl}`}
                            alt={galleryInfo.name}
                          />
                        </div>
                      );
                    })
                  : ''}
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      );
    }
  };

  const handleShowLogin = () => {
    setShowLogin(true);
    setShowForgot(false);
  };

  const handleShowForgot = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleShowSignUp = () => {
    setShowLogin(false);
    setShowVerifyPopup(true);
  };
  const closeVerifyEmailPopup = () => {
    setShowVerifyPopup(false);
  };

  const handleButtonBook = () => {
    if (typeCode === 'long') {
      setShowContact(true);
    } else {
      const authUserData = JSON.parse(localStorage.getItem('authUserData'));
      if (authUserData) {
        history.push(`/${language}/payment-summary/${apartmentId}`);
      } else {
        handleShowLogin();
      }
    }
  };

  const getApartmentId = apartmentId => {
    setApartmentId(apartmentId);
  };

  return (
    <>
      <BreadcumbApartment />
      <BannerApartment />
      <MenuApartment getApartmentId={getApartmentId} />
      <ContactUs
        showLogin={showContact}
        closeLogin={() => {
          setShowContact(false);
        }}
      />
      <div id="detailApartmentGallery">
        <div className="container">
          {handleGallery()}
          {typeCode === 'long' ?
            <div
              style={{ marginTop: `${Object.keys(galleryInfo).length !== 0 ? '' : '143px'}` }}
              className="d-flex justify-content-center mt-5"
            >
              <p>{t('detailApartment.contact_us_note')}</p>
            </div> : ''
          }
          <div
            style={{ marginTop: `${Object.keys(galleryInfo).length !== 0 ? '' : '143px'}` }}
            className="d-flex justify-content-center mg-top-bottom"
          >
            <input
              className="btn-booknow"
              type="button"
              onClick={handleButtonBook}
              value={typeCode === 'long' ? t('detailApartment.contact_us_title') : t('detailApartment.book_now_title')}
            />
          </div>
        </div>
      </div>
      <LoginComponent
        closeLogin={() => {
          setShowLogin(false);
        }}
        showLogin={showLogin}
        handleShowForgot={handleShowForgot}
        handleShowSignUp={handleShowSignUp}
      />
      <VerifyEmailComponent
        showVerifyEmail={showVerifyPopup}
        hideVerifyEmail={closeVerifyEmailPopup}
      ></VerifyEmailComponent>
      <ForgotModalComponent
        showModal={showForgot}
        hideModal={() => setShowForgot(false)}
        handleShowLogin={handleShowLogin}
      />
      {loader}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGalleryRequest: actions.getGalleryRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GalleryComponent);
