import axios from 'axios';

class Service {
  constructor() {
    let isnService = axios.create({
      headers: { 'Content-Type': 'application/json' },
      baseURL: window._env_ && window._env_.REACT_ISN_API ? window._env_.REACT_ISN_API : process.env.REACT_ISN_API,
    });
    isnService.interceptors.response.use(this.handleSuccess, this.handleError);
    this.isnService = isnService;

    let isnServiceFormData = axios.create({
      headers: { 'Content-Type': 'multipart/form-data' },
      baseURL: window._env_ && window._env_.REACT_ISN_API ? window._env_.REACT_ISN_API : process.env.REACT_ISN_API,
    });
    isnServiceFormData.interceptors.response.use(this.handleSuccess, this.handleError);
    this.isnServiceFormData = isnServiceFormData;
  }

  handleIsnErr = err => {
    switch (err.res.status) {
      case 401:
        this.isnRedirectTo(document, '/');
        break;
      case 404:
      default:
        break;
    }
    return Promise.reject(err);
  };

  handleSuccess(response) {
    return response;
  }

  handleError = error => {
    return Promise.reject(error?.response?.status);
  };

  isnRedirectTo = (document, path) => {
    document.location = path;
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  isnGet(path, callback) {
    return this.isnService.get(`${process.env.REACT_APP_API}${path}`).then(response => callback(response.data));
  }

  isnPost(path, payload, callback) {
    return this.isnService
      .request({
        method: 'POST',
        url: `${process.env.REACT_APP_API}${path}`,
        responseType: 'json',
        data: payload,
      })
      .then(response => callback(response.data));
  }

  isnPostFormData(path, payload, callback) {
    return this.isnServiceFormData
      .request({
        method: 'POST',
        url: `${process.env.REACT_APP_API}${path}`,
        data: payload,
      })
      .then(response => callback(response));
  }

  setHeaderToken(token) {
    this.isnService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    this.isnServiceFormData.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

export default new Service();
