import React, { useRef, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import * as actions from '../../redux/layout.action';

import SearchResidence from '../searchs/residence/Residence';
import SearchOffices from '../searchs/office/Office';
import { bindActionCreators } from 'redux';
import { NAV_LEFT, NAV_RIGHT } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../../utils/params';
import { useLocation } from 'react-router-dom';
const tab_home_active = 'tab_home_active';
const long_term = 'long_term';
const short_term = 'short_term';
const BannerSlides = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(true);
  const [height, setHeight] = useState(true);
  const slideRef = useRef(null);
  const currentLocale = localStorage.getItem('LOCATION_CITY')
    ? localStorage.getItem('LOCATION_CITY')
    : localStorage.getItem('DEFAULT_CITY');

  useEffect(() => {
    let cityId = currentLocale;
    props.getBannerHomePageRequest({ language, cityId, pageSize: 5, pageIndex: 1 });
    sessionStorage.removeItem('searchDataCheckInOut');
    // eslint-disable-next-line
  }, [currentLocale]);

  const options = {
    items: 1,
    margin: 0,
    nav: true,
    navText: [NAV_LEFT, NAV_RIGHT],
    dots: true,
    loop: true,
    autoplay: true,
    autoHeight: true,
    slideTransition: 'linear',
    autoplaySpeed: true,
    autoplayTimeout: 5000,
    responsive: {},
    animateOut: 'fadeOut',
  };

  const { items } = props.layout.bannerHomePage || null;

  const onClickLongTermTab = () => {
    sessionStorage.setItem(tab_home_active, long_term);
    setShowSearch(true);
    setHeight(true);
  };
  const onClickShortTermTab = () => {
    sessionStorage.setItem(tab_home_active, short_term);
    setShowSearch(false);
    setHeight(true);
  };

  // eslint-disable-next-line
  const slideBanner = useMemo(() => {
    if (items && items.length) {
      return (
        <OwlCarousel
          options={options}
          ref={slideRef}
          className="banner nav-top owl-carousel owl-theme owl-loaded owl-drag"
        >
          {items.map((item, index) => (
            <div key={index}>
              <div
                data={item.imageUrl}
                className="slides header-banner"
                style={{ backgroundImage: `url(${process.env.REACT_APP_ISN_IMAGE_URL}${item.imageUrl})` }}
              ></div>
              <div className="banner-text container">
                <h2 className="title-work">{item.name}</h2>
                <div className="content-work mt-4 mb-2">{item.subTitle}</div>
                {item.titleButton && item.url ? (
                  <span onClick={() => window.open(item.url, '_blank')} className="button-work">
                    {item.titleButton}
                  </span>
                ) : (
                  ''
                )}
                {item.titleButton && item.url === null ? <span className="button-work">{item.titleButton}</span> : ''}
              </div>
            </div>
          ))}
        </OwlCarousel>
      );
    }
  });

  return (
    <>
      <section
        id="section-banner"
        className={`${
          (height && 'section-block section-banner pt-0 less-height') || 'section-block section-banner pt-0 more-height'
        }`}
      >
        {slideBanner}
        <div className="container search-wrapper">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="search-form jp-font-sawarabi-gothic">
                <ul className="nav nav-tabs" id="searchForm" role="tablist">
                  <li className="nav-item" role="presentation">
                    <span
                      onClick={() => {
                        onClickLongTermTab();
                      }}
                      className={showSearch === true ? 'nav-link active' : 'nav-link disable'}
                      id="Residence-tab"
                      data-toggle="tab"
                      role="tab"
                    >
                      {t('home_page.search.tab_long_term')}
                    </span>
                  </li>
                  <li className="nav-item" role="presentation">
                    <span
                      onClick={() => {
                        onClickShortTermTab();
                      }}
                      className={showSearch === false ? 'nav-link active' : 'nav-link disable'}
                      id="Office-tab"
                      data-toggle="tab"
                      role="tab"
                    >
                      {t('home_page.search.tab_short_term')}
                    </span>
                  </li>
                </ul>
                {showSearch && <SearchResidence />}
                {!showSearch && <SearchOffices />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getBannerHomePageRequest: actions.getBannerHomePageRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BannerSlides);
