import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HomeServiced from './HomeServiced.component';
import HomeProject from './HomeProject.component';
import HomeAboutUs from './HomeAboutUs.component';
import BannerSlides from '../../../layouts/commons/bannerSlides/BannerSlides';
import HomeEssentials from './HomeEssentials.component';
import HomeBlog from './HomeBlog.component';

const HomeComponent = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <BannerSlides />
      <HomeEssentials />
      <div className="section-block background-gray">
        <HomeServiced
          section="RESIDENCES_LIKE"
          key="Hot Properties"
          headerTitle={t('home_page.hot_properties')}
          class={'host-properties'}
          metaKey="hot"
        />
      </div>
      <HomeProject />
      <HomeBlog />
      <HomeAboutUs />
    </>
  );
};

export default withRouter(HomeComponent);
