import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  OverviewComponent,
  AmenitiesComponent,
  LocationComponent,
  GalleryComponent,
  UserReviewComponent,
  RatingReviewComponent,
  OffersComponent
} from '../pages';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default {
  OverviewContainer: connect(mapStateToProps, mapDispatchToProps)(OverviewComponent),
  AmenitiesContainer: connect(mapStateToProps, mapDispatchToProps)(AmenitiesComponent),
  LocationContainer: connect(mapStateToProps, mapDispatchToProps)(LocationComponent),
  GalleryContainer: connect(mapStateToProps, mapDispatchToProps)(GalleryComponent),
  UserReviewContainer: connect(mapStateToProps, mapDispatchToProps)(UserReviewComponent),
  RatingReviewContainer: connect(mapStateToProps, mapDispatchToProps)(RatingReviewComponent),
  OffersContainer: connect(mapStateToProps, mapDispatchToProps)(OffersComponent),
};
