import React, { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../forgotPassword.action';
import { Close, ISN } from '../../../assets/images/web-isn';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { useTranslation } from 'react-i18next';

const ForgotModal = props => {
  const { t } = useTranslation();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [email, setEmail] = useState('');
  const [err, setErr] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [succMessage, setSuccMessage] = useState('');

  const emailCheck = e => {
    setErrMessage('');
    setSuccMessage('');
    let emailInput = e.target.value.trim();
    let regexEmail = /^([0-9a-zA-Z]([+\-_.][0-9a-zA-Z])*)+@([a-zA-Z_]+?\.)+[a-zA-Z]{2,4}$/;
    if (emailInput.length > 255) {
      emailInput = emailInput.slice(0, 255);
    }
    setEmail(emailInput);
    if (emailInput === '' && emailInput.length === 0) {
      setErr(t('forgot_password_isn.msg_email_required'));
    } else if (!regexEmail.test(emailInput)) {
      setErr(t('forgot_password_isn.msg_email_invalid'));
    } else {
      setErr('');
    }
  };

  const validateEmail = e => {
    e.preventDefault();
    let info = {
      email: email,
      language: localStorage.getItem('language'),
    };
    props.fetchResetPasswordRequested({ info, handleIsExist, handleTokenFailed });
    showLoader();
    setEmail('');
  };

  const handleTokenFailed = error => {
    if (error === 400) {
      setErrMessage(t('forgot_password_isn.msg_failed'));
      setSuccMessage('');
    } else {
      setErrMessage('Something were wrong, please try again later.');
      setSuccMessage('');
    }
    hideLoader();
  };

  const handleIsExist = isExist => {
    if (isExist === 1) {
      setErrMessage('');
      setSuccMessage(t('forgot_password_isn.msg_email_send'));
      hideLoader();
    } else {
      setErrMessage('Something were wrong, please try again later.');
      setSuccMessage('');
    }
    hideLoader();
  };

  const disableResetButton = () => {
    if (email === '' || err !== '') {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseModal = () => {
    setEmail('');
    setErr('');

    setErrMessage('');
    setSuccMessage('');
    props.hideModal();
  };

  return (
    <React.Fragment>
      <Modal className="forgot-modal" centered show={props.showModal} backdrop="static" onHide={handleCloseModal}>
        <form onSubmit={validateEmail}>
          <div className="close-btn" onClick={handleCloseModal}>
            <img src={Close} alt="close-icon" />
          </div>
          <div className="logo text-center">
            <img src={ISN} alt="isn-logo"></img>
          </div>
          <div className="forgot-form">
            <h2 className="title text-center">{t('forgot_password_isn.forgot_title')}</h2>
            {errMessage && <Alert className="message-err mt-3 mb-0">{errMessage}</Alert>}
            {succMessage && <Alert className="message-succ mt-3 mb-0">{succMessage}</Alert>}
            <div className="form-group mt-3 mb-0">
              <label className="field-name text-left mt-0 mb-2">
                {t('forgot_password_isn.label_email')}
                <span className="required-mask">&nbsp;*</span>
              </label>
              <input
                type="text"
                value={email}
                onChange={e => emailCheck(e)}
                className={err !== '' ? 'input-email-err' : 'input-email'}
              />
            </div>
            {err !== '' ? (
              <div className="email-validate-error">
                <p className="mt-0 mb-0">{err}</p>
              </div>
            ) : (
              ''
            )}
            <h4 className="w-100 remind-text text-left mt-3">{t('forgot_password_isn.text_remind')}</h4>
            <button className="reset-button mt-3" disabled={disableResetButton()} type="submit">
              {t('forgot_password_isn.btn_reset_password')}
            </button>
            <div className="back-to-login text-center mt-4">
              {t('forgot_password_isn.label_remember')}{' '}
              <Link
                to="/"
                onClick={e => {
                  e.preventDefault();
                  props.handleShowLogin();
                }}
              >
                {t('forgot_password_isn.label_return')}
              </Link>
            </div>
          </div>
        </form>
      </Modal>
      {loader}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchResetPasswordRequested: actions.fetchResetPasswordRequested,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotModal);
