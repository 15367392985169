import { get } from 'lodash';
import Service from '../../services';

export const fetchSpecial = async params => {
  const response = await Service.isnGet(`/api/speciallists/detail${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getSpecialName = async params => {
  const response = await Service.isnGet(`/api/speciallists/get-name${params}`, res => res);
  return response;
};
