import { get } from 'lodash';
import Service from '../../services';

export const getUserProfile = async () => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnGet(`/api/userprofile/get-user`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const postChangeProfile = async params => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnPostFormData(`/api/userprofile/change-info`, params, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const postChangePassword = async params => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnPostFormData(`/api/userprofile/change-password`, params, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const postLogout = async params => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnPost(`/api/userprofile/logout`, params, res => res);
  return response;
};

export const getListBookings = async params => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnGet(`/api/booking/get-bookings${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getFavoritesAccount = async params => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnGet(`/api/userprofile/get-favorites${params}`, res => res);
  return response;
};

export const postRemoveFavorites = async params => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnPost(`/api/userprofile/remove-favorites${params}`, '', res => res);
  const data = get(response, 'data', null);
  return data;
};
