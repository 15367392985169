import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HomeComponent from '../pages/Home.component';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
