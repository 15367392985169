import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { history } from './utils/history';

import SwitchRouter from './router/SwitchRouter';
import { getLanguage } from './utils/params';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import decode from 'jwt-decode';
import * as loginActions from '../src/modules/loginPage/login.action';
import * as actions from '../src/layouts/redux/layout.action';

const App = props => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const data = fetch('/manifest.json');
  data
    .then(r => r.json())
    .then(data => {
      localStorage.setItem('EXCHANGE_RATE', data.exchange_rate);
    });

  const languagePathname = location.pathname.split('/')[1];
  const authUserData = JSON.parse(localStorage.getItem('authUserData'));

  const handleSuccess = (access_token, refresh_token) => {
    const newAuthUserData = JSON.stringify({
      ...authUserData,
      token: {
        access_token: access_token,
        refresh_token: refresh_token,
      },
    });
    localStorage.setItem('authUserData', newAuthUserData);
  };

  const handleError = error => {
    switch (error) {
      case 400:
        localStorage.removeItem('authUserData');
        props.fetchLogoutSuccess();
        if (window.location.href.includes('/payment-summary/') || window.location.href.includes('/booking-confirm/')) {
          history.push('/');
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (authUserData) {
      const token = decode(authUserData.token.access_token);
      if (authUserData.rememberMe === true) {
        if (token.exp < new Date().getTime() / 1000) {
          const dataToken = JSON.stringify({
            tokenOld: authUserData.token.access_token,
            refreshToken: authUserData.token.refresh_token,
          });
          props.fetchRefreshTokenRequest({ dataToken, handleSuccess, handleError });
        }
      } else {
        if (token.exp < new Date().getTime() / 1000) {
          localStorage.removeItem('authUserData');
          props.fetchLogoutSuccess();
          if (
            window.location.href.includes('/payment-summary/') ||
            window.location.href.includes('/booking-confirm/')
          ) {
            history.push('/');
          }
        }
      }
    }

    if (!window.location.href.includes('/en/') && !window.location.href.includes('/vi/') && !window.location.href.includes('/jp/')) {
      localStorage.setItem('language', 'en');
    }
    const languageLocaleStorage = localStorage.getItem('language');
    if (languageLocaleStorage === null) {
      localStorage.setItem('language', 'en');
    } else {
      localStorage.setItem('language', languageLocaleStorage);
    }

    const defaultCityId = localStorage.getItem('DEFAULT_CITY');
    if (defaultCityId === null) {
      localStorage.setItem('DEFAULT_CITY', 1);
    } else {
      localStorage.setItem('DEFAULT_CITY', defaultCityId);
    }

    const currency = localStorage.getItem('CURRENCY');
    if (currency && currency.code !== 'USD') {
      localStorage.setItem('CURRENCY', currency);
    }

    // if (languageLocaleStorage !== location.pathname.split('/')[1]) {
    //   const indexVi = window.location.href.indexOf('/vi/');
    //   const indexEn = window.location.href.indexOf('/en/');
    //   const indexJp = window.location.href.indexOf('/jp/');
    //   const index = indexVi + indexEn + indexJp + 5;
    //   if (indexEn < 0 && indexJp < 0 && indexVi < 0) {
    //     window.location.href = `/${languageLocaleStorage}`;
    //   } else {
    //     window.location.href = `/${languageLocaleStorage}${window.location.href.slice(index)}`;
    //   }
    // }

    if (languageLocaleStorage !== languagePathname) {
      localStorage.setItem('language', languagePathname);
    }

    if (
      !languageLocaleStorage &&
      (languagePathname === '' || (languagePathname !== 'en' && languagePathname !== 'jp' && languagePathname !== 'vi'))
    ) {
      localStorage.setItem('language', 'en');
      history.push(`/en` + location.search);
    }

    if (
      languageLocaleStorage &&
      (languagePathname === '' || (languagePathname !== 'en' && languagePathname !== 'jp' && languagePathname !== 'vi'))
    ) {
      localStorage.setItem('language', languageLocaleStorage);
      history.push(`/` + languageLocaleStorage + location.search);
    }

    const setLanguage = async data => {
      if (data) {
        if (data === 'ja' && !location.pathname.startsWith('/jp')) {
          if (location.pathname.startsWith('/en')) {
            history.push(location.pathname.replace('/en', '/jp') + location.search);
          }
        } else if (data === 'en') {
          if (!location.pathname.startsWith('/en')) {
            if (location.pathname.startsWith('/jp')) {
              history.push(location.pathname.replace('/jp', '/en') + location.search);
            }
          }
        } else if (data === 'vi') {
          if (!location.pathname.startsWith('/vi')) {
            if (location.pathname.startsWith('/en')) {
              history.push(location.pathname.replace('/en', '/vi') + location.search);
            }
          }
        }

        await i18n.changeLanguage(data === 'ja' ? 'jp' : data === 'en' ? 'en' : 'vi');
        // await localStorage.setItem('language', data === 'ja' ? 'jp' : data === 'vi' ? 'vi' : 'en');
      }
    };

    let language = '';
    const getLanguageBrowser =
      navigator.userLanguage || navigator.language || window.navigator.userLanguage || window.navigator.language;
    if (
      location.pathname.startsWith('/jp') ||
      location.pathname.startsWith('/en') ||
      location.pathname.startsWith('/vi')
    ) {
      if (location.pathname.startsWith('/jp')) language = 'ja';
      else if (location.pathname.startsWith('/en')) language = 'en';
      else if (location.pathname.startsWith('/vi')) language = 'vi';
    } else if (languageLocaleStorage) {
      if (languageLocaleStorage === 'jp') language = 'ja';
      else if (languageLocaleStorage === 'en') language = 'en';
      else if (languageLocaleStorage === 'vi') language = 'vi';
    } else if (getLanguageBrowser) {
      if (getLanguageBrowser === 'ja' || getLanguageBrowser.toLowerCase() === 'ja-jp') language = 'ja';
      else if (getLanguageBrowser === 'en') language = 'en';
      else if (getLanguageBrowser === 'vi') language = 'vi';
    } else language = getLanguage(location);

    setLanguage(language);
    // eslint-disable-next-line
  }, [i18n, location, languagePathname]);

  return <SwitchRouter />;
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRefreshTokenRequest: loginActions.fetchRefreshTokenRequest,
      fetchLogoutSuccess: actions.fetchLogoutSuccess,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
