import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../signUp.action';
import * as actionsLogin from '../../loginPage/login.action';
import InputCommon from '../common/Input.component';
import { useHistory, useLocation } from 'react-router-dom';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { Expried } from '../../../assets/images/web-isn';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../../utils/params';

const SignUpFormComponent = props => {
  const { t } = useTranslation();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let history = useHistory();

  const [isFullNameErr, setIsFullNameErr] = useState(false);
  const [fulltNameErrMsg, setFullNameErrMsg] = useState('');

  const [isPasswordErr, setIsPasswordErr] = useState(false);
  const [passwordErrMsg, setPasswordErrMsg] = useState('');

  const [isConfirmPasswordErr, setIsConfirmPasswordErr] = useState(false);
  const [confirmPasswordErrMsg, setConfirmPasswordErrMsg] = useState('');

  const [isPhoneNumberErr, setIsPhoneNumberErr] = useState(false);
  const [phoneNumberErrMsg, setPhoneNumberErrMsg] = useState('');

  const [fulltName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [showExpriedPage, setShowExpriedPage] = useState(false);
  const getHref = window.location.href;
  const location = useLocation();
  const language = getLanguage(location);
  const params = new URLSearchParams(window.location.search);
  const verifyKey = encodeURIComponent(params.get('verify-key'));
  const email = window.location.search.slice(window.location.search.lastIndexOf('&email=') + 7, window.location.search.length);
  useEffect(() => {
    var info = {
      code: verifyKey,
    };
    props.fetchVerifyKeyEmailRequested({ info, verifySuccess, verifyFail });
    showLoader();

    // eslint-disable-next-line
  }, []);

  const verifySuccess = () => {
    setShowExpriedPage(false);
    hideLoader();
  };

  const verifyFail = error => {
    if (error === 400) {
      setShowExpriedPage(true);
    }
    hideLoader();
  };

  const validateFullName = value => {
    if (value === '') {
      setIsFullNameErr(true);
      setFullNameErrMsg(t('signup_isn.signup_form.full_name_required'));
    } else {
      setIsFullNameErr(false);
      setFullNameErrMsg('');
    }
    setFullName(value);
  };

  const validatePassword = value => {
    const regexPwd = /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,48}$/;
    if (value === '') {
      setIsPasswordErr(true);
      setPasswordErrMsg(t('signup_isn.signup_form.password_required'));
    } else if (!regexPwd.test(value)) {
      setIsPasswordErr(true);
      setPasswordErrMsg(t('signup_isn.signup_form.password_invalid'));
    } else {
      setIsPasswordErr(false);
      setPasswordErrMsg('');
    }
    setPassword(value);
  };

  const validateConfirmPassword = value => {
    if (value === '') {
      setIsConfirmPasswordErr(true);
      setConfirmPasswordErrMsg(t('signup_isn.signup_form.confirm_password_required'));
    } else if (value !== password && value !== '') {
      setIsConfirmPasswordErr(true);
      setConfirmPasswordErrMsg(t('signup_isn.signup_form.password_not_match'));
    } else {
      setIsConfirmPasswordErr(false);
      setConfirmPasswordErrMsg('');
    }
    setConfirmPassword(value);
  };

  const getPhoneNumber = value => {
    value = value.replace(/[^\d/+-]/g, '');
    value = value.replace(/^([+0-9]{3})([0-9]{3})([0-9]{4})$/, '$1$2$3');
    if (value.length < 7 && value !== '') {
      setIsPhoneNumberErr(true);
      setPhoneNumberErrMsg(t('signup_isn.signup_form.phone_number_invalid'));
    } else {
      setIsPhoneNumberErr(false);
      setPhoneNumberErrMsg('');
    }
    setPhoneNumber(value);
  };

  const getCompanyName = value => {
    if (value.length > 255) {
      value = value.slice(0, 255);
    }
    setCompanyName(value);
  };

  const completeSignup = async (fulltName, password, phoneNumber, companyName, key, e) => {
    e.preventDefault();
    var userInfo = {
      fulltName: fulltName,
      email: email,
      phoneNumber: phoneNumber,
      companyName: companyName,
      password: password,
      verifyKey: key,
    };
    await props.fetchCompleteSignUpRequested({ userInfo, signupComplete, signupFailed });
    showLoader();
  };

  const signupComplete = () => {
    var info = {
      email: email,
      password: password,
    };
    props.fetchLoginRequest({ info, handleSuccess, handleError });
    showLoader();
  };

  const signupFailed = error => {
    console.log('error fail: ', error);
    hideLoader();
  };

  const handleError = error => {
    console.log('error fail: ', error);
    hideLoader();
  };

  const handleSuccess = (data, data2) => {
    const authUserData = {
      rememberMe: false,
      token: {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      },
      userInfo: data2,
    };
    localStorage.setItem('authUserData', JSON.stringify(authUserData));
    hideLoader();
    if (getHref.includes(`/sign-up/?verify-key`)) {
      history.push(`/${language}/`);
    }
  };

  const disableButtonSignup = () => {
    if (
      fulltName === '' ||
      password === '' ||
      confirmPassword === '' ||
      fulltNameErrMsg !== '' ||
      passwordErrMsg !== '' ||
      confirmPasswordErrMsg !== '' ||
      phoneNumberErrMsg !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  const redirectHome = () => {
    history.push(`/${language}/`);
  };

  const expriedPage = () => {
    return (
      <>
        <div className="expried-page px-3">
          <div className="items-expried d-flex flex-column align-items-center">
            <div className="expried-img">
              <img src={Expried} alt="expried-page"></img>
            </div>
            <p className="title-expried mt-3 text-center">{t('signup_isn.expried_page.page_title')}</p>
            <p className="text-expried">{t('signup_isn.expried_page.message_notify')}</p>
            <button className="button-expried" onClick={redirectHome}>
              {t('signup_isn.expried_page.btn_gohome')}
            </button>
          </div>
        </div>
      </>
    );
  };

  const signupPage = () => {
    return (
      <>
        <div className="container-fluid">
          <form
            onSubmit={e => completeSignup(fulltName, password, phoneNumber, companyName, verifyKey, e)}
            className="col-12 col-md-10 col-8 text-center mx-auto"
          >
            <div className="form-signup mx-auto my-5">
              <h4 className="signup-title">{t('signup_isn.signup_form.modal_title')}</h4>
              <InputCommon
                name="last-name"
                title={t('signup_isn.signup_form.label_full_name')}
                type="text"
                mask="1"
                isError={isFullNameErr}
                msgError={fulltNameErrMsg}
                validateInputChange={validateFullName}
                maxLength="25"
              />
              <InputCommon
                name="email"
                title={t('signup_isn.signup_form.label_email')}
                type="email"
                mask="1"
                value={email}
              />
              <InputCommon
                name="phone-number"
                title={t('signup_isn.signup_form.label_phone_number')}
                type="phoneNumber"
                mask="0"
                isError={isPhoneNumberErr}
                msgError={phoneNumberErrMsg}
                validateInputChange={getPhoneNumber}
                maxLength="15"
                value={phoneNumber}
              />
              <InputCommon
                name="company-name"
                title={t('signup_isn.signup_form.label_company_name')}
                type="text"
                mask="0"
                isError={false}
                msgError=""
                validateInputChange={getCompanyName}
                maxLength="255"
              />
              <InputCommon
                name="password"
                title={t('signup_isn.signup_form.label_password')}
                type="password"
                mask="1"
                isError={isPasswordErr}
                msgError={passwordErrMsg}
                validateInputChange={validatePassword}
                maxLength="48"
              />
              <InputCommon
                name="confirm-password"
                title={t('signup_isn.signup_form.label_confirm_password')}
                type="password"
                mask="1"
                isError={isConfirmPasswordErr}
                msgError={confirmPasswordErrMsg}
                validateInputChange={validateConfirmPassword}
                maxLength="48"
              />
              <button type="submit" className="sub-btn my-4" disabled={disableButtonSignup()}>
                {t('signup_isn.signup_form.btn_signup')}
              </button>
              <p className="confirm-text">{t('signup_isn.signup_form.text_term')}</p>
            </div>
          </form>
        </div>
        {loader}
      </>
    );
  };

  return <>{showExpriedPage === true ? expriedPage() : signupPage()}</>;
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchVerifyKeyEmailRequested: actions.fetchVerifyKeyEmailRequested,
      fetchCompleteSignUpRequested: actions.fetchCompleteSignUpRequested,
      fetchLoginRequest: actionsLogin.fetchLoginRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFormComponent);
