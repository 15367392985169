import * as types from './detailApartment.types';

export const getBannerRequest = params => ({ type: types.GET_BANNER_REQUEST, params });
export const getBannerSuccess = data => ({ type: types.GET_BANNER_SUCCESS, data });
export const getBannerFailure = error => ({ type: types.GET_BANNER_FAILURE, error });

export const getOverviewRequest = params => ({ type: types.GET_OVERVIEW_REQUEST, params });
export const getOverviewSuccess = data => ({ type: types.GET_OVERVIEW_SUCCESS, data });
export const getOverviewFailure = error => ({ type: types.GET_OVERVIEW_FAILURE, error });

export const getAmenitiesRequest = params => ({ type: types.GET_AMENITIES_REQUEST, params });
export const getAmenitiesSuccess = data => ({ type: types.GET_AMENITIES_SUCCESS, data });
export const getAmenitiesFailure = error => ({ type: types.GET_AMENITIES_FAILURE, error });

export const getLocationRequest = params => ({ type: types.GET_LOCATION_REQUEST, params });
export const getLocationSuccess = data => ({ type: types.GET_LOCATION_SUCCESS, data });
export const getLocationFailure = error => ({ type: types.GET_LOCATION_FAILURE, error });

export const getGalleryRequest = params => ({ type: types.GET_GALLERY_REQUEST, params });
export const getGallerySuccess = data => ({ type: types.GET_GALLERY_SUCCESS, data });
export const getGalleryFailure = error => ({ type: types.GET_GALLERY_FAILURE, error });

export const getRatingRequest = params => ({ type: types.GET_RATING_REQUEST, params });
export const getRatingSuccess = data => ({ type: types.GET_RATING_SUCCESS, data });
export const getRatingFailure = error => ({ type: types.GET_RATING_FAILURE, error });

export const getReviewRequest = params => ({ type: types.GET_REVIEW_REQUEST, params });
export const getReviewSuccess = data => ({ type: types.GET_REVIEW_SUCCESS, data });
export const getReviewFailure = error => ({ type: types.GET_REVIEW_FAILURE, error });
