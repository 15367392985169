import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTranslation } from 'react-i18next';
import { ArrowRightCircle } from '../../../../layouts/commons/icons';
import { getOwnerCategories } from 'modules/forOwnersPage/forOwners.api';
import { getLanguage, getQueryString, getPathLanguage } from '../../../../utils/params';

const ForOwnersContent = props => {
  const pathLanguage = getPathLanguage(useLocation());
  const language = getLanguage(useLocation());
  // const { t } = useTranslation();
  const [listOwner, setListOwner] = useState();

  useEffect(() => {
    props.showLoader()
    getOwnerCategories(getQueryString({ language })).then(res => {
      if (res) {
        setListOwner(res);
      }
      props.hideLoader();
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line
  }, [language]);

  return (
    <div className="overview-company">
      <div className="container-fluid">
        <div className="row">
          {listOwner && listOwner.length > 0 ?
            listOwner.map((item, index) => {
              return (
                <div key={index} className="col-12 col-md-6 pl-0 pr-0">
                  <div className="overview-company-image">
                    <Link className="h-100 w-100 company-image-link" to={item.id === 1 ? `${pathLanguage}/for-owners/create-your-property` : `${pathLanguage}/for-owners/faqs`}>
                      <img src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item.imageUrl}`} className="overview-company-image w-100 h-100" alt="" />
                      {/* <LazyLoadImage src={owners2}
                className="overview-company-image w-100 h-100" alt="" effect="blur" /> */}
                      <div className="overview-company-overlay w-100 h-100" />
                      <div className={`overview-company-title text-transform-none ${language === 'jp' ? 'font-noto-serif-cjk-jp' : '' }`}>
                        {item.title}
                        <div className="see-more">
                          <ArrowRightCircle />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            }) : ''}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForOwnersContent);
