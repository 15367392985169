import { call, put, takeEvery } from '@redux-saga/core/effects';
import * as actions from './accountPage.action';
import * as API from './accountPage.api';
import * as types from './accountPage.types';

export function* fetchAccountInfo() {
  try {
    const data = yield call(API.getUserProfile);
    yield put(actions.fetchAccountInfoSuccess(data));
  } catch (error) {
    yield put(actions.fetchAccountInfoFailure(error));
  }
}

export function* accountPageSaga() {
  yield takeEvery(types.FETCH_ACCOUNT_INFO, fetchAccountInfo);
}
