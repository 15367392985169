import * as actions from './layout.types';

const initialState = {
  listCity: [],
  listDistricts: [],
  units: '',
  listCondition: null,
  bannerHomePage: [],
  error: null,
  customerContact: [],
  projectsSearch: [],
  office: [],
  fileDownload: '',
  range: null,
  partner: null,
  listLanguage: [],
  listCurrency: [],
  listProjects: [],
  listRangeSetting: null,
  currentCurrency: null
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH LANGUAGE
    case actions.FETCH_LANGUAGES_REQUEST:
      return { ...state };
    case actions.FETCH_LANGUAGES_SUCCESS:
      return { ...state, listLanguage: action.data };
    case actions.FETCH_LANGUAGES_FAILURE:
      return { ...state, error: action.error };

    // FETCH CITIES
    case actions.FETCH_CITIES_REQUEST:
      return { ...state };
    case actions.FETCH_CITIES_SUCCESS:
      return { ...state, listCity: action.data };
    case actions.FETCH_CITIES_FAILURE:
      return { ...state, error: action.error };

    // FETCH DISTRICT BY ID
    case actions.FETCH_DISTRICTS_BY_CITYID_REQUEST:
      return { ...state };
    case actions.FETCH_DISTRICTS_BY_CITYID_SUCCESS:
      return { ...state, listDistricts: action.data };
    case actions.FETCH_DISTRICTS_BY_CITYID_FAILURE:
      return { ...state, error: action.error };

    // FETCH CURRENCY
    case actions.FETCH_CURRENCY_REQUEST:
      return { ...state };
    case actions.FETCH_CURRENCY_SUCCESS:
      return { ...state, listCurrency: action.data };
    case actions.FETCH_CURRENCY_FAILURE:
      return { ...state, error: action.error };
    case actions.FETCH_CURRENCY_CURRENT: 
      return { ...state, currentCurrency: action.data}

    // FETCH PROJECTS
    case actions.FETCH_PROJECTS_REQUEST:
      return { ...state };
    case actions.FETCH_PROJECTS_SUCCESS:
      return { ...state, listProjects: action.data };
    case actions.FETCH_PROJECTS_FAILURE:
      return { ...state, error: action.error };

    // // FETCH BANNERS BY ID
    case actions.GET_BANNER_HOME_PAGE_REQUEST:
      return { ...state };
    case actions.GET_BANNER_HOME_PAGE_SUCCESS:
      return { ...state, bannerHomePage: action.data };
    case actions.GET_BANNER_HOME_PAGE_FAILURE:
      return { ...state, error: action.error };

    // FETCH LIST CONDITION
    case actions.FETCH_LIST_CONDITION_REQUEST:
      return { ...state };
    case actions.FETCH_LIST_CONDITION_SUCCESS:
      return { ...state, listCondition: action.data };
    case actions.FETCH_LIST_CONDITION_FAILURE:
      return { ...state, error: action.error };

    // FETCH RANGE_SETTING
    case actions.FETCH_RANGE_SETTING_REQUEST:
      return { ...state };
    case actions.FETCH_RANGE_SETTING_SUCCESS:
      return { ...state, listRangeSetting: action.data };
    case actions.FETCH_RANGE_SETTING_FAILURE:
      return { ...state, error: action.error };

    // FETCH CUSTOMER_CONTACT
    case actions.FETCH_CUSTOMER_CONTACT_REQUEST:
      return { ...state };
    case actions.FETCH_CUSTOMER_CONTACT_SUCCESS:
      return { ...state, customerContact: action.data };
    case actions.FETCH_CUSTOMER_CONTACT_FAILURE:
      return { ...state, error: action.error };

    // GET UNITS
    // case actions.GET_UNITS_REQUEST:
    //   return { ...state };
    // case actions.GET_UNITS_SUCCESS:
    //   return { ...state, units: action.data };
    // case actions.GET_UNITS_FAILURE:
    //   return { ...state, error: action.error };

    // // FETCH CUSTOMER CONTACT
    // case actions.FETCH_CUSTOMER_CONTACT_REQUEST:
    //   return { ...state };
    // case actions.FETCH_CUSTOMER_CONTACT_SUCCESS:
    //   return { ...state, customerContact: action.data[0] };
    // case actions.FETCH_CUSTOMER_CONTACT_FAILURE:
    //   return { ...state, error: action.error };

    // // FETCH PROJECT SEARCH
    // case actions.FETCH_PROJECT_SEARCH_REQUEST:
    //   return { ...state };
    // case actions.FETCH_PROJECT_SEARCH_SUCCESS:
    //   return { ...state, projectsSearch: action.data };
    // case actions.FETCH_PROJECT_SEARCH_FAILURE:
    //   return { ...state, error: action.error };

    // // Office info
    // case actions.GET_FOOTER_INFORMATION_REQUEST:
    //   return { ...state };
    // case actions.GET_FOOTER_INFORMATION_SUCCESS:
    //   return { ...state, office: action.data };
    // case actions.GET_FOOTER_INFORMATION_FAILURE:
    //   return { ...state, error: action.error };

    // case actions.DOWNLOAD_MAGAZINE_REQUEST:
    //   return { ...state };
    // case actions.DOWNLOAD_MAGAZINE_SUCCESS:
    //   return { ...state, fileDownload: action.data };
    // case actions.DOWNLOAD_MAGAZINE_FAILURE:
    //   return { ...state, error: action.error };

    // // FETCH RANGE
    // case actions.FETCH_RANGE_REQUEST:
    //   return { ...state };
    // case actions.FETCH_RANGE_SUCCESS:
    //   return { ...state, range: action.data };
    // case actions.FETCH_RANGE_FAILURE:
    //   return { ...state, error: action.error };

    // GET PARTNER
    // case actions.GET_PARTNER_REQUEST:
    //   return { ...state };
    // case actions.GET_PARTNER_SUCCESS:
    //   return { ...state, partner: action.data };
    // case actions.GET_PARTNER_FAILURE:
    //   return { ...state, error: action.error };

    default:
      return state;
  }
};

export default layoutReducer;
