import * as types from './accountPage.types';

const initialState = {
  error: null,
  userInfo: null,
};

const accountPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_INFO:
      return { ...state };

    case types.FETCH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        userInfo: action.data,
      };
    }

    case types.FETCH_ACCOUNT_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default accountPageReducer;
