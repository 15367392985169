import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const InputCommon = props => {
  const { isError, msgError, title, value, validateInputChange, name, type, mask, maxLength } = props;

  const handleChange = e => {
    e.preventDefault();
    validateInputChange(e.target.value);
  };

  return (
    <div className="form-group d-flex flex-column mt-4">
      <div className="d-flex mb-2">
        <label className="field-name">{title}</label>
        {mask === '1' ? <span className="required-mask">&nbsp;*</span> : ''}
      </div>
      <input
        onChange={handleChange}
        name={name}
        className={`${isError ? 'field-err' : 'field-input'}`}
        type={type}
        disabled={type === 'email' ? true : false}
        maxLength={maxLength}
        value={value}
      />
      {isError && <div className="error-message">{msgError}</div>}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InputCommon);
