import { lazy } from 'react';
import { PublicLayout } from '../layouts';

const HomeContainer = lazy(() => import('../modules').then(module => ({ default: module.HomeContainer })));

const PreparationContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.PreparationContainer })),
);

const EmailURLContainer = lazy(() => import('../modules').then(module => ({ default: module.EmailURLContainer })));

/* Vnext ISN Component */
const SendURLContainer = lazy(() => import('../modules').then(module => ({ default: module.SendURLContainer })));

/* Login */
const LoginContainer = lazy(() => import('../modules').then(module => ({ default: module.LoginContainer })));

//Service apartment
const ServiceApartmentContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ServiceApartmentContainer })),
);
/* Detail Apartment */
/* Detail Apartment - Overview */
const OverviewContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailApartmentContainer.OverviewContainer })),
);

/* Detail Apartment - Amenities */
const AmenitiesContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailApartmentContainer.AmenitiesContainer })),
);

/* Detail Apartment - Loaction */
const LocationContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailApartmentContainer.LocationContainer })),
);

/* Detail Apartment - Gallery */
const GalleryContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailApartmentContainer.GalleryContainer })),
);

/* Detail Apartment - User Ratings & Reviews */
const UserReviewContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailApartmentContainer.UserReviewContainer })),
);

/* Detail Apartment - Ratings & Reviews */
const RatingReviewContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailApartmentContainer.RatingReviewContainer })),
);
/* Featured projects */
const FeaturedProjectsContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.FeaturedProjectsContainer })),
);

/* User Ratings & Reviews result */
const ConfirmReviewContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ConfirmPageContainer.ReviewApartmentContainer })),
);

/* Helps Page */
const HelpsContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.HelpsContainer.HelpsContainer })),
);
/* Help - Frequently asked issues*/
const FrequentlyAskedIssuesContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.HelpsContainer.FrequentlyAskedIssuesContainer })),
);

/* Help - Create issue */
const CreateIssueContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.HelpsContainer.CreateIssueContainer })),
);

/* Help - Create issue */
const PolicyContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.PolicyPageContainer.PolicyPageContainer })),
);

/* Detail Featured projects */
/* Detail Featured projects - Overview */
const ProjectOverviewContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailProjectContainer.OverviewContainer })),
);

/* Detail Featured projects - Apartment */
const ProjectApartmentComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailProjectContainer.ApartmentContainer })),
);

/* Detail Featured projects - Location */
const ProjectLocationComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailProjectContainer.LocationContainer })),
);

/* Detail Featured projects - Gallery */
const ProjectGalleryComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailProjectContainer.GalleryContainer })),
);

/* Detail Featured projects - Facilities */
const ProjectFacilitiesComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailProjectContainer.FacilitiesContainer })),
);

/* Detail Featured projects - Offers */
const ProjectOffersComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailProjectContainer.OffersContainer })),
);

/* Blog */
const BlogsContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.BlogsContainer.BlogsContainer })),
);

const BlogDetailContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.BlogsContainer.BlogDetailContainer })),
);

/*For owners */
const MainForOwnersContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ForOwnersContainer.MainForOwnersContainer })),
);
const FormCreateInfomationContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ForOwnersContainer.FormCreateContainer })),
);
const FaqsForOwnersContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ForOwnersContainer.FaqsContainer })),
);

/* Search result service apartment */
const SearchResultContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.SearchResultContainer.SearchResultContainer })),
);

/* Find Property */
const SearchPropertiesAndMapContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.SearchResultContainer.SearchPropertiesAndMapContainer })),
);

/* Detail Apartment - Offers */
const OffersContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.DetailApartmentContainer.OffersContainer })),
);

//List Offers
const ListOffersContainer = lazy(() => import('../modules').then(module => ({ default: module.ListOffersContainer })));

/* Servied Office - Overview */
const ServicedOfficeOverviewComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.ServicedOfficeOverviewComponent })),
);

/* Servied Office - Overview */
const LocationCommonComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.LocationCommonComponent })),
);
/* Servied Office - Types - Private Room */
const TypesPrivateRoomComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.TypesPrivateRoomComponent })),
);

/* Servied Office - Types - Work Station */
const TypesWorkStationComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.TypesWorkStationComponent })),
);

/* Servied Office - Types - Virtual Offices */
const TypesVirtualOfficesComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.TypesVirtualOfficesComponent })),
);

/* Servied Office - Types - Meeting Room */
const TypesMeetingRoomComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.TypesMeetingRoomComponent })),
);

/* Servied Office - Location */
const ServicedOfficeLocationComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.ServicedOfficeLocationComponent })),
);

/* Servied Office - Gallery */
const ServicedOfficeGalleryComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.ServicedOfficeGalleryComponent })),
);

// About us ISN JUTECT
const MainAboutContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MainAboutContainer })),
);
const AboutUsCompanyProfile = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.CompanyProfileContainer })),
);
const AboutUsCompanyPhilosophy = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.CompanyPhilosophyContainer })),
);
const AboutUsOurCompanyFeature = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.OurCompanyContainer })),
);
const AboutUsMeetOurTeam = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MeetOurContainer })),
);

const AboutUsGroupCompanies = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.GroupCompaniesContainer })),
);

const AboutUsWhyChooseUs = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.WhyChooseContainer })),
);
// About us - Contact us
const AboutUsContactUs = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.ContactUsContainer })),
);
// About us - Housing material
const MaterialOverviewContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MaterialOverviewContainer })),
);
const MaterialProductContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MaterialProductContainer })),
);
const MaterialGalleryContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MaterialGalleryContainer })),
);

/* Useful Information - Apartment Office */
const UsefulPageComponent = lazy(() => import('../modules').then(module => ({ default: module.UsefulPageComponent })));

/* Useful Information - Support Service */
const FaqsComponent = lazy(() => import('../modules').then(module => ({ default: module.FaqsComponent })));

/* Paymment Page */
const PaymentSummaryComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.PaymentSummaryComponent })),
);

/* Booking Confirm */
const BookingConfirmComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.BookingConfirmComponent })),
);
const FinishBookingComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.FinishBookingComponent })),
);

/* Manage Account */
const PersonalDetailComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.PersonalDetailComponent })),
);
const ChangePasswordComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.ChangePasswordComponent })),
);
const ListBookingComponent = lazy(() =>
  import('../modules').then(module => ({ default: module.ListBookingComponent })),
);
const FavouritesComponent = lazy(() => import('../modules').then(module => ({ default: module.FavouritesComponent })));

const publicRouters = {
  layout: PublicLayout,
  subRoutes: [
    /* home */
    { path: '/', component: HomeContainer, exact: true },
    { path: `/:language`, component: HomeContainer, exact: true },

    { path: '/preparation-page', component: PreparationContainer, exact: true },
    { path: `/:language/preparation-page`, component: PreparationContainer, exact: true },

    /* forgot password */
    { path: '/forgot-password', component: EmailURLContainer, exact: true },
    { path: `/:language/forgot-password`, component: EmailURLContainer, exact: true },

    /* Vnext ISN router */
    { path: '/sign-up', component: SendURLContainer, exact: true },
    { path: `/:language/sign-up`, component: SendURLContainer, exact: true },

    /* Login */
    { path: '/login', component: LoginContainer, exact: true },
    { path: `/:language/login`, component: LoginContainer, exact: true },

    //Service apartment
    { path: '/serviced-apartments', component: ServiceApartmentContainer, exact: true },
    { path: `/:language/serviced-apartments`, component: ServiceApartmentContainer, exact: true },

    /* Detail Apartment */
    /* Detail Apartment - Overview */
    { path: '/serviced-apartments/overview/:id/:slug/:typeCode', component: OverviewContainer, exact: true },
    {
      path: `/:language/serviced-apartments/overview/:id/:slug/:typeCode`,
      component: OverviewContainer,
      exact: true,
    },

    /* Detail Apartment - Amenities */
    { path: '/serviced-apartments/amenities/:id/:slug/:typeCode', component: AmenitiesContainer, exact: true },
    {
      path: `/:language/serviced-apartments/amenities/:id/:slug/:typeCode`,
      component: AmenitiesContainer,
      exact: true,
    },

    /* Detail Apartment - Loaction */
    { path: '/serviced-apartments/location/:id/:slug/:typeCode', component: LocationContainer, exact: true },
    {
      path: `/:language/serviced-apartments/location/:id/:slug/:typeCode`,
      component: LocationContainer,
      exact: true,
    },

    /* Detail Apartment - Gallery */
    { path: '/serviced-apartments/gallery/:id/:slug/:typeCode', component: GalleryContainer, exact: true },
    { path: `/:language/serviced-apartments/gallery/:id/:slug/:typeCode`, component: GalleryContainer, exact: true },

    /* User Ratings & Reviews */
    { path: '/send-review/', component: UserReviewContainer, exact: true },
    {
      path: `/:language/send-review/`,
      component: UserReviewContainer,
      exact: true,
    },

    /* Detail Apartment - Ratings & Reviews */
    { path: '/serviced-apartments/ratings-reviews/:id/:slug/:typeCode', component: RatingReviewContainer, exact: true },
    {
      path: `/:language/serviced-apartments/ratings-reviews/:id/:slug/:typeCode`,
      component: RatingReviewContainer,
      exact: true,
    },

    /* Detail Apartment - Offers */
    { path: '/serviced-apartments/offer-sub/:id/:slug/:typeCode', component: OffersContainer, exact: true },
    { path: `/:language/serviced-apartments/offer-sub/:id/:slug/:typeCode`, component: OffersContainer, exact: true },

    /* Featured project */
    { path: '/featured-projects', component: FeaturedProjectsContainer, exact: true },
    { path: `/:language/featured-projects`, component: FeaturedProjectsContainer, exact: true },

    /* Detail Project */
    /* Detail Project - Overview */
    { path: '/featured-projects/detail/overview/:id/:slug', component: ProjectOverviewContainer, exact: true },
    {
      path: `/:language/featured-projects/detail/overview/:id/:slug`,
      component: ProjectOverviewContainer,
      exact: true,
    },

    /* Detail Project - apartment-room-types */
    {
      path: '/featured-projects/detail/apartment-room-types/:id/:slug',
      component: ProjectApartmentComponent,
      exact: true,
    },
    {
      path: `/:language/featured-projects/detail/apartment-room-types/:id/:slug`,
      component: ProjectApartmentComponent,
      exact: true,
    },

    /* Detail Project - Location */
    { path: '/featured-projects/detail/location/:id/:slug', component: ProjectLocationComponent, exact: true },
    {
      path: `/:language/featured-projects/detail/location/:id/:slug`,
      component: ProjectLocationComponent,
      exact: true,
    },

    /* Detail Project - gallery */
    { path: '/featured-projects/detail/gallery/:id/:slug', component: ProjectGalleryComponent, exact: true },
    {
      path: `/:language/featured-projects/detail/gallery/:id/:slug`,
      component: ProjectGalleryComponent,
      exact: true,
    },

    /* Detail Project - facilities */
    { path: '/featured-projects/detail/facilities/:id/:slug', component: ProjectFacilitiesComponent, exact: true },
    {
      path: `/:language/featured-projects/detail/facilities/:id/:slug`,
      component: ProjectFacilitiesComponent,
      exact: true,
    },

    /* Detail Project - offers */
    { path: '/featured-projects/detail/offer-sub/:id/:slug', component: ProjectOffersComponent, exact: true },
    { path: `/:language/featured-projects/detail/offer-sub/:id/:slug`, component: ProjectOffersComponent, exact: true },

    /* Search Result Serviced Apartment */
    { path: '/search-result', component: SearchResultContainer, exact: true },
    { path: `/:language/search-result`, component: SearchResultContainer, exact: true },

    /* Find Property */
    { path: '/find-property/:category', component: SearchPropertiesAndMapContainer, exact: true },
    { path: `/:language/find-property/:category`, component: SearchPropertiesAndMapContainer, exact: true },

    /* List Offers */
    /* List Offers - Overview */
    { path: '/offers/overview/:id/:slug/:typeCode', component: OverviewContainer, exact: true },
    { path: `/:language/offers/overview/:id/:slug/:typeCode`, component: OverviewContainer, exact: true },

    /* List Offers - Amenities */
    { path: '/offers/amenities/:id/:slug/:typeCode', component: AmenitiesContainer, exact: true },
    { path: `/:language/offers/amenities/:id/:slug/:typeCode`, component: AmenitiesContainer, exact: true },

    /* List Offers - Loaction */
    { path: '/offers/location/:id/:slug/:typeCode', component: LocationContainer, exact: true },
    { path: `/:language/offers/location/:id/:slug/:typeCode`, component: LocationContainer, exact: true },

    /* List Offers - Gallery */
    { path: '/offers/gallery/:id/:slug/:typeCode', component: GalleryContainer, exact: true },
    { path: `/:language/offers/gallery/:id/:slug/:typeCode`, component: GalleryContainer, exact: true },

    /* List Offers - Ratings & Reviews */
    { path: '/offers/ratings-reviews/:id/:slug/:typeCode', component: RatingReviewContainer, exact: true },
    {
      path: `/:language/offers/ratings-reviews/:id/:slug/:typeCode`,
      component: RatingReviewContainer,
      exact: true,
    },

    /* Detail Apartment - Offers */
    { path: '/offers/offers/:id/:slug/:typeCode', component: OffersContainer, exact: true },
    { path: `/:language/offers/offers/:id/:slug/:typeCode`, component: OffersContainer, exact: true },

    // Blogs
    { path: '/blogs/:category/:id?', component: BlogsContainer, exact: true },
    { path: `/:language/blogs/:category/:id?`, component: BlogsContainer, exact: true },
    // { path: '/jp/blogs/:category/:id?', component: BlogsContainer, exact: true },

    // Blog detail
    { path: '/blog/:category/detail/:id/:slug', component: BlogDetailContainer, exact: true },
    { path: `/:language/blog/:category/detail/:id/:slug`, component: BlogDetailContainer, exact: true },
    // { path: '/jp/blog/:category/detail/:id/:slug', component: BlogDetailContainer, exact: true },

    /* for owners */
    { path: '/for-owners', component: MainForOwnersContainer, exact: true },
    { path: '/:language/for-owners', component: MainForOwnersContainer, exact: true },

    { path: '/for-owners/create-your-property', component: FormCreateInfomationContainer, exact: true },
    { path: '/:language/for-owners/create-your-property', component: FormCreateInfomationContainer, exact: true },

    { path: '/for-owners/faqs', component: FaqsForOwnersContainer, exact: true },
    { path: '/:language/for-owners/faqs', component: FaqsForOwnersContainer, exact: true },
    /* for owners */

    //List offers
    { path: '/offers', component: ListOffersContainer, exact: true },
    { path: `/:language/offers`, component: ListOffersContainer, exact: true },

    /* Servied Office - Overview */
    { path: '/serviced-office/overview', component: ServicedOfficeOverviewComponent, exact: true },
    { path: `/:language/serviced-office/overview`, component: ServicedOfficeOverviewComponent, exact: true },

    /* Servied Office - Types - Private Room */
    { path: '/serviced-office/types/private-room', component: TypesPrivateRoomComponent, exact: true },
    { path: `/:language/serviced-office/types/private-room`, component: TypesPrivateRoomComponent, exact: true },

    /* Servied Office - Types - Work Station */
    { path: '/serviced-office/types/work-space', component: TypesWorkStationComponent, exact: true },
    { path: `/:language/serviced-office/types/work-space`, component: TypesWorkStationComponent, exact: true },

    /* Servied Office - Types - Work Station */
    { path: '/serviced-office/types/virtual-offices', component: TypesVirtualOfficesComponent, exact: true },
    {
      path: `/:language/serviced-office/types/virtual-offices`,
      component: TypesVirtualOfficesComponent,
      exact: true,
    },

    /* Servied Office - Types - Meeting Room */
    { path: '/serviced-office/types/meeting-room', component: TypesMeetingRoomComponent, exact: true },
    { path: `/:language/serviced-office/types/meeting-room`, component: TypesMeetingRoomComponent, exact: true },

    /* Servied Office - Location */
    { path: '/serviced-office/location', component: ServicedOfficeLocationComponent, exact: true },
    { path: `/:language/serviced-office/location`, component: ServicedOfficeLocationComponent, exact: true },

    /* Servied Office - Gallery */
    { path: '/serviced-office/gallery', component: ServicedOfficeGalleryComponent, exact: true },
    { path: `/:language/serviced-office/gallery`, component: ServicedOfficeGalleryComponent, exact: true },

    /* Search result */
    /* Search result - Overview */
    { path: '/search-result/overview/:id/:slug/:typeCode', component: OverviewContainer, exact: true },
    { path: `/:language/search-result/overview/:id/:slug/:typeCode`, component: OverviewContainer, exact: true },

    /* Search result - Amenities */
    { path: '/search-result/amenities/:id/:slug/:typeCode', component: AmenitiesContainer, exact: true },
    { path: `/:language/search-result/amenities/:id/:slug/:typeCode`, component: AmenitiesContainer, exact: true },

    /* Search result - Loaction */
    { path: '/search-result/location/:id/:slug/:typeCode', component: LocationContainer, exact: true },
    { path: `/:language/search-result/location/:id/:slug/:typeCode`, component: LocationContainer, exact: true },

    /* Search result - Gallery */
    { path: '/search-result/gallery/:id/:slug/:typeCode', component: GalleryContainer, exact: true },
    { path: `/:language/search-result/gallery/:id/:slug/:typeCode`, component: GalleryContainer, exact: true },

    /* Search result - Ratings & Reviews */
    { path: '/search-result/ratings-reviews/:id/:slug/:typeCode', component: RatingReviewContainer, exact: true },
    {
      path: `/:language/search-result/ratings-reviews/:id/:slug/:typeCode`,
      component: RatingReviewContainer,
      exact: true,
    },
    { path: `/:language/search-result/offers/:id/:slug/:typeCode`, component: OffersContainer, exact: true },

    // /* Search result - Offers */
    // { path: '/search-result/offers/:id/:slug/:typeCode', component: OffersContainer, exact: true },
    // { path: '/en/search-result/offers/:id/:slug/:typeCode', component: OffersContainer, exact: true },
    // { path: '/jp/search-result/offers/:id/:slug/:typeCode', component: OffersContainer, exact: true },
    // { path: '/vi/search-result/offers/:id/:slug/:typeCode', component: OffersContainer, exact: true },

    // About us
    { path: '/:language/about-us', component: MainAboutContainer, exact: true },
    // About us - company profile
    { path: `/:language/about-us/company-profile`, component: AboutUsCompanyProfile, exact: true },
    { path: `/:language/about-us/company-philosophy`, component: AboutUsCompanyPhilosophy, exact: true },
    { path: `/:language/about-us/what-is-flesta`, component: AboutUsOurCompanyFeature, exact: true },
    { path: `/:language/about-us/meet-our-team`, component: AboutUsMeetOurTeam, exact: true },
    // About us - group companies
    { path: `/:language/about-us/group-companies`, component: AboutUsGroupCompanies, exact: true },
    { path: '/:language/about-us/why-choose-us', component: AboutUsWhyChooseUs, exact: true },
    // About us - contact us
    { path: `/:language/about-us/contact-us`, component: AboutUsContactUs, exact: true },
    // About us - housing material
    { path: `/:language/about-us/housing-material/overview`, component: MaterialOverviewContainer, exact: true },
    {
      path: `/:language/about-us/housing-material/products/:productid`,
      component: MaterialProductContainer,
      exact: true,
    },
    { path: `/:language/about-us/housing-material/gallery`, component: MaterialGalleryContainer, exact: true },

    /* Useful Information - Apartment Office */
    { path: `/useful-information/useful-service-apartment/:slug`, component: UsefulPageComponent, exact: true },
    {
      path: `/:language/useful-information/about-renting-an-apartment-office-in-vietnam/:slug`,
      component: UsefulPageComponent,
      exact: true,
    },

    /* Useful Information - Support Service */
    { path: '/useful-information/our-customer-support-service', component: FaqsComponent, exact: true },
    { path: `/:language/useful-information/our-customer-support-service`, component: FaqsComponent, exact: true },

    /* Useful Information - Rental Flow */
    { path: '/useful-information/apartment-renting-process', component: FaqsComponent, exact: true },
    { path: `/:language/useful-information/apartment-renting-process`, component: FaqsComponent, exact: true },

    /* User Ratings & Reviews result */
    { path: '/send-review/result', component: ConfirmReviewContainer, exact: true },
    { path: `/:language/send-review/result`, component: ConfirmReviewContainer, exact: true },

    /* Help page */
    { path: '/helps', component: HelpsContainer, exact: true },
    { path: `/:language/helps`, component: HelpsContainer, exact: true },

    /* Help - Frequently asked issues*/
    { path: '/helps/frequently-asked-issues', component: FrequentlyAskedIssuesContainer, exact: true },
    { path: `/:language/helps/frequently-asked-issues`, component: FrequentlyAskedIssuesContainer, exact: true },

    /* Help - Create issue */
    { path: '/helps/create-issue', component: CreateIssueContainer, exact: true },
    { path: `/:language/helps/create-issue`, component: CreateIssueContainer, exact: true },

    /* Policy - page */
    { path: '/policy/:category/:id?', component: PolicyContainer, exact: true },
    { path: `/:language/policy/:category/:id?`, component: PolicyContainer, exact: true },

    /* Useful Information - FAQ */
    { path: '/useful-information/faqs', component: FaqsComponent, exact: true },
    { path: `/:language/useful-information/faqs`, component: FaqsComponent, exact: true },

    { path: `/:language/test`, component: LocationCommonComponent, exact: true },

    /* Paymment Page */
    { path: '/payment-summary/:apartmentId', component: PaymentSummaryComponent, exact: true },
    { path: `/:language/payment-summary/:apartmentId`, component: PaymentSummaryComponent, exact: true },

    /* Booking Confirm */
    { path: '/booking-confirm/:bookingNumber', component: BookingConfirmComponent, exact: true },
    { path: `/:language/booking-confirm/:bookingNumber`, component: BookingConfirmComponent, exact: true },

    { path: '/finish-booking', component: FinishBookingComponent, exact: true },
    { path: `/:language/finish-booking`, component: FinishBookingComponent, exact: true },

    /* Manage Account */
    { path: '/manage-account/personal-detail', component: PersonalDetailComponent, exact: true },
    { path: `/:language/manage-account/personal-detail`, component: PersonalDetailComponent, exact: true },

    { path: '/manage-account/change-password', component: ChangePasswordComponent, exact: true },
    { path: `/:language/manage-account/change-password`, component: ChangePasswordComponent, exact: true },

    { path: '/list-booking', component: ListBookingComponent, exact: true },
    { path: `/:language/list-booking`, component: ListBookingComponent, exact: true },

    { path: '/favourites', component: FavouritesComponent, exact: true },
    { path: `/:language/favourites`, component: FavouritesComponent, exact: true },
  ],
};

export default publicRouters;
