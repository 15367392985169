import * as actions from './layout.types';

export const fetchLanguagesRequest = params => ({ type: actions.FETCH_LANGUAGES_REQUEST, params });
export const fetchLanguagesSuccess = data => ({ type: actions.FETCH_LANGUAGES_SUCCESS, data });
export const fetchLanguagesFailure = error => ({ type: actions.FETCH_LANGUAGES_FAILURE, error });

export const fetchCitiesRequest = params => ({ type: actions.FETCH_CITIES_REQUEST, params });
export const fetchCitiesSuccess = data => ({ type: actions.FETCH_CITIES_SUCCESS, data });
export const fetchCitiesFailure = error => ({ type: actions.FETCH_CITIES_FAILURE, error });

export const fetchDistrictsByCityIdRequest = params => ({ type: actions.FETCH_DISTRICTS_BY_CITYID_REQUEST, params });
export const fetchDistrictsByCityIdSuccess = data => ({ type: actions.FETCH_DISTRICTS_BY_CITYID_SUCCESS, data });
export const fetchDistrictsByCityIdFailure = error => ({ type: actions.FETCH_DISTRICTS_BY_CITYID_FAILURE, error });

export const fetchCurrencyRequest = params => ({ type: actions.FETCH_CURRENCY_REQUEST, params });
export const fetchCurrencySuccess = data => ({ type: actions.FETCH_CURRENCY_SUCCESS, data });
export const fetchCurrencyFailure = error => ({ type: actions.FETCH_CURRENCY_FAILURE, error });

export const fetchProjectsRequest = params => ({ type: actions.FETCH_PROJECTS_REQUEST, params });
export const fetchProjectsSuccess = data => ({ type: actions.FETCH_PROJECTS_SUCCESS, data });
export const fetchProjectsFailure = error => ({ type: actions.FETCH_PROJECTS_FAILURE, error });

export const getBannerHomePageRequest = params => ({ type: actions.GET_BANNER_HOME_PAGE_REQUEST, params });
export const getBannerHomePageSuccess = data => ({ type: actions.GET_BANNER_HOME_PAGE_SUCCESS, data });
export const getBannerHomePageFailure = error => ({ type: actions.GET_BANNER_HOME_PAGE_FAILURE, error });

export const fetchListConditionRequest = params => ({ type: actions.FETCH_LIST_CONDITION_REQUEST, params });
export const fetchListConditionSuccess = data => ({ type: actions.FETCH_LIST_CONDITION_SUCCESS, data });
export const fetchListConditionFailure = error => ({ type: actions.FETCH_LIST_CONDITION_FAILURE, error });

export const fetchRangeSettingRequest = params => ({ type: actions.FETCH_RANGE_SETTING_REQUEST, params });
export const fetchRangeSettingSuccess = data => ({ type: actions.FETCH_RANGE_SETTING_SUCCESS, data });
export const fetchRangeSettingFailure = error => ({ type: actions.FETCH_RANGE_SETTING_FAILURE, error });

export const fetchCustomerContactRequest = params => ({ type: actions.FETCH_CUSTOMER_CONTACT_REQUEST, params });
export const fetchCustomerContactSuccess = data => ({ type: actions.FETCH_CUSTOMER_CONTACT_SUCCESS, data });
export const fetchCustomerContactFailure = error => ({ type: actions.FETCH_CUSTOMER_CONTACT_FAILURE, error });
export const fetchCurrencyCurrent = data => ({type: actions.FETCH_CURRENCY_CURRENT, data})

export const fetchLogoutSuccess = (data) => ({ type: actions.FETCH_LOGOUT_SUCCESS, data });

// export const getUnitsRequest = params => ({ type: actions.GET_UNITS_REQUEST, params });
// export const getUnitsSuccess = data => ({ type: actions.GET_UNITS_SUCCESS, data });
// export const getUnitsFailure = error => ({ type: actions.GET_UNITS_FAILURE, error });

// export const fetchCitiesByLocaleRequest = params => ({ type: actions.FETCH_CITIES_BY_LOCALE_REQUEST, params });
// export const fetchCitiesByLocaleSuccess = data => ({ type: actions.FETCH_CITIES_BY_LOCALE_SUCCESS, data });
// export const fetchCitiesByLocaleFailure = error => ({ type: actions.FETCH_CITIES_BY_LOCALE_FAILURE, error });

// export const subscribeNewsletterRequest = params => ({ type: actions.SUBSCRIBE_NEWSLETTER_REQUEST, params });

// export const fetchProjectsSearchRequest = params => ({ type: actions.FETCH_PROJECT_SEARCH_REQUEST, params });
// export const fetchProjectsSearchSuccess = data => ({ type: actions.FETCH_PROJECT_SEARCH_SUCCESS, data });
// export const fetchProjectsSearchFailure = error => ({ type: actions.FETCH_PROJECT_SEARCH_FAILURE, error });

// export const getOfficeInformationRequest = params => ({ type: actions.GET_FOOTER_INFORMATION_REQUEST, params });
// export const getOfficeInformatioSuccess = data => ({ type: actions.GET_FOOTER_INFORMATION_SUCCESS, data });
// export const getOfficeInformatioFailure = error => ({ type: actions.GET_FOOTER_INFORMATION_FAILURE, error });

// export const downloadMagazineRequest = params => ({ type: actions.DOWNLOAD_MAGAZINE_REQUEST, params });
// export const downloadMagazineSuccess = data => ({ type: actions.DOWNLOAD_MAGAZINE_SUCCESS, data });
// export const downloadMagazineFailure = error => ({ type: actions.DOWNLOAD_MAGAZINE_FAILURE, error });

// export const fetchRangeRequest = () => ({ type: actions.FETCH_RANGE_REQUEST });
// export const fetchRangeSuccess = data => ({ type: actions.FETCH_RANGE_SUCCESS, data });
// export const fetchRangeFailure = error => ({ type: actions.FETCH_RANGE_FAILURE, error });

// export const postCustomerSupportRequest = params => ({ type: actions.POST_CUSTOMER_SUPPORT_REQUEST, params });

// export const getPartnerRequest = params => ({ type: actions.GET_PARTNER_REQUEST, params });
// export const getPartnerSuccess = data => ({ type: actions.GET_PARTNER_SUCCESS, data });
// export const getPartnerFailure = error => ({ type: actions.GET_PARTNER_FAILURE, error });
