import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Pagination from 'react-js-pagination';
import { isEqual, intersection, uniqBy, get } from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import EventBus from 'eventing-bus';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactPaginate from 'react-paginate';

import { InputDate } from '../../../components';
import iconTrash from '../../../assets/images/Icon_trash.svg';
import mailWhite from '../../../assets/images/mail-white.svg';
import { FavoriteItem } from '../common';
import { getLanguage, getPathLanguage } from '../../../utils/params';
import previousIcon from '../../../assets/images/arrow-left.png';
import nextIcon from '../../../assets/images/arrow-right.png';
import { useTranslation } from 'react-i18next';
import favorite404 from '../../../assets/images/notfound-icon.svg';
import imageDefault from '../../../assets/images/loading-image.jpg';
import closeIcon from '../../../assets/images/Icon_x.svg';
import { history } from '../../../utils/history';
import { isMobile } from '../../../utils/constants';

const FavoritesComponent = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  const { handleSubmit, register, errors, reset } = useForm();
  const { t } = useTranslation();

  const [listIds, setListIds] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [initIds, setInitIds] = useState([]);
  const [isShowSubmit, setIsShowSubmit] = useState(false);
  const isClearAllFavourite = localStorage.getItem('IS_CLEAR_ALL_FAVOURITE') || false;

  const getListLocaleStorage = key => (localStorage.getItem(key) ? localStorage.getItem(key).split(',') : []);

  //   const localData = localStorage.getItem('CONTACT_FAVORITE') || '';
  const [choosedContactIds, setChoosedContactIds] = useState([]);
  const [choosedContactObj, setChoosedContactObj] = useState([]);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState({ key: '', value: false });

  const residencesLike = getListLocaleStorage('RESIDENCES_LIKE');
  const officesLike = getListLocaleStorage('OFFICES_LIKE');

  const [dataImage, setDataImage] = useState([]);
  const [date, setDate] = useState({ dateFrom: moment(), dateTo: moment() });

  useEffect(() => {
    localStorage.setItem('CONTACT_FAVORITE', []);
    const propertyId = [...residencesLike, ...officesLike] || [];
    setInitIds({ residencesLike, officesLike });
    let params = { language, pageIndex: pageIndex, pageSize: 9 };
    setListIds(propertyId);

    if (propertyId && propertyId.length) {
      params.propertyId = propertyId.toString();
      props.fetchFavoritesRequest(params);
    }
    window.scrollTo(0, 0);

    return () => {
      localStorage.removeItem('CONTACT_FAVORITE');
      localStorage.removeItem('IS_CLEAR_ALL_FAVOURITE');
    };
    // eslint-disable-next-line
  }, []);

  const handlePageChange = pageNumber => {
    pageNumber = parseInt(pageNumber.selected) + 1;
    const params = { language, propertyId: listIds.toString(), pageSize: 9, pageIndex: pageNumber };
    setPageIndex(pageNumber);

    props.fetchFavoritesRequest(params);
    window.scrollTo(0, 0);
  };

  const onChangeOneProperties = (id, action) => {
    let newIds = [];
    let keyLocal = '';
    if (action === 'remove') {
      if (residencesLike.includes(id)) {
        newIds = residencesLike.filter(i => i !== id);
        keyLocal = 'RESIDENCES_LIKE';
      } else if (officesLike.includes(id)) {
        newIds = officesLike.filter(i => i !== id);
        keyLocal = 'OFFICES_LIKE';
      }
    } else if (action === 'add') {
      if (initIds.residencesLike.includes(id)) {
        keyLocal = 'RESIDENCES_LIKE';
        newIds = [...residencesLike, id];
      } else if (initIds.officesLike.includes(id)) {
        keyLocal = 'OFFICES_LIKE';
        newIds = [...officesLike, id];
      }
    }

    localStorage.setItem(keyLocal, newIds);
  };

  const handleLikeProperties = id => {
    let ids = [];
    if (listIds.includes(id)) {
      ids = listIds.filter(i => i !== id);
      onChangeOneProperties(id, 'remove');
    } else {
      ids = [...listIds, id];
      onChangeOneProperties(id, 'add');
    }

    setListIds(ids);

    EventBus.publish('RESET_LIKE', true);
  };

  const handleClearAllLike = () => {
    localStorage.removeItem('RESIDENCES_LIKE');
    localStorage.removeItem('OFFICES_LIKE');
    localStorage.removeItem('CONTACT_FAVORITE');
    window.location.reload();
  };

  const chooseItemContact = data => {
    const getItem = localStorage.getItem('CONTACT_FAVORITE') || '';
    const localContact = getItem ? JSON.parse(getItem) : [];

    const localContactIds = localContact.map(item => item.id);

    let newLocalContact = [];

    if (localContactIds.includes(data.id)) {
      newLocalContact = localContact.filter(i => i.id !== data.id);
    } else {
      newLocalContact = [...localContact, data];
    }

    localStorage.setItem('CONTACT_FAVORITE', JSON.stringify(uniqBy(newLocalContact, 'id')));
    setChoosedContactObj(newLocalContact);
  };

  const { favorites } = props.favorites;

  useEffect(() => {
    if (favorites && favorites.items && favorites.items.length) {
      const favoriteIds = favorites.items.map(i => i.id);
      const listIds = choosedContactObj.map(i => i.id);

      setChoosedContactIds(listIds);
      setIsCheckAll(isEqual(intersection(listIds, favoriteIds).sort(), favoriteIds.sort()));
    } else {
      setIsCheckAll(false);
    }
  }, [choosedContactObj, favorites]);

  const handleCheckAll = () => {
    const getItem = localStorage.getItem('CONTACT_FAVORITE') || '';
    const localContact = getItem ? JSON.parse(getItem) : [];

    let arrayFavoriteIds = [];

    if (favorites && favorites.items && favorites.items.length) {
      arrayFavoriteIds = favorites.items.map(i => {
        if (i.images && i.images.length) return { id: i.id, image: i.images[0], propertyId: i.propertyId };
        return { id: i.id, image: null, propertyId: i.propertyId };
      });
    }

    const itemNotIncludes = arrayFavoriteIds.filter(arr => !localContact.map(i => i.id).includes(arr.id));

    if (itemNotIncludes.length) {
      setChoosedContactObj([...localContact, ...itemNotIncludes]);
      localStorage.setItem('CONTACT_FAVORITE', JSON.stringify(uniqBy([...localContact, ...itemNotIncludes]), 'id'));
    } else {
      const arrayRemoved = arrayFavoriteIds.filter(item => localContact.map(i => i.id).includes(item.id));
      const newArrayContact = localContact.filter(i => arrayRemoved.map(item => item.id).indexOf(i.id) < 0);
      setChoosedContactObj(newArrayContact);
      localStorage.setItem('CONTACT_FAVORITE', JSON.stringify(newArrayContact));
    }
  };

  useEffect(() => {
    if (isShowConfirm.key === 'modal' && isShowConfirm.value) {
      setDataImage(
        localStorage.getItem('CONTACT_FAVORITE') ? JSON.parse(localStorage.getItem('CONTACT_FAVORITE')) : [],
      );
    }
  }, [isShowConfirm]);

  useEffect(() => {
    if (!location.hash) {
      setIsShowConfirm({ key: '', value: false });
      EventBus.publish('checkBodyScroll', false);
    }
  }, [location, setIsShowConfirm]);

  const handleRemoveItem = id => setDataImage(dataImage.filter(i => i.id !== id));

  const onChangeDatePicker = (key, value) => {
    if (key === 'dateFrom') setDate({ ...date, ...{ dateFrom: value } });
    else if (key === 'dateTo') setDate({ ...date, ...{ dateTo: value } });
  };

  const onSuccess = () => {
    // toast.dismiss();
    // toast.success(t('about_isn.contact_us_page.message_success'));
    reset();
    history.push(`${pathLanguage}/notification/contact-us`);
  };

  const onError = err => {
    toast.dismiss();
    toast.error(err);
  };

  const onSubmit = values => {
    if (!values) return;
    const formSubmit = {};

    const cityId = get(props, 'layout.cityActive.defaultLocationId', null) || localStorage.getItem('LOCATION_CITY');

    if (dataImage.length) formSubmit.propertyId = dataImage.map(i => i.propertyId).toString();
    if (values.fullName) formSubmit.fullName = values.fullName;
    formSubmit.email = values.email;
    formSubmit.cityId = cityId;
    formSubmit.language = language;
    formSubmit.companyName = values.companyName;
    formSubmit.phone = values.phoneNumber;
    formSubmit.expectedCheckInDate = date.dateFrom;
    formSubmit.expectedTourInspectionDate = date.dateTo;
    if (values.otherRequirements) formSubmit.otherRequirements = values.otherRequirements;

    props.postContactFavoriteRequest({ formSubmit: { ...formSubmit }, onSuccess, onError });
    reset();
    setIsShowConfirm({ key: '', value: false });
    EventBus.publish('checkBodyScroll', false);
  };

  const { loading } = props.loading;

  const checkCaptcha = value => {
    if (value) setIsShowSubmit(true);
  };

  const wrapperRef = useRef(null);
  const wrapperRefConfirm = useRef(null);
  const handleClickOutsidePopup = event => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      wrapperRefConfirm.current &&
      !wrapperRefConfirm.current.contains(event.target)
    ) {
      reset();
      setIsShowConfirm({ key: '', value: false });
      EventBus.publish('checkBodyScroll', false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidePopup);

    return () => document.removeEventListener('mousedown', handleClickOutsidePopup);
    // eslint-disable-next-line
  }, [wrapperRef, wrapperRefConfirm]);

  return (
    <>
      <div className="section-block section-breadcrumb background-gray">
        <div className="container">
          <div className="row breadcrumb align-items-center">
            <div className="col-6">
              <div className="breadcrumb-item row">
                <ul>
                  <li className="first">
                    <Link className="home" to={pathLanguage}>
                      {t('favorite_page.home')}
                    </Link>
                  </li>
                  <li className="item">
                    <span className="breadcrumb-dot">&#8226;</span>
                    <span className="active"> {t('favorite_page.favorites')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {favorites && favorites.items && favorites.items.length ? (
            <div className="row justify-content-end remove-contact mt-3 checked-contact">
              <div className="col-12">
                <div className="col-12 wrap-text">
                  <div
                    className={`text-selected ${language === 'jp' ? 'text-selected-jp' : ''}`}
                    dangerouslySetInnerHTML={{ __html: t('favorite_page.please_check') || '' }}
                  ></div>
                </div>
                <div className="row row-wrap-button">
                  <div className="wrap-1">
                    <div className="form-item link-red">
                      <label>
                        <input type="checkbox" checked={isCheckAll} onChange={() => handleCheckAll()} />{' '}
                        <span>{t('favorite_page.select_all')}</span>
                      </label>
                    </div>
                    <div className="button-clear-all">
                      <button
                        onClick={() => {
                          if ([...residencesLike, ...officesLike].length)
                            setIsShowConfirm({ key: 'confirm', value: true });
                        }}
                        className="button button-white button-red  button-medium button-transparent"
                      >
                        <LazyLoadImage src={iconTrash} alt="" effect="blur" /> {t('favorite_page.clear_all')}
                      </button>
                    </div>
                  </div>
                  <div className="wrap-2">
                    <button
                      onClick={() => {
                        if (choosedContactIds.length) {
                          window.history.pushState({}, '', location.pathname + '#popup');
                          setIsShowConfirm({ key: 'modal', value: true });
                          EventBus.publish('checkBodyScroll', true);
                        }
                      }}
                      className="button uppercase button-medium button-red"
                    >
                      <LazyLoadImage src={mailWhite} alt="" effect="blur" /> {t('favorite_page.contact_us')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="section-block background-gray section-page-content">
        <div className="container">
          <div className="seach-results">
            <div className="row">
              {favorites && favorites.items && favorites.items.length ? (
                favorites.items.map((value, index) => (
                  <FavoriteItem
                    handleLikeProperties={handleLikeProperties}
                    chooseItemContact={chooseItemContact}
                    contactIds={choosedContactIds}
                    listIds={listIds}
                    favorite={value}
                    key={index}
                  />
                ))
              ) : !loading ? (
                isClearAllFavourite ? (
                  <div className="image-notfound notification-clear-all">
                    <div className="not-found-message"> {t('favorite_page.favourite_list_cleared')}</div>
                  </div>
                ) : (
                  <div className="image-notfound">
                    <div className="not-found-message"> {t('favorite_page.not_found')}</div>
                    <img src={favorite404} className="favourite-notfound" alt="" />
                    {/* <LazyLoadImage src={favorite404} className="favourite-notfound" alt="" effect="blur" /> */}
                  </div>
                )
              ) : (
                ''
              )}
            </div>
          </div>
          {favorites && favorites.totalRecords > 9 ? (
            <div className="pagination">
              <ReactPaginate
                // activePage={pageIndex}
                // itemsCountPerPage={9}
                // totalItemsCount={favorites && favorites.totalRecords ? favorites.totalRecords : 0}
                // pageRangeDisplayed={5}
                // hideFirstLastPages={true}
                // nextPageText={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                // prevPageText={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                // activeClass={'pagination-active'}
                // onChange={page => handlePageChange(page)}
                // itemClass={'pagination-pager-item'}
                forcePage={pageIndex - 1}
                pageCount={favorites.pageCount}
                pageRangeDisplayed={1}
                marginPagesDisplayed={isMobile ? 1 : 2}
                previousLabel={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                nextLabel={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                activeClassName={'pagination-active'}
                onPageChange={handlePageChange}
                pageClassName={'pagination-pager-item'}
                disabledClassName={'opacity-disable'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div
        className={`overlay ${
          (isShowConfirm.key === 'confirm' || isShowConfirm.key === 'modal') && isShowConfirm.value ? 'active' : ''
        }`}
      ></div>
      <div
        className={`modal fade show`}
        id="confirmModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="confirmModalLabel"
        aria-hidden="true"
        style={{
          display: isShowConfirm.key === 'confirm' && isShowConfirm.value ? 'block' : 'none',
        }}
        ref={wrapperRefConfirm}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title uppercase">
                {t('favorite_page.message.do_you_want_to_clear_all_favourites')}
              </h5>
              <button
                onClick={() => setIsShowConfirm({ key: '', value: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true"></span>
              </button>
            </div>
            <div className="modal-body">
              {/* <p>Are you sure you want to remove this property from favorite list?</p> */}
              <div className="row justify-content-end">
                <div className="col-6 col-md-3">
                  <button
                    onClick={() => {
                      handleClearAllLike();
                      localStorage.setItem('IS_CLEAR_ALL_FAVOURITE', true);
                    }}
                    type="button"
                    className="button-red uppercase"
                  >
                    {t('favorite_page.message.yes')}
                  </button>
                </div>
                <div className="col-6 col-md-3">
                  <button
                    onClick={() => setIsShowConfirm({ key: '', value: false })}
                    type="button"
                    className="button-red button-white uppercase"
                    data-dismiss="modal"
                  >
                    {t('favorite_page.message.no')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade show modal-contact-favorite"
        id="contactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contactModalLabel"
        aria-hidden="true"
        style={{
          display: isShowConfirm.key === 'modal' && isShowConfirm.value ? 'block' : 'none',
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content" ref={wrapperRef}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-12 col-md-6 favorites-selected">
                  <div className="row row-popup">
                    <div className="col-12">
                      <div className="title">{t('favorite_page.modal.form_title')}</div>
                      <div className={`description ${language === 'jp' ? 'description-jp' : ''}`}>
                        {t('favorite_page.modal.form_description')}
                      </div>
                    </div>
                  </div>
                  <div className="row row-image">
                    {dataImage
                      ? dataImage.map((item, index) => (
                          <div key={index} className="col-12 col-md-6 position-relative favourite-item-selected">
                            <span onClick={() => handleRemoveItem(item.id)} className="close-remove circle">
                              {/* <img src={closeIcon} alt=""></img> */}
                              <LazyLoadImage src={closeIcon} alt="" effect="blur" />
                            </span>
                            {/* <img
                              className="modal-contact-image w-100"
                              alt=""
                              src={
                                item.image ? `${process.env.REACT_APP_URL_CRM_FILE}/images/${item.image}` : imageDefault
                              }
                            /> */}
                            <LazyLoadImage
                              className="modal-contact-image w-100"
                              alt=""
                              src={
                                item.image ? `${process.env.REACT_APP_URL_CRM_FILE}/images/${item.image}` : imageDefault
                              }
                              effect="blur"
                            />
                          </div>
                        ))
                      : ''}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <form className="search-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="group-control">
                      <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                          <label>
                            {t('favorite_page.modal.fullname')} <span className="requirement">*</span>
                          </label>
                          <input
                            name="fullName"
                            ref={register({
                              required: t('residence_detail.contact_form.err_required'),
                            })}
                            className="form-control"
                            type="text"
                            placeholder=""
                          />
                          <small className="error">
                            {errors.fullName && errors.fullName.message ? errors.fullName.message : ''}
                          </small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                          <label>
                            {t('favorite_page.modal.email')} <span className="requirement">*</span>
                          </label>
                          <input
                            name="email"
                            ref={register({
                              required: t('residence_detail.contact_form.err_required'),
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t('residence_detail.contact_form.err_email'),
                              },
                            })}
                            className="form-control"
                            type="text"
                            placeholder=""
                          />
                          <small className="error">
                            {errors.email && errors.email.message ? errors.email.message : ''}
                          </small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                          <label>
                            {t('favorite_page.modal.phone')} <span className="requirement">*</span>
                          </label>
                          <input
                            name="phoneNumber"
                            ref={register({
                              required: t('residence_detail.contact_form.err_required'),
                              // pattern: {
                              //   value: /^[0-9]+$/,
                              //   message: t('residence_detail.contact_form.err_phone'),
                              // },
                            })}
                            className="form-control"
                            type="text"
                            placeholder=""
                          />
                          <small className="error">
                            {errors.phoneNumber && errors.phoneNumber.message ? errors.phoneNumber.message : ''}
                          </small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                          <label>{t('favorite_page.modal.company')}</label>
                          <input
                            name="companyName"
                            ref={register({})}
                            className="form-control"
                            type="text"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <div className="row">
                            <div className="col-12 col-md-6 mb-3">
                              <InputDate
                                class_name="form-control datetime modal-datetime"
                                formName="dateFrom"
                                label={t('favorite_page.modal.date_from')}
                                onChange={onChangeDatePicker}
                              />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                              <InputDate
                                class_name="form-control datetime modal-datetime"
                                formName="dateTo"
                                label={t('favorite_page.modal.date_to')}
                                onChange={onChangeDatePicker}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                          <label>
                            {t('favorite_page.modal.other_requirements')} ({t('favorite_page.modal.requirement_text')})
                          </label>
                          <textarea
                            name="otherRequirements"
                            ref={register({})}
                            className="form-control modal-text-aria"
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 pb-3">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                            onChange={checkCaptcha}
                            hl={`${language === 'en' ? 'en' : 'ja'}`}
                          />
                        </div>
                        {/* <div className="col-6"></div> */}
                        <div className="col-12 col-md-12 text-center button-handle">
                          <button
                            type="submit"
                            className="button button-red modal-send-button special-send"
                            disabled={!isShowSubmit}
                          >
                            {t('favorite_page.modal.send')}
                          </button>
                          <button
                            onClick={() => {
                              reset();
                              setIsShowConfirm({ key: '', value: false });
                              EventBus.publish('checkBodyScroll', false);
                            }}
                            type="button"
                            className="button button-red button-white uppercase modal-send-button"
                            data-dismiss="modal"
                          >
                            {t('favorite_page.modal.cancel')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavoritesComponent;
