import React, { useEffect, useRef } from 'react';
import BlockBlogComponent from '../../commons/block/BlockBlog.component';
import OwlCarousel from 'react-owl-carousel2';
import { ARROW_LEFT, ARROW_RIGHT } from '../../../utils/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../home.action';
import { getLanguage } from '../../../utils/params';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HomeBlog = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const owlBlog = useRef(null);
  const { listBlogsHot } = props.home;
  const { items } = listBlogsHot;
  const history = useHistory();
  const { t } = useTranslation();

  const options = {
    items: 1,
    nav: true,
    dots: false,
    autoplay: false,
    loop: false,
    navText: [ARROW_LEFT, ARROW_RIGHT],
    responsive: {},
  };

  const gotoAllBlogs = () => {
    history.push(`/${language}/blogs/all-blogs?pageIndex=1`);
  };
  useEffect(() => {
    const cityId = localStorage.getItem('LOCATION_CITY')
      ? localStorage.getItem('LOCATION_CITY')
      : localStorage.getItem('DEFAULT_CITY');
    if (cityId) {
      props.fetchBlogsHotRequest({ language, cityId, pageSize: '3', pageIndex: '1' });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="section-block blogs">
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 service-apartment">
              <h3 className={`service-title ${language === 'jp' ? 'font-noto-serif-cjk-jp' : ''}`}>{t('home_page.label_our_blog')}</h3>
              <div style={{ width: '170px' }} className="under-line mt-3 mb-5 mx-auto">
                <span className="middle-line"></span>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            {items && items.length ? (
              <div className="row">
                {items.map((blog, i) => (
                  <div key={i} className="p-3 col-xl-4 col-md-6 col-sm-12 col-12">
                    <OwlCarousel
                      className="nav-top owl-carousel owl-theme owl-loaded owl-drag"
                      ref={owlBlog}
                      options={options}>
                      <div className="col-item">
                        <BlockBlogComponent post={blog} />
                      </div>
                    </OwlCarousel>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="col-12 mt-5 text-center">
            <button onClick={() => gotoAllBlogs()} className="btn-showmore">
              {t('home_page.btn_showmore')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBlogsHotRequest: actions.fetchBlogsHotRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeBlog);
