import * as types from './forgotPassword.types';

const initialState = {
  isExist: null,
  message: null,
  accessToken: null,
  refreshToken: null,
};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_RESET_PASSWORD_REQUESTED:
      return { ...state };
    case types.FETCH_RESET_PASSWORD_SUCCEEDED: {
      return {
        ...state,
        isExist: action.data ? action.data.isExist : null,
        message: action.data ? action.data.message : null,
      };
    }
    case types.FETCH_RESET_PASSWORD_FAILED:
      return { ...state, isExist: null, message: null };

    case types.FETCH_VERIFY_KEY_FORGOT_REQUESTED:
      return { ...state };
    case types.FETCH_VERIFY_KEY_FORGOT_SUCCEEDED: {
      return { ...state };
    }
    case types.FETCH_VERIFY_KEY_FORGOT_FAILED:
      return { ...state, error: action.error };

    case types.FETCH_NEW_PASSWORD_REQUESTED:
      return { ...state };
    case types.FETCH_NEW_PASSWORD_SUCCEEDED: {
      return {
        ...state,
        accessToken: action.data ? action.data.access_token : null,
        refreshToken: action.data ? action.data.refresh_token : null,
      };
    }
    case types.FETCH_NEW_PASSWORD_FAILED:
      return { ...state, accessToken: null, refreshToken: null };

    default:
      return state;
  }
};
export default forgotPasswordReducer;
