import React, { useEffect, useState } from 'react';
import { BannerApartment, BreadcumbApartment, MenuApartment } from '../common';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import * as actions from '../detailApartment.action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useParams } from 'react-router-dom';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import option from '../../../assets/images/icon/mappin.svg';
import walking from '../../../assets/images/icon/walking.svg';
import { useTranslation } from 'react-i18next';
import { getQueryString } from '../../../utils/params';
import { getLocationRequest } from '../detailApartment.api';
import { HideLeft, HideRight, Address, PhoneMap } from '../../../assets/images/web-isn';
import { PinpointDetailProject } from '../../../assets/images/icon'

const getLanguage = localStorage.getItem('language');
let setLanguage = '';
if (getLanguage === 'jp') {
  setLanguage = 'ja';
} else if (getLanguage === 'en') {
  setLanguage = 'en';
} else if (getLanguage === 'vi') {
  setLanguage = 'vi';
}

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_KEY_GOOGLE_MAP}&language=${setLanguage}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => {
  return (
    <GoogleMap defaultZoom={14} defaultCenter={{ lat: props.lat, lng: props.lng }}>
      {props.listMap.length > 0 ?
        props.listMap.map((ele, idx) => {
          return (
            <Marker
              key={idx}
              icon={{ url: ele.id ? PinpointDetailProject : (process.env.REACT_APP_ISN_IMAGE_URL + ele.iconMap), scaledSize: ele.id ? new window.google.maps.Size(25, 30) : new window.google.maps.Size(24, 24) }}
              position={{ lat: parseFloat(ele.latitude), lng: parseFloat(ele.longitude) }}
              onClick={() => { props.onMarkerClick(ele) }}>
              {props.parentId === ele.type_id && props.childId === ele.child_id ?
                <InfoWindow>
                  <div className="container-letter">
                    <div className="letter-img">
                      <img src={`${process.env.REACT_APP_ISN_IMAGE_URL}${ele.imageUrl}`} alt="" />
                    </div>
                    <div className={`letter-content ${ele.content ? 'd-block' : 'd-flex'}`}>
                      <span className="letter-content__title">{ele.name}</span>
                      {ele.content ?
                        <div>
                          <img className="letter-content-img" src={`${process.env.REACT_APP_ISN_IMAGE_URL}${ele.iconUrl}`} alt="" />
                          <span>{ele.content}</span>
                        </div>
                        : ''}
                    </div>
                  </div>
                </InfoWindow>
                : ''}
            </Marker>
          )
        })
        : ''}
    </GoogleMap>
  );
});

const LocationComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { language } = useParams();
  const [locationInfo, setLocationInfo] = useState(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [checkUpPlay, setUpPlay] = useState(false);
  const [newLocation, setNewLocation] = useState(true);
  const [dataDetail, setDataDetail] = useState(null);
  const [idShow, setIdShow] = useState(null);
  const [dataMap, setDataMap] = useState([]);
  const [hideLeft, setHideLeft] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [childId, setChildId] = useState(0);

  useEffect(() => {
    showLoader();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    getLocationRequest(getQueryString({ language, commonId: id })).then(res => {
      if (res && res.latitude) {
        dataDefault(res)
      }
    });
    //eslint-disable-next-line
  }, []);

  const dataDefault = (res) => {
    const listMap = []
    const info = {
      id: null,
      name: '',
      imageUrl: '',
      latitude: 0,
      longitude: 0,
      type_id: 0,
      child_id: 0,
    }
    info.id = res.id
    info.name = res.name
    info.imageUrl = res.imageUrl
    info.latitude = res.latitude
    info.longitude = res.longitude
    listMap.push(info)
    if (res.categoriesLocations.length > 0) {
      res.categoriesLocations.map((ele, index) => {
        index === 0 ? ele.checkShow = true : ele.checkShow = false;
        if (ele.items.length > 0) {
          ele.items.map(e => {
            const objectMap = {
              iconMap: '',
              name: '',
              imageUrl: '',
              latitude: 0,
              longitude: 0,
              content: '',
              iconUrl: '',
              type_id: '',
              child_id: '',
              address: '',
              phoneNumber: ''
            }
            objectMap.type_id = ele.id
            objectMap.iconMap = ele.imageUrl
            objectMap.child_id = e.id
            objectMap.latitude = e.latitude
            objectMap.longitude = e.longitude
            objectMap.name = e.name
            objectMap.iconUrl = e.iconUrl
            objectMap.content = e.content
            objectMap.imageUrl = e.imageUrl
            objectMap.address = e.address
            objectMap.phoneNumber = e.phoneNumber
            listMap.push(objectMap)
            return listMap;
          });
        }
        return listMap;
      });
    }
    setDataMap(listMap)
    setLocationInfo(res);
  }

  const handleShow = id => {
    if (locationInfo && locationInfo.categoriesLocations.length > 0) {
      const data = locationInfo?.categoriesLocations.filter(e => {
        if (e.id === id) {
          e.checkShow = !e.checkShow;
        }
      });
      checkUpPlay === true ? setUpPlay(false) : setUpPlay(true);
      return data;
    }
  };

  const showNewLocation = (child, data) => {
    if (childId && parentId && dataMap.length > 0) {
      const dataDT = dataMap.filter(e => e.child_id === child.id && e.type_id === data.id);
      setDataDetail(dataDT[0])
    } else {
      setDataDetail(child)
    }
    setNewLocation(false)
    setIdShow(data.id)
    setChildId(child.id)
    setParentId(data.id)
  }

  const backNewLocation = () => {
    setChildId(0)
    setParentId(0)
    setNewLocation(true);
    if (locationInfo && locationInfo.categoriesLocations.length > 0) {
      const data = locationInfo?.categoriesLocations.filter(e => {
        if (e.id === idShow) {
          e.checkShow = true;
        }
        const info = {
          id: null,
          name: '',
          imageUrl: '',
          latitude: 0,
          longitude: 0,
        };
        info.id = locationInfo.id;
        info.name = locationInfo.name;
        info.imageUrl = locationInfo.imageUrl;
        info.latitude = locationInfo.latitude;
        info.longitude = locationInfo.longitude;
      });
      return data;
    }
  };


  const showCard = () => {
    setHideLeft(!hideLeft);
  };

  const handleMarkerClick = (...e) => {
    if (e && e[0]) {
      if (e[0].id) {
        setNewLocation(true)
      } else {
        setIdShow(e[0].type_id)
        setDataDetail(e[0])
        setNewLocation(false)
      }
      setChildId(e[0].child_id)
      setParentId(e[0].type_id)
    }
  };


  const handleLocation = () => {
    hideLoader();
    if (locationInfo) {
      return (
        <>
          <div className="contaiter-map">
            <div className="apartment-location width-location">
              <div className="apartment-location__map">
                {locationInfo.latitude ? (
                  <MyMapComponent
                  key="map"
                  onMarkerClick={handleMarkerClick}
                  childId={childId}
                  parentId={parentId}
                  listMap={dataMap}
                  lat={locationInfo.latitude}
                  lng={locationInfo.longitude}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="d-flex justify-content-center">
                <Link className="btn-booknow-a" to={`/${language}/preparation-page`}></Link>
              </div>
            </div>

            {hideLeft === false ? (
              <div onClick={() => showCard()} className="hide_right">
                <img src={HideLeft} alt="" />
              </div>
            ) : (
              ''
            )}
            {hideLeft === true ? (
              <div onClick={() => showCard()} className="hide_left">
                <img src={HideRight} alt="" />
              </div>
            ) : (
              ''
            )}

            {locationInfo?.latitude ? (
              <div className="card card-location d-none d-md-block">
                <div className="card-body-location card-body">
                  {newLocation ? (
                    <>
                      <div className="card-title-location">{t('location.discover_title')}</div>
                      <div className="card-border"></div>
                      <div className="container-card-panel">
                        <div className="card-panel">
                          <img className="img-option" src={option} alt="" />
                          <span>{t('location.current_selection')}</span>
                        </div>
                        <div className="content-card">
                          <img
                            className="img-photo"
                            src={`${process.env.REACT_APP_ISN_IMAGE_URL}${locationInfo?.imageUrl}`}
                            alt=""
                          />
                          <span className="content-card-child">{locationInfo?.name}</span>
                        </div>
                        <div className="content-address">
                          {locationInfo?.address ? (
                            <div className="address">
                              <img className="img-walking " src={Address} alt="address" />
                              <span>{locationInfo?.address}</span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      {locationInfo && locationInfo?.categoriesLocations.length > 0
                        ? locationInfo?.categoriesLocations?.map((data, idx) => (
                            <div key={idx} onClick={() => handleShow(data.id)} className="container-card-panel">
                              <div className="card-panel cursor-pointer">
                                <img
                                  className="img-option"
                                  src={`${process.env.REACT_APP_ISN_IMAGE_URL}${data.imageUrl}`}
                                  alt=""
                                />
                                <span>{data.name}</span>
                                <div
                                  className={`arrow ${data.checkShow === true ? 'up-location' : 'down-location'} `}
                                ></div>
                              </div>
                              {data.checkShow === true
                                ? data.items.map((e, index) => (
                                    <div
                                      key={index}
                                      onClick={() => showNewLocation(e, data)}
                                      className="content-card cursor-pointer"
                                    >
                                      <img
                                        className="img-photo"
                                        src={`${process.env.REACT_APP_ISN_IMAGE_URL}${e.imageUrl}`}
                                        alt=""
                                      />
                                      <div className="distance">
                                        <div>{e.name}</div>
                                        <div>
                                          <img
                                            className="img-walking"
                                            src={`${process.env.REACT_APP_ISN_IMAGE_URL}${e.iconUrl}`}
                                            alt=""
                                          />
                                          <span>{e.content}</span>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : ''}
                            </div>
                          ))
                        : ''}
                    </>
                  ) : (
                    <>
                      <div onClick={() => backNewLocation()} className="new-title-location">
                        <div className="arrow-left"></div>
                        <div>{t('location.back_to_nearby')}</div>
                      </div>
                      <div className="img-new">
                        <img
                          className="img-new-location"
                          src={`${process.env.REACT_APP_ISN_IMAGE_URL}${dataDetail.imageUrl}`}
                          alt=""
                        />
                      </div>
                      <div className="new-content">
                        <img className="img-balloon" src={walking} alt="" />
                        <span>{dataDetail.name}</span>
                      </div>
                      <div className="new-content">
                        <img
                          className="img-walking"
                          src={`${process.env.REACT_APP_ISN_IMAGE_URL}${dataDetail.iconUrl}`}
                          alt=""
                        />
                        <span>{dataDetail.content}</span>
                      </div>
                      {dataDetail.address ? (
                        <div className="new-content">
                          <img className="img-walking " src={Address} alt="address" />
                          <span>{dataDetail.address}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {dataDetail.phoneNumber ? (
                        <div className="new-content">
                          <img className="img-walking" src={PhoneMap} alt="phone_map" />
                          <span>{dataDetail.phoneNumber}</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}

            {locationInfo?.latitude && hideLeft ? (
              <div className="card card-location-mobile">
                <div className="card-body-location card-body">
                  {newLocation ? (
                    <>
                      <div className="card-title-location">{t('location.discover_title')}</div>
                      <div className="card-border"></div>
                      <div className="container-card-panel">
                        <div className="card-panel">
                          <img className="img-option" src={option} alt="" />
                          <span>{t('location.current_selection')}</span>
                        </div>
                        <div className="content-card">
                          <img
                            className="img-photo"
                            src={`${process.env.REACT_APP_ISN_IMAGE_URL}${locationInfo?.imageUrl}`}
                            alt=""
                          />
                          <span className="content-card-child">{locationInfo?.name}</span>
                        </div>
                      </div>
                      {locationInfo && locationInfo?.categoriesLocations.length > 0
                        ? locationInfo?.categoriesLocations?.map((data, idx) => (
                            <div key={idx} onClick={() => handleShow(data.id)} className="container-card-panel">
                              <div className="card-panel cursor-pointer">
                                <img
                                  className="img-option"
                                  src={`${process.env.REACT_APP_ISN_IMAGE_URL}${data.imageUrl}`}
                                  alt=""
                                />
                                <span>{data.name}</span>
                                <div
                                  className={`arrow ${data.checkShow === true ? 'up-location' : 'down-location'} `}
                                ></div>
                              </div>
                              {data.checkShow === true
                                ? data.items.map((e, index) => (
                                    <div
                                      key={index}
                                      onClick={() => showNewLocation(e, data)}
                                      className="content-card cursor-pointer"
                                    >
                                      <img
                                        className="img-photo"
                                        src={`${process.env.REACT_APP_ISN_IMAGE_URL}${e.imageUrl}`}
                                        alt=""
                                      />
                                      <div className="distance">
                                        <div>{e.name}</div>
                                        <div>
                                          <img
                                            className="img-walking"
                                            src={`${process.env.REACT_APP_ISN_IMAGE_URL}${e.iconUrl}`}
                                            alt=""
                                          />
                                          <span>{e.content}</span>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : ''}
                            </div>
                          ))
                        : ''}
                    </>
                  ) : (
                    <>
                      <div onClick={() => backNewLocation()} className="new-title-location">
                        <div className="arrow-left"></div>
                        <div>{t('location.back_to_nearby')}</div>
                      </div>
                      <div className="img-new">
                        <img
                          className="img-new-location"
                          src={`${process.env.REACT_APP_ISN_IMAGE_URL}${dataDetail.imageUrl}`}
                          alt=""
                        />
                      </div>
                      <div className="new-content">
                        <img className="img-balloon" src={walking} alt="" />
                        <span>{dataDetail.name}</span>
                      </div>
                      <div className="new-content">
                        <img
                          className="img-walking"
                          src={`${process.env.REACT_APP_ISN_IMAGE_URL}${dataDetail.iconUrl}`}
                          alt=""
                        />
                        <span>{dataDetail.content}</span>
                      </div>
                      {dataDetail.address ? (
                        <div className="new-content">
                          <img className="img-walking " src={Address} alt="address" />
                          <span>{dataDetail.address}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {dataDetail.phoneNumber ? (
                        <div className="new-content">
                          <img className="img-walking" src={PhoneMap} alt="phone_map" />
                          <span>{dataDetail.phoneNumber}</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <BreadcumbApartment />
      <BannerApartment />
      <MenuApartment />
      <div id="detailApartmentLocation">
        <div className="container padding-0">{handleLocation()}</div>
      </div>
      {loader}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLocationRequest: actions.getLocationRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LocationComponent);
