const FETCH_FEATURED_PROJECTS_REQUEST = 'FETCH_FEATURED_PROJECTS_REQUEST';
const FETCH_FEATURED_PROJECTS_SUCCESS = 'FETCH_FEATURED_PROJECTS_SUCCESS';
const FETCH_FEATURED_PROJECTS_FAILURE = 'FETCH_FEATURED_PROJECTS_FAILURE';

const FETCH_ESSENTIALS_REQUEST = 'FETCH_ESSENTIALS_REQUEST';
const FETCH_ESSENTIALS_SUCCESS = 'FETCH_ESSENTIALS_SUCCESS';
const FETCH_ESSENTIALS_FAILURE = 'FETCH_ESSENTIALS_FAILURE';

const FETCH_ABOUT_US_REQUEST = 'FETCH_ABOUT_US_REQUEST';
const FETCH_ABOUT_US_SUCCESS = 'FETCH_ABOUT_US_SUCCESS';
const FETCH_ABOUT_US_FAILURE = 'FETCH_ABOUT_US_FAILURE';

const FETCH_BLOGS_HOT_REQUEST = 'FETCH_BLOGS_HOT_REQUEST';
const FETCH_BLOGS_HOT_SUCCESS = 'FETCH_BLOGS_HOT_SUCCESS';
const FETCH_BLOGS_HOT_FAILURE = 'FETCH_BLOGS_HOT_FAILURE';

const FETCH_SERVICED_REQUEST = 'FETCH_SERVICED_REQUEST';
const FETCH_SERVICED_SUCCESS = 'FETCH_SERVICED_SUCCESS';
const FETCH_SERVICED_FAILURE = 'FETCH_SERVICED_FAILURE';

export {
  FETCH_FEATURED_PROJECTS_REQUEST,
  FETCH_FEATURED_PROJECTS_SUCCESS,
  FETCH_FEATURED_PROJECTS_FAILURE,
  FETCH_ESSENTIALS_REQUEST,
  FETCH_ESSENTIALS_SUCCESS,
  FETCH_ESSENTIALS_FAILURE,
  FETCH_ABOUT_US_REQUEST,
  FETCH_ABOUT_US_SUCCESS,
  FETCH_ABOUT_US_FAILURE,
  FETCH_BLOGS_HOT_REQUEST,
  FETCH_BLOGS_HOT_SUCCESS,
  FETCH_BLOGS_HOT_FAILURE,
  FETCH_SERVICED_REQUEST,
  FETCH_SERVICED_SUCCESS,
  FETCH_SERVICED_FAILURE,
};
