import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Rating from "react-rating";
import StartYellow from "assets/images/icon/star-yellow.svg"
import StartDefault from "assets/images/icon/star-default.svg"
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Upload from 'assets/images/Upload.svg';
import CloseImage from 'assets/images/Icon_x.svg';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { useLocation } from 'react-router-dom';
import { checkTokenReview, getTagsReview, createReview } from '../detailApartment.api';
import { getLanguage, getQueryString } from '../../../utils/params';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { useHistory } from 'react-router-dom';

function UserReviewComponent() {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const language = getLanguage(useLocation());
  const [rating, setRating] = useState(5);
  const [files, setFiles] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [valueTagsRating, setValueTagsRating] = useState();
  const [tagsReview, setTagsReview] = useState();
  const [projectName, setProjectName] = useState();
  const [content, setContent] = useState('');
  // const content = '';
  const verifyKey = encodeURIComponent(searchParams.get('verify-key'));

  useEffect(() => {
    showLoader();
    Promise.all([checkTokenReview({ language, key: verifyKey }), getTagsReview(getQueryString({ language }))]).then((values) => {
      if (!values[0].isVerify) {
        history.push(`/${language}/send-review/result`);
      } else {
        setProjectName(values[0].apartmentName)
        setTagsReview(values[1]);
      }
      hideLoader();
    });
    // eslint-disable-next-line
  }, [verifyKey]);

  const handlerSetRating = rate => {
    setRating(rate)
  }

  const removeImage = index => {
    let filesCopy = [...files];
    filesCopy.splice(index, 1);

    setFiles(filesCopy);

    const images = filesCopy.map(item => URL.createObjectURL(item));
    setFilePreview(images);
  };

  const handleChangeTags = (val) => {
    setValueTagsRating(val)
  };

  const handleChangeContent = e => {
    let { value } = e.target;
    setContent(value);
  }

  const submit = () => {
    showLoader();
    const formData = new FormData();
    formData.append('verifyKey', verifyKey);
    formData.append('language', language);
    formData.append('rate', rating);
    if (valueTagsRating) formData.append('tagId', valueTagsRating);
    formData.append('content', content);
    // if (values.leasableArea) formData.append('leasableArea', values.leasableArea);
    files.forEach(file => {
      formData.append('images', file, file.name);
    });
    createReview(formData).then(res => {
      if (res.status === "success") {
        // onSuccess();
        const ratingApartment = {
          rating: rating,
          nameApartment: projectName
        }
        localStorage.setItem("ratingApartment", JSON.stringify(ratingApartment));
        history.push(`/${language}/send-review/result`);
      hideLoader();

      } else {
        // onError()
        hideLoader();
      }
    });
  }

  return (
    <>{tagsReview ? (
      <div className="user-rating-review my-5">
        <div className="container">
          <div className="page-for-owners">
            <div className="row">
              <div className="col-12">
                <form className="rating-form box-white">

                  <div className="row">

                    <div className="col-12">
                      <h3 className="rating-title text-center">{t('detailApartment.title_rating')
                        + projectName}</h3>
                    </div>
                    <div className="col-12 rating">
                      <h3 className="rating-number ml-2 my-3 text-center">{rating + '.0'}</h3>
                      <div className="width-fit-content mx-auto">
                        <Rating
                          className="width-fit-content"
                          stop={5}
                          emptySymbol={<img src={StartDefault} className="icon" alt="icon-rating-default" />}
                          fullSymbol={<img src={StartYellow} className="icon" alt="icon-rating-yellow" />}
                          initialRating={rating}
                          onClick={rate => handlerSetRating(rate)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row group">
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>{t('for_owners.owner_register.upload_image')}</label>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            let checkErr = false;
                            let selectedFiles = [];

                            acceptedFiles.forEach(file => {
                              if (file.type.includes('image') && file.size < 2.5e6) {
                                selectedFiles.push(file);
                              } else {
                                checkErr = true;
                              }
                            });

                            if (checkErr) toast.error(t('owner_register.file_image_maximum'));

                            let arrayFiles = [];

                            if (selectedFiles.length + files.length <= 5) arrayFiles = [...files, ...selectedFiles];
                            else arrayFiles = [...files, ...selectedFiles].slice(0, 5);

                            setFiles(arrayFiles);

                            const images = arrayFiles.map(item => URL.createObjectURL(item));
                            setFilePreview(images);
                          }}
                          accept='image/jpeg, image/png, image/jpg, image/gif, image/tiff, image/bmp'
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="drag-drop-files" {...getRootProps()}>
                              <div className="image-upload">
                                <label htmlFor="file-input">
                                  <img src={Upload} alt="" />
                                  {/* <LazyLoadImage src={Upload} alt="" effect="blur" /> */}
                                </label>
                                <input {...getInputProps()} />
                              </div>
                              <label>{t('for_owners.owner_register.click_here')}</label>
                              <small>
                                {t('for_owners.owner_register.supported_image')}
                              </small>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {filePreview.length ? (
                        <div className="preview-image">
                          <div className="row">
                            <div className="col-12 col-md-12 image-all">
                              {filePreview.length
                                ? filePreview.map((item, index) => (
                                  <div key={index} className="image-selected">
                                    <img src={CloseImage} alt="" className="icon-close" onClick={() => removeImage(index)} />

                                    <img src={item} alt="" className="image" />

                                    {/* <LazyLoadImage
                            src={CloseImage}
                            alt=""
                            className="icon-close"
                            onClick={() => removeImage(index)}
                            effect="blur"
                          />
                          <LazyLoadImage src={item} alt="" className="image" effect="blur" /> */}
                                  </div>
                                ))
                                : ''}
                            </div>
                          </div>
                        </div>
                      ) : ''}
                    </div>
                  </div>
                  <div className="group">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control w-100 area-owner"
                            name="descriptionFacilities"
                            value={content}
                            onChange={handleChangeContent}
                            placeholder={t('')}
                            maxLength={1500}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <ToggleButtonGroup type="checkbox" value={valueTagsRating} onChange={handleChangeTags}>
                          {tagsReview && tagsReview.length > 0 ?
                            tagsReview.map((item, index) => {
                              return (
                                <ToggleButton key={index} className="mr-3 border-radius-none" variant="outline-secondary" id={'tbg-btn-' + item.id} value={item.id}>
                                  {/* {t('detailApartment.very_luxrious')} */}
                                  {item.title}
                                </ToggleButton>
                              );
                            })

                            : ''}
                          {/* <ToggleButton className="mr-3 border-radius-none" variant="outline-secondary" id="tbg-btn-1" value={1}>
                            {t('detailApartment.very_luxrious')}
                          </ToggleButton>
                          <ToggleButton className="mr-3 border-radius-none" variant="outline-secondary" id="tbg-btn-2" value={2}>
                            {t('detailApartment.very_comfortable')}
                          </ToggleButton>
                          <ToggleButton className="mr-3 border-radius-none" variant="outline-secondary" id="tbg-btn-3" value={3}>
                            {t('detailApartment.very_convenient')}
                          </ToggleButton>
                          <ToggleButton className="mr-3 border-radius-none" variant="outline-secondary" id="tbg-btn-4" value={4}>
                            {t('detailApartment.good_security')}
                          </ToggleButton> */}
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-5 mb-3">
                    <button type="button" onClick={submit} className="button-red btn-booknow-a">{t('detailApartment.submit')}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : ''}
      {loader}
    </>
  );
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserReviewComponent);
