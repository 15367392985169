// eslint-disable-next-line no-unused-vars
import { all } from 'redux-saga/effects';

import { layoutSaga } from '../layouts';
import { homeSaga } from '../modules/homePage';
import { forOwnersSaga } from '../modules/forOwnersPage';
import { favoritesSaga } from '../modules/favoriteHistoryPage';
import { specialSaga } from '../modules/specialPage';
import { forgotPasswordSaga } from '../modules/forgotPassword';
import { signUpSaga } from '../modules/signUp';
import { loginSaga } from '../modules/loginPage';
import { detailApartmentSaga } from '../modules/detailApartmentPage';
import { accountPageSaga } from 'modules/accountPage/accountPage.saga';

export default function* rootSaga() {
  yield all([
    homeSaga(),
    layoutSaga(),
    forOwnersSaga(),
    favoritesSaga(),
    specialSaga(),
    loginSaga(),
    forgotPasswordSaga(),
    signUpSaga(),
    detailApartmentSaga(),
    accountPageSaga(),
  ]);
}
