import { put, call, takeEvery } from 'redux-saga/effects';
import * as types from './forgotPassword.types';
import * as actions from './forgotPassword.action';
import * as API from './forgotPassword.api';
import { setLoading } from '../../layouts/loading/loading.action';

export function* fetchResetPasswordRequested(action) {
  const { info, handleIsExist, handleTokenFailed } = action.params;
  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchResetPasswordRequested, info);
    yield put(actions.fetchResetPasswordSucceeded(data));
    handleIsExist(data.data.isExist);
  } catch (error) {
    yield put(actions.fetchResetPasswordFailed(error));
    handleTokenFailed(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchVerifyKeyForgotRequested(action) {
  const { key, verifySuccess, verifyError } = action.params;
  try {
    yield put(setLoading(true));
    const verifyKey = yield call(API.fetchVerifyKeyForgotRequested, key);
    yield put(actions.fetchVerifyKeyForgotSucceeded(verifyKey));
    verifySuccess(verifyKey);
  } catch (error) {
    yield put(actions.fetchVerifyKeyForgotFailed(error));
    verifyError(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchNewPasswordRequested(action) {
  const { info, handleToken, handleError } = action.params;
  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchNewPasswordRequested, info);
    yield put(actions.fetchNewPasswordSucceeded(data));
    handleToken(data.data.token, data.data.userInfo);
  } catch (error) {
    yield put(actions.fetchNewPasswordFailed(error));
    handleError(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* forgotPasswordSaga() {
  yield takeEvery(types.FETCH_RESET_PASSWORD_REQUESTED, fetchResetPasswordRequested);
  yield takeEvery(types.FETCH_VERIFY_KEY_FORGOT_REQUESTED, fetchVerifyKeyForgotRequested);
  yield takeEvery(types.FETCH_NEW_PASSWORD_REQUESTED, fetchNewPasswordRequested);
}
