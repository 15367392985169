import * as types from './signUp.types';

export const fetchVerifyEmailRequested = params => ({
  type: types.FETCH_VERIFY_EMAIL_REQUESTED,
  params
});
export const fetchVerifyEmailSucceeded = data => ({
  type: types.FETCH_VERIFY_EMAIL_SUCCEEDED,
  data
});
export const fetchVerifyEmailFailed = error => ({
  type: types.FETCH_VERIFY_EMAIL_FAILED,
  error
});


export const fetchVerifyKeyEmailRequested = params => ({
  type: types.FETCH_VERIFY_KEY_EMAIL_REQUESTED,
  params
});
export const fetchVerifyKeyEmailSucceeded = data => ({
  type: types.FETCH_VERIFY_KEY_EMAIL_SUCCEEDED,
  data
});
export const fetchVerifyKeyEmailFailed = error => ({
  type: types.FETCH_VERIFY_KEY_EMAIL_FAILED,
  error
});

export const fetchCompleteSignUpRequested = params => ({
  type: types.FETCH_COMPLETE_SIGNUP_REQUESTED,
  params
});
export const fetchCompleteSignUpSucceeded = data => ({
  type: types.FETCH_COMPLETE_SIGNUP_SUCCEEDED,
  data
});
export const fetchCompleteSignUpFailed = error => ({
  type: types.FETCH_COMPLETE_SIGNUP_FAILED,
  error
});

export const fetchLoginByGoogleRequested = params => ({
  type: types.FETCH_LOGIN_BY_GOOGLE_REQUESTED,
  params
});
export const fetchLoginByGoogleSucceeded = data => ({
  type: types.FETCH_LOGIN_BY_GOOGLE_SUCCEEDED,
  data
});
export const fetchLoginByGoogleFailed = error => ({
  type: types.FETCH_LOGIN_BY_GOOGLE_FAILED,
  error
});
