import React, { useRef, useEffect } from 'react';
import HeaderTop from './HeaderTop';
import Navigator from '../navigator/Navigator';
import Supports from '../supports/Supports';

const Header = () => {
  const prevScrollY = useRef(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    prevScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [prevScrollY]);

  return (
    <>
      <div className="fixed-frame-header"></div>
      <header className="section-header header sticky">
        <HeaderTop />
        <Navigator />
      </header>
      <Supports />
    </>
  );
};

export default Header;
