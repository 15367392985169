import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EmailURLComponent } from '../pages';
import { fetchVerifyKeyForgotRequested, fetchNewPasswordRequested } from '../forgotPassword.action';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchVerifyKeyForgotRequested, fetchNewPasswordRequested }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmailURLComponent);
