import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getLanguage, getQueryString } from '../../../../utils/params';
import { ResetIcon, SearchIcon, CloseIcon } from '../../icons';
import { Input, Select, Checkbox } from '../../../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { SearchLub, Buildings, DoubleBed, Calendar } from '../../../../assets/images/web-isn';
import Children from '../../../../assets/images/web-isn/children.svg';
import Users from '../../../../assets/images/web-isn/users.svg';
import { getTotalUnitServicedApartment } from '../../../../modules/searchResultPage/searchResult.api';
import debounce from 'lodash.debounce';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { addDays, addMonths } from 'date-fns';

const SearchOffices = props => {
  const today = new Date();
  // moment(new Date(today.toString()).format('YYYY-MM-DD') + 'T12:00:00Z')
  // console.log(moment(addDays(new Date(today), 3)).format('YYYY-MM-DD') + 'T12:00:00Z');
  const [dateRange, setDateRange] = useState([today, addDays(today, 3)]);
  const [startDate, endDate] = dateRange;
  const { t } = useTranslation();
  const history = useHistory();
  const language = getLanguage(useLocation());
  const locationPath = useLocation();
  const location = localStorage.getItem('LOCATION_CITY') ?? localStorage.getItem('DEFAULT_CITY');
  const [city, setCity] = useState(location);
  const [isShowMore, setIsShowMore] = useState({ key: '', value: false });
  const [adultNum, setAdultNum] = useState(0);
  const [childrenNum, setChildrenNum] = useState(0);
  const [roomNum, setRoomNum] = useState(0);
  const { register, errors, watch } = useForm();
  const { listRangeSetting } = props.layout;
  const currency = props.layout.currentCurrency;
  const { officePriceMax, officePriceMin, officePriceMaxVi, officePriceMinVi } = listRangeSetting || {};
  const [units] = useState([0, 5000]);
  const [unitPrice, setRangeSliderUnitPirce] = useState([0, 100]);
  const [showMoreConditions, setShowMoreConditions] = useState(false);
  const searchParams = new URLSearchParams(useLocation().search);
  const [formData, setFormData] = useState({
    districts: [],
    bedrooms: [],
    projects: [],
    sizes: [],
    types: [],
    facilities: {
      buildingFacilities: [],
      interiorFacilities: [],
    },
    views: [],
  });

  const [displayOption, setDisplayOption] = useState({
    districts: t('home_page.search.district'),
    bedrooms: t('home_page.search.bedroom'),
    city: t('home_page.search.city'),
    projects: t('home_page.search.building_name'),
    sizes: t('home_page.search.size_range'),
    facilities: t('home_page.search.facilities'),
    views: t('home_page.search.views'),
  });

  let office_keyword = watch('office_keyword', '');
  let residence_keyword = watch('residence_keyword', '');
  const { listDistricts, listCity, listCondition } = props?.layout;
  const { bedrooms, sizes, views } = listCondition || {};
  const facilities = listCondition?.facilities;
  const { locations } = listCity || {};

  let listOptionChidren = [
    { value: 0, label: '' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  let listOptionAdults = [
    { value: 0, label: '' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  const handleClickOutside = e => {
    if (isShowMore.value) {
      if (e.target.className && !e.target.className.toString().includes('flag-dropdown')) {
        setIsShowMore({ key: '', value: false });
      }
    }
  };

  useEffect(() => {
    if (
      officePriceMax !== undefined &&
      officePriceMaxVi !== undefined &&
      officePriceMin !== undefined &&
      officePriceMinVi !== undefined
    ) {
      let maxRange = currency?.code === 'USD' ? officePriceMax : officePriceMaxVi;
      let minRange = currency?.code === 'USD' ? officePriceMin : officePriceMinVi;
      setRangeSliderUnitPirce([minRange, maxRange]);
    }
    if (props.isShowModal) {
      setShowMoreConditions(true);
    }
    // eslint-disable-next-line
  }, [currency, listRangeSetting]);
  useEffect(() => {
    if (isShowMore.value) {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

    // eslint-disable-next-line
  }, [isShowMore]);

  useEffect(() => {
    let textFacilities = t('home_page.search.facilities');
    const listFacilities = [...formData.facilities.buildingFacilities, ...formData.facilities.interiorFacilities];
    if (listFacilities.length === 1) {
      const detail = [...facilities[2].items, ...facilities[1].items].find(i => i.id === listFacilities[0]);
      textFacilities = detail && detail.name ? detail.name : t('home_page.search.facilities');
    } else if (listFacilities.length > 1) {
      textFacilities = t('home_page.search.selected');
    }

    setDisplayOption({
      ...displayOption,
      facilities: textFacilities,
    });

    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    let filter = {
      language: language,
      cityId: city,
      keyword: residence_keyword.trim(),
      currencyUnit: currency?.code,
      districtId: formData?.districts?.join(','),
      type: 1, //long term
      bedroom: formData?.bedrooms?.join(','),
      leasePriceMin: units[0],
      leasePriceMax: units[1],
      size: formData?.sizes?.join(','),
      view: formData?.views?.join(','),
      facilities: [...formData?.facilities?.buildingFacilities, ...formData?.facilities?.interiorFacilities]?.join(','),
    };
    debounceTotalUnit(filter);
    return () => {
      debounceTotalUnit.cancel();
    };
    // eslint-disable-next-line
  }, [city, formData, units, residence_keyword]);

  useEffect(() => {
    let filter = {
      language: language,
      cityId: city,
      keyword: office_keyword.trim(),
      currencyUnit: currency?.code,
      type: 2, //short term
      checkIn: dateRange[0],
      checkOut: dateRange[1],
      leasePriceMin: unitPrice[0],
      leasePriceMax: unitPrice[1],
      adults: adultNum,
      children: childrenNum,
      totalBedroom: roomNum,
      facilities: [...formData?.facilities?.buildingFacilities, ...formData?.facilities?.interiorFacilities]?.join(','),
    };
    debounceTotalUnit(filter);
    return () => {
      debounceTotalUnit.cancel();
    };
    // eslint-disable-next-line
  }, [city, formData, unitPrice, dateRange, adultNum, childrenNum, roomNum, office_keyword]);

  const debounceTotalUnit = useCallback(
    debounce(nextValue => fetchTotalUnit(nextValue), 1000),
    [],
  );

  useEffect(() => {
    const textDistrict = returnTextOption(
      formData.districts,
      listDistricts,
      'districtName',
      t('home_page.search.district'),
    );
    const textBedroom = returnTextOption(formData.bedrooms, bedrooms, 'name', t('home_page.search.bedroom'));
    const textBuildingName = returnTextOption(formData.projects, 'projectName', t('home_page.search.building_name'));
    const textSize = returnTextOption(formData.sizes, sizes, 'name', t('home_page.search.size_range'));
    const textViews = returnTextOption(formData.views, views, 'name', t('home_page.search.views'));

    let textFacilities = t('home_page.search.facilities');
    const listFacilities = [...formData.facilities.buildingFacilities, ...formData.facilities.interiorFacilities];
    if (listFacilities.length === 1) {
      const detail = [...facilities[2].items, ...facilities[1].items].find(i => i.id === listFacilities[0]);
      textFacilities = detail && detail.name ? detail.name : t('home_page.search.facilities');
    } else if (listFacilities.length > 1) {
      textFacilities = t('home_page.search.selected');
    }

    setDisplayOption({
      ...displayOption,
      districts: textDistrict,
      bedrooms: textBedroom,
      projects: textBuildingName,
      sizes: textSize,
      facilities: textFacilities,
      views: textViews,
    });
    // eslint-disable-next-line
  }, [formData, units, residence_keyword]);

  const returnTextOption = (option, listData, name, defaultText) => {
    if (option.length === 1) {
      const detail = listData.find(i => i.id === option[0]);
      return detail && detail[name] ? detail[name] : defaultText;
    } else if (option.length > 1) {
      return t('home_page.search.selected');
    }
    return defaultText;
  };

  const fetchTotalUnit = async params => {
    await getTotalUnitServicedApartment(getQueryString(params));
  };

  const handleShowMore = () => {
    setShowMoreConditions(true);
  };

  const resetForm = e => {
    e.preventDefault();
    setFormData({
      districts: [],
      bedrooms: [],
      projects: [],
      sizes: [],
      types: [],
      facilities: {
        buildingFacilities: [],
        interiorFacilities: [],
      },
      views: [],
    });
    let maxRange = props.layout.currentCurrency?.code === 'USD' ? officePriceMax : officePriceMaxVi;
    let minRange = props.layout.currentCurrency?.code === 'USD' ? officePriceMin : officePriceMinVi;
    setRangeSliderUnitPirce([minRange, maxRange]);
    setDateRange([today, addDays(today, 3)]);
    setCity(location);
    setAdultNum(0);
    setChildrenNum(0);
    setRoomNum(0);
    document.getElementsByName('office_keyword')[0].value = '';
  };

  const handleOnChange = value => {
    setCity(value);
  };

  const handleChangeChild = newVal => {
    if (newVal >= 0) {
      setChildrenNum(newVal);
    }
  };

  const handleChangeAdult = newVal => {
    if (newVal >= 0) {
      setAdultNum(newVal);
    }
  };

  const listCitiesDropdown = useMemo(() => {
    if (locations && locations.length) {
      return (
        <Select
          options={locations.map(item => ({ value: item.cityId, label: item.cityName }))}
          class_name="form-control font-noto-sans-jp"
          name="location"
          src={Buildings}
          onChange={handleOnChange}
          defaultValue={city}
        />
      );
    }
    return (
      <Select
        disabled
        options={[]}
        isAll={{ value: '', label: t('home_page.search.city') }}
        class_name="form-control"
        src={Buildings}
      />
    );

    // eslint-disable-next-line
  }, [locations, city]);

  const listChidrenDropdown = useMemo(() => {
    return (
      <Select
        options={listOptionChidren.map(item => ({
          value: item.value,
          label: (item.value == ''? t('home_page.search.label_childrens') : t('home_page.search.label_children', {0 : item.label})),
        }))}
        class_name="form-control font-noto-sans-jp"
        name="Children"
        src={Children}
        onChange={handleChangeChild}
        defaultValue={childrenNum}
      />
    );

    // eslint-disable-next-line
  }, [childrenNum]);

  const listAdultsDropdown = useMemo(() => {
    return (
      <Select
        options={listOptionAdults.map(item => ({
          value: item.value,
          label: (item.value == ''? t('home_page.search.label_adults') : t('home_page.search.label_adult', {0 : item.label})),
        }))}
        class_name="form-control font-noto-sans-jp"
        name="Users"
        src={Users}
        onChange={handleChangeAdult}
        defaultValue={adultNum}
      />
    );

    // eslint-disable-next-line
  }, [adultNum]);

  const onChangeFormData = (key, value) => {
    const cloneFormData = { ...formData };
    if (Object.keys(cloneFormData).includes(key) && value.length < 5) {
      if (cloneFormData[key].includes(parseInt(value))) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== parseInt(value));
      } else cloneFormData[key].push(parseInt(value));
    } else if (Object.keys(cloneFormData.facilities).includes(key)) {
      if (cloneFormData.facilities[key].includes(Number.parseInt(value))) {
        cloneFormData.facilities[key] = cloneFormData.facilities[key].filter(item => item !== Number.parseInt(value));
      } else cloneFormData.facilities[key].push(Number.parseInt(value));
    } else {
      if (cloneFormData[key].includes(value)) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== value);
      } else cloneFormData[key].push(Number.parseInt(value));
    }
    setFormData(cloneFormData);
  };

  const listBedroomsDropdown = useMemo(() => {
    if (bedrooms && bedrooms.length) {
      return bedrooms.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.bedrooms}
            onChangeFormData={onChangeFormData}
            formName="bedrooms"
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [bedrooms, formData]);

  const onSearchClick = e => {
    //pass form search to component by state of history
    e.preventDefault();

    if (startDate === null || endDate === null) {
      setDateRange([null, null]);
      return;
    } else {
      let filter = {
        language: language,
        cityId: city,
        keyword: office_keyword.trim(),
        currencyUnit: currency?.code,
        type: 2, //short term
        checkIn: moment(dateRange[0]).format('YYYY-MM-DD') + 'T14:00:00Z',
        checkOut: moment(dateRange[1]).format('YYYY-MM-DD') + 'T12:00:00Z',
        leasePriceMin: unitPrice[0],
        leasePriceMax: unitPrice[1],
        adults: adultNum,
        children: childrenNum,
        totalBedroom: roomNum,
        facilities: [...formData?.facilities?.buildingFacilities, ...formData?.facilities?.interiorFacilities]?.join(
          ',',
        ),
      };
      // moment(dateRange[0]).format('YYYY-MM-DD') + 'T12:00:00Z';
      let searchDataCheckInOut = {
        checkIn: moment(dateRange[0]).format('YYYY-MM-DD') + 'T14:00:00Z',
        checkOut: moment(dateRange[1]).format('YYYY-MM-DD') + 'T12:00:00Z',
      };

      sessionStorage.setItem('filter_search', JSON.stringify(filter));
      sessionStorage.setItem('searchDataCheckInOut', JSON.stringify(searchDataCheckInOut));
      let queryString = '';
      //Begin param url Handle search for Popup Search Global
      if (locationPath.pathname.includes('search-result')) {
        // setOrderBy(searchParams.get('searchGlobal'))
        const searchGlobal = searchParams.get('searchGlobal') === 'false' ? 'true' : 'false';
        queryString = getQueryString({ pageIndex: 1, pageSize: 15, orderBy: 'desc', searchGlobal: searchGlobal });
      } else {
        queryString = getQueryString({ pageIndex: 1, pageSize: 15, orderBy: 'desc' });
      }
      //End param url Handle search for Popup Search Global

      if (props.isShowModal) {
        props.hanldeCloseModal();
      }
      history.push({ pathname: `/${language}/search-result`, search: queryString, state: { filter: filter } });
    }
  };

  return (
    <>
      <div className="tab-content" id="searchFormContent">
        <div className="tab-pane fade" id="Residence" role="tabpanel" aria-labelledby="Residence-tab"></div>
        <div className="tab-pane fade show active" id="Office" role="tabpanel" aria-labelledby="Office-tab">
          <Form>
            <div className="group-control">
              <div className="row align-items-end mb-1">
                <div onClick={() => handleShowMore()} className="col-md-4 col-12 mb-1 mb-2 pl-0 pl-md-3  pr-0">
                  <Input
                    class_name="form-control font-noto-sans-jp"
                    type="text"
                    placeholder={t('home_page.search.key_word_vinhomes')}
                    errors={errors.office_keyword}
                    register={register()}
                    name="office_keyword"
                    id="mySearch"
                    src={SearchLub}
                    maxLength={255}
                  />
                </div>
                <div className="col-md-6 col-12 pl-0 pr-0 d-block d-md-flex">
                  <div className="col-md-5 col-12 mb-1 pl-0 pl-md-3  pr-0">{listCitiesDropdown}</div>
                  <div className="col-md-7 col-12 order-md-1 mb-1 pl-0 pl-md-3 mb-2 pr-0">
                    <div className="form-input-group">
                      <DatePicker
                        onFocus={e => e.target.blur()}
                        className="form-control pl-0"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={update => {
                          setDateRange(update);
                        }}
                        minDate={today}
                        disabledKeyboardNavigation
                        dateFormat={
                          language === 'jp' ? 'yyyy年MM月d日' : language === 'en' ? 'MMM d, yyyy' : 'dd/MM/yyyy'
                        }
                        maxDate={addMonths(startDate, 1)}
                        excludeDates={[startDate]}
                      />
                      <img src={Calendar} alt="calendar-icon"></img>
                    </div>
                  </div>
                </div>

                <div className="d-none d-md-block col-2 mb-2 pr-0">
                  <div className="d-flex flex-column text-left">
                    <div className="col-12 btn-search-home">
                      <button className="btn-submit-home mr-3" onClick={e => onSearchClick(e)}>
                        <SearchIcon />
                      </button>
                      <button className="btn-reset-home" onClick={e => resetForm(e)}>
                        <ResetIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* district */}
              <div className={`row align-items-end ${(!showMoreConditions && 'more') || ''}`}>
                <div className="col-md-4 col-12 mb-1 pr-0 pl-0 pl-md-3 d-block d-md-flex">
                  <div className="d-flex flex-column col-12 mb-2 pl-0 pr-0 text-left">
                    <div className="form-input-group font-noto-sans-jp">
                      <div
                        className={`form-control select-control font-noto-sans-jp ${
                          displayOption.bedrooms !== t('home_page.search.bedroom') ? 'search-selected' : ''
                        }`}
                        id="facilities"
                        onClick={() => {
                          setIsShowMore({ key: 'showMoreBedRoom', value: true });
                        }}
                      >
                        {displayOption.bedrooms}
                      </div>
                      <img src={DoubleBed} alt="bed-icon"></img>
                    </div>
                  </div>
                  <div
                    className={`facilities fade-in flag-dropdown ${
                      (isShowMore.key === 'showMoreBedRoom' && isShowMore.value ? 'show' : '') || ''
                    }`}
                    id="fcDropDown"
                  >
                    <div className="row flag-dropdown">
                      <div className="col-10 flag-dropdown">
                        <span className="link-red uppercase flag-dropdown">{t('home_page.search.bedroom')}</span>
                      </div>
                      <div className="col-2 text-right flag-dropdown">
                        <span
                          className="close-more flag-dropdown"
                          onClick={() => {
                            setIsShowMore({ key: 'showMoreBedRoom', value: false });
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div className="row building flag-dropdown">{listBedroomsDropdown}</div>
                  </div>
                </div>

                {/* size */}
                <div className="col-md-5 col-12 mb-2 pr-0 pl-0 d-block d-md-flex">
                  <div className="col-md-6 col-12 mb-1 pl-0 pl-md-3 pr-0">{listChidrenDropdown}</div>
                  <div className="col-md-6 col-12 mb-1 pl-0 pl-md-3 pr-0">{listAdultsDropdown}</div>
                </div>
              </div>
            </div>
            <div className="d-md-none col-12 mt-3 pl-0 pr-0 btn-search-home d-block">
              <div className="col-12 mb-4 pl-0 pr-0">
                <button className="btn-submit-home w-100" onClick={e => onSearchClick(e)}>
                  <SearchIcon /> {t('home_page.search.text_btn_search')}
                </button>
              </div>
              <div className="col-12 pl-0 pr-0">
                <button className="btn-reset-home w-100" onClick={e => resetForm(e)}>
                  <ResetIcon /> {t('home_page.search.reset_criteria')}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchOffices);
