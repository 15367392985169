// eslint-disable-next-line no-unused-vars
import { get } from 'lodash';
import Service from '../../services';

export const createIssue = async params => {
  const response = await Service.isnPost(`/api/customersupports/submit-form`, params, res => res);
  // const data = get(response);
  return response;
};

export const getIssues = async params => {
  const response = await Service.isnGet(`/api/customersupports/get-issues${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getFaqsHelps = async params => {
  const response = await Service.isnGet(`/api/customersupports/get-asked${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getHelpCategories = async params => {
  const response = await Service.isnGet(`/api/customersupports/get-categories${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getBanner = async params => {
  const response = await Service.isnGet(`/api/customersupports/get-banner${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getContactByType = async params => {
  const response = await Service.isnGet(`/api/customersupports/get-contact-by-type${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};
