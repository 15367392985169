import Service from '../../services';

export const fetchLoginRequest = async params => {
  const response = await Service.isnPost(`/api/user/login`, params, res => res);
  return response;
};

export const fetchRefreshTokenRequest = async params => {
  const response = await Service.isnPost(`/api/user/refresh-token`, params, res => res);
  return response;
};
