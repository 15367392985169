import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/layout.action';
import { LoginComponent } from '../../../modules/loginPage/pages';
import ForgotModal from '../../../modules/forgotPassword/pages/ForgotModal.component';
import { ChevronDownIcon } from '../icons';
import { VerifyEmailComponent } from '../../../modules/signUp/pages';
import { Phone, Question, Globe, Wallet, Location, LogoutIcon, ProfileIcon } from '../../../assets/images/web-isn';
import useFullPageLoader from '../../../modules/commons/isn/useFullPageLoader';
import { postLogout } from 'modules/accountPage/accountPage.api';
import { getContactByType } from 'modules/helpPage/helps.api';
import { getQueryString } from '../../../utils/params';

const HeaderTop = props => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { language } = useParams();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const authUserData = JSON.parse(localStorage.getItem('authUserData'));
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const { customerContact } = props.layout;
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [userData, setUserData] = useState({});

  const getCityCode = localStorage.getItem('LOCATION_CODE_CITY') ? localStorage.getItem('LOCATION_CODE_CITY') : 'HN';
  const [cityName, setCityName] = useState(`${t('location_' + getCityCode)}`);
  const [currency, setCurrency] = useState({});
  const handleShowLogin = () => {
    setShowLogin(true);
    setShowForgot(false);
  };

  const closeVerifyEmailPopup = () => {
    setShowVerifyPopup(false);
  };
  const showVerifyEmailPopup = () => {
    setShowVerifyPopup(true);
  };

  const handleShowForgot = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleShowSignUp = () => {
    setShowLogin(false);
    setShowVerifyPopup(true);
  };

  useEffect(() => {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
    const city = localStorage.getItem('LOCATION_CITY');
    //eslint-disable-next-line
    getContactByType(getQueryString({ cityId: city ?? 1, language: language, type: 4 })).then(res => {
      if (res) {
        setPhoneNumber(res[0]?.phone);
      }
    });
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (authUserData && authUserData.token && authUserData.token.refresh_token) {
      setShowUserMenu(true);
      // getUserProfile().then(res => {
      //   if (res) {
      //     setUserData(res);
      //   }
      // });
    } else {
      setShowUserMenu(false);
    }
  }, [authUserData]);

  const { listLanguage, listCity, listCurrency } = props?.layout || {};
  const { defaultLocation, locations } = listCity || {};

  useEffect(() => {
    if (defaultLocation && defaultLocation !== '') {
      localStorage.setItem('DEFAULT_CITY', defaultLocation?.defaultLocationId);
    }
  }, [defaultLocation]);

  useEffect(() => {
    props.fetchLanguagesRequest();
    props.fetchCurrencyRequest();
    props.fetchCitiesRequest({ language });
    props.fetchListConditionRequest({ language });
    props.fetchRangeSettingRequest();

    const currentLocale = localStorage.getItem('LOCATION_CITY')
      ? localStorage.getItem('LOCATION_CITY')
      : localStorage.getItem('DEFAULT_CITY');
    getContentHomePage(currentLocale);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let currencyDefault = listCurrency?.filter(el => el?.isDefault)[0];
    if (currencyDefault) {
      let currencyStorage = JSON.parse(localStorage.getItem('CURRENCY'));
      if (currencyStorage) {
        setCurrency(currencyStorage);
        props.fetchCurrencyCurrent(currencyStorage);
      } else {
        localStorage.setItem('CURRENCY', JSON.stringify(currencyDefault));
        setCurrency(currencyDefault);
        props.fetchCurrencyCurrent(currencyDefault);
      }
    }

    //eslint-disable-next-line
  }, [listCurrency]);

  const getContentHomePage = cityId => {
    if (cityId) {
      props.fetchDistrictsByCityIdRequest({ language, cityId });
      props.fetchCustomerContactRequest({ language, cityId });
    }
  };

  const onChangeLanguage = langPath => {
    const lang = langPath.split('/')[1];
    const listParams = location.pathname.split('/');
    if (lang !== language) {
      listParams[1] = lang;
      const newPathname = listParams.join('/');
      const refUrl = location.search.replace(/language=(.*?(jp|vi|en))/gi, `language=${lang}`);
      window.location.href = newPathname + refUrl;
    } else {
      return;
    }
  };

  const handleChangeCurrency = (e, item) => {
    if (item.code !== currency.code) {
      localStorage.setItem('CURRENCY', JSON.stringify(item));
      setCurrency(item);
      props.fetchCurrencyCurrent(item);
    }
  };

  const logout = async () => {
    showLoader();
    postLogout({ email: `${authUserData.userInfo.email}` }).then(res => {
      if (res.status === 'success') {
        localStorage.removeItem('authUserData');
        setShowUserMenu(false);
        window.location.reload();
      }
    });
    hideLoader();
    setShowUserMenu(false);
  };

  const handleLang = () => {
    let lang = localStorage.getItem('language');
    if (listLanguage && listLanguage.length !== 0) {
      return (
        <ul className="wrap-dropdown-flag">
          {listLanguage.map((item, i) => {
            return (
              <li
                key={i}
                className="flag-item"
                style={{ backgroundColor: lang === item.code ? '#dadada' : '' }}
                onClick={() => onChangeLanguage('/' + item.code)}
              >
                <div>
                  <img
                    width="25%"
                    alt="flag-icon"
                    src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item.flag_Image_Url}`}
                  />
                  <span className="item-link">{item.name}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const handleChangeLocation = async (id, cityCode) => {
    if (id && cityCode && locations && locations.length) {
      setCityName(`${t('location_' + cityCode)}`);
      await localStorage.setItem('LOCATION_CODE_CITY', cityCode);
      await localStorage.setItem('LOCATION_CITY', id);
      window.location.href = location.pathname + location.search.toString();
    }
  };

  const handleListCities = () => {
    let city = parseInt(localStorage.getItem('LOCATION_CITY') ?? localStorage.getItem('DEFAULT_CITY'));
    if (locations && locations.length > 0) {
      return (
        <ul className="wrap-dropdown-flag">
          {locations.map((item, i) => {
            return (
              <li
                onClick={() => handleChangeLocation(item.cityId, item.code)}
                className="flag-item"
                style={{ backgroundColor: city === item.cityId ? '#dadada' : '' }}
                key={i}
              >
                <div>
                  <span className="item-link">{item.cityName}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const handleCurrency = () => {
    if (listCurrency && listCurrency.length > 0) {
      return (
        <ul className="wrap-dropdown-flag">
          {listCurrency.map((item, i) => {
            return (
              <li
                className="flag-item"
                style={{ backgroundColor: currency?.code === item.code ? '#dadada' : '' }}
                key={i}
                onClick={e => {
                  handleChangeCurrency(e, item);
                }}
              >
                <div>
                  <span className="item-link">{item.code}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };
  const onClickPreventDefault = event => {
    event.preventDefault();
  };

  const gotoPage = (event, link) => {
    event.preventDefault();
    history.push(link);
    if (link.includes(location.pathname)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const goProfile = e => {
    e.preventDefault();
    history.push(`/${language}/manage-account/personal-detail`);
    setShowUserMenu(false);
  };

  return (
    <section className="z-index-header-top section-header-top header-top background-black">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="nav-menu-top">
              <ul className="menu-top d-flex bd-highlight">
                <div className="d-flex align-items-center mr-auto">
                  <li className="pl-0">
                    {customerContact.length > 0 ? (
                      <a href={`tel:${phoneNumber}`} className="item-link">
                        <img src={Phone} alt="phone-icon"></img>
                        <span className="book-now book-now-hide font-noto-sans-jp">
                          {t('home_page.menu_book_now', { phone: phoneNumber })}
                        </span>
                        <span className="book-now book-now-show font-noto-sans-jp">{phoneNumber}</span>
                      </a>
                    ) : (
                      ''
                    )}
                  </li>
                  <li className="header-hide">
                    <a
                      href="/"
                      onClick={e => {
                        gotoPage(e, `/${language}/helps`);
                      }}
                      className="item-link"
                    >
                      <img src={Question} alt="question-icon"></img>
                      <span
                        className={`font-noto-sans-jp ${location.pathname.includes('helps') ? 'link-brown-bold' : ''}`}
                      >
                        {t('home_page.menu_help')}
                      </span>
                    </a>
                  </li>
                  <li className="header-hide">
                    <a
                      href="/"
                      className="item-link"
                      onClick={e => {
                        onClickPreventDefault(e);
                      }}
                    >
                      <img src={Globe} alt="globe-icon"></img>
                      <span className="font-noto-sans-jp">{t('text_language')}</span>
                      <ChevronDownIcon />
                    </a>
                    {handleLang()}
                  </li>
                  <li className="header-hide">
                    <a
                      href="/"
                      className="item-link"
                      onClick={e => {
                        onClickPreventDefault(e);
                      }}
                    >
                      <img src={Wallet} alt="globe-icon"></img>
                      <span className="font-noto-sans-jp">{currency?.code}</span>
                      <ChevronDownIcon />
                    </a>
                    {handleCurrency()}
                  </li>
                  <li className={window.location.href.includes('/payment-summary/') ? 'd-none' : 'header-hide'}>
                    <a
                      href="/"
                      className="item-link"
                      onClick={e => {
                        onClickPreventDefault(e);
                      }}
                    >
                      <img src={Location} alt="location-icon"></img>
                      <span className="font-noto-sans-jp">{cityName}</span>
                      <ChevronDownIcon />
                    </a>
                    {handleListCities()}
                  </li>
                </div>
                {showUserMenu === true ? (
                  <li style={{ cursor: 'default' }}>
                    <div className="d-flex justify-content-end">
                      <img
                        src={authUserData?.userInfo?.imageUrl}
                        alt=""
                        style={{ width: '42px', height: '42px', marginRight: '12px', borderRadius: '50%' }}
                      ></img>
                      {/* <img className="ml-2" src={ArrowDown} alt="arrow-icon"></img> */}
                      <span className="header-top_user-name d-flex justify-content-end align-items-center">
                        {authUserData?.userInfo?.fullName}
                      </span>
                    </div>
                    <ul className="dropdown-user">
                      <li className="py-3" onClick={e => goProfile(e)}>
                        <div className="d-flex align-items-center">
                          <img src={ProfileIcon} alt="profile-icon" />
                          <span>{t('manageAccount.label_profileInfo')}</span>
                        </div>
                      </li>
                      <div
                        style={{
                          borderBottom: '1px solid #EEEEEE',
                          margin: '0px 12px',
                        }}
                      ></div>
                      <li className="flag-item py-3" onClick={() => logout()}>
                        <div className="d-flex align-items-center">
                          <img src={LogoutIcon} alt="logout-icon" />
                          <span className="item-link">{t('home_page.btn_logout')}</span>
                        </div>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="px-0">
                    <div className="item-link d-flex justify-content-end">
                      <button className="login-btn" onClick={() => setShowLogin(true)}>
                        {t('home_page.btn_login')}
                      </button>
                      <button className="signup-btn" onClick={() => showVerifyEmailPopup()}>
                        {t('home_page.btn_signup')}
                      </button>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <LoginComponent
        closeLogin={() => {
          setShowLogin(false);
        }}
        showLogin={showLogin}
        handleShowForgot={handleShowForgot}
        handleShowSignUp={handleShowSignUp}
      />
      <VerifyEmailComponent
        showVerifyEmail={showVerifyPopup}
        hideVerifyEmail={closeVerifyEmailPopup}
      ></VerifyEmailComponent>
      <ForgotModal showModal={showForgot} hideModal={() => setShowForgot(false)} handleShowLogin={handleShowLogin} />
      {loader}
    </section>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCitiesRequest: actions.fetchCitiesRequest,
      fetchDistrictsByCityIdRequest: actions.fetchDistrictsByCityIdRequest,
      fetchCurrencyRequest: actions.fetchCurrencyRequest,
      fetchLanguagesRequest: actions.fetchLanguagesRequest,
      fetchListConditionRequest: actions.fetchListConditionRequest,
      fetchCustomerContactRequest: actions.fetchCustomerContactRequest,
      fetchRangeSettingRequest: actions.fetchRangeSettingRequest,
      fetchCurrencyCurrent: actions.fetchCurrencyCurrent,
      fetchLogoutSuccess: actions.fetchLogoutSuccess,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);
