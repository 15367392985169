import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbForOwners, HeaderForOwners, Questions } from '../common';
// import background from '../../../assets/images/backgound-faqs-owners.jpg';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { getBanner } from 'modules/forOwnersPage/forOwners.api';
import { useLocation } from 'react-router-dom';
import { getLanguage, getQueryString } from '../../../utils/params';
import { FaqImageFooter } from '../../../assets/images/web-isn';

const FaqsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [headerContent, setheaderContent] = useState('');
  const breadCrumb = [
    { title: t('for_owners.home'), url: '/' },
    { title: t('for_owners.for_owners'), url: '/for-owners' },
    { title: t('for_owners.faqs'), url: '/for-owners/faqs' },
  ];
  // const headerContent = {
  //   title: t('for_owners.frequently_asked_questions'),
  //   background,
  // };

  useEffect(() => {
    showLoader();
    getBanner(getQueryString({ language, categoryId: 2 })).then(res => {
      setheaderContent(res)
      hideLoader();
      window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    })
    //eslint-disable-next-line
  }, [language]);

  return (
    <>
      <BreadcrumbForOwners breadCrumb={breadCrumb} />
      <HeaderForOwners content={headerContent} />

      <div className="container">
        <div className="faqs">
          <div className="row">
            <div className="col-12">
              <Questions showLoader={showLoader} hideLoader={hideLoader} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: '6px' }}>
        <img className="faq-image-footer" src={FaqImageFooter} alt="" />
      </div>
      {loader}
    </>
  );
};

export default FaqsComponent;
