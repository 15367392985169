import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BannerApartment, BreadcumbApartment, MenuApartment } from '../common';
import { getDetailOffer } from '../detailApartment.api';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { getQueryString } from '../../../utils/params';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoginComponent } from 'modules/loginPage/pages';
import { VerifyEmailComponent } from 'modules/signUp/pages';
import ForgotModalComponent from 'modules/forgotPassword/pages/ForgotModal.component';
import { ContactUs } from '../pages';

const OffersComponent = () => {
  const { t } = useTranslation();
  const { typeCode } = useParams();
  const [offerInfo, setOfferInfo] = useState();
  const [listOffer, setListOffer] = useState();
  const { language } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const history = useHistory();
  const [apartmentId, setApartmentId] = useState(null);
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    showLoader();
    const typeCodeId = typeCode === 'long' ? 1 : 2;
    getDetailOffer(getQueryString({ language, apartmentId, type: typeCodeId })).then(res => {
      hideLoader();
      if (res) {
        setOfferInfo(res);
        setListOffer(res.services);
      }
    });
    //eslint-disable-next-line
  }, [language, apartmentId]);

  const handleShowLogin = () => {
    setShowLogin(true);
    setShowForgot(false);
  };

  const handleShowForgot = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleShowSignUp = () => {
    setShowLogin(false);
    setShowVerifyPopup(true);
  };
  const closeVerifyEmailPopup = () => {
    setShowVerifyPopup(false);
  };

  const handleButtonBook = () => {
    if (typeCode === 'long') {
      setShowContact(true);
    } else {
      const authUserData = JSON.parse(localStorage.getItem('authUserData'));
      if (authUserData) {
        history.push(`/${language}/payment-summary/${apartmentId}`);
      } else {
        handleShowLogin();
      }
    }
  };

  const getApartmentId = apartmentId => {
    setApartmentId(apartmentId);
  };

  return (
    <>
      <BreadcumbApartment />
      <BannerApartment />
      <MenuApartment getApartmentId={getApartmentId} />
      <ContactUs
        showLogin={showContact}
        closeLogin={() => {
          setShowContact(false);
        }}
      />
      {offerInfo && offerInfo !== null ? (
        <div id="offers-detail" className="container mt-5">
          <h3 className="text-center title">{offerInfo.name}</h3>
          <div className="row">
            <div className="col-4"></div>
            <div className="d-flex flex-column col-4">
              {listOffer && listOffer.length > 0
                ? listOffer.map((item, i) => {
                    return (
                      <div className="mt-4" key={i}>
                        <img src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item.imageUrl}`} alt="icon-offers"></img>
                        <span className="content ml-3">{item.name}</span>
                      </div>
                    );
                  })
                : ''}
            </div>
            <div className="col-4"></div>
          </div>
          {typeCode === 'long' ?
            <div className="text-center my-5">
              <p>{t('detailApartment.contact_us_note')}</p>
            </div> : ''
          }
          <div className="text-center my-5">
            <input
              className="btn-booknow"
              type="button"
              onClick={handleButtonBook}
              value={typeCode === 'long' ? t('detailApartment.contact_us_title') : t('detailApartment.book_now_title')}
            />
          </div>
        </div>
      ) : (
        ''
      )}{' '}
      <LoginComponent
        closeLogin={() => {
          setShowLogin(false);
        }}
        showLogin={showLogin}
        handleShowForgot={handleShowForgot}
        handleShowSignUp={handleShowSignUp}
      />
      <VerifyEmailComponent
        showVerifyEmail={showVerifyPopup}
        hideVerifyEmail={closeVerifyEmailPopup}
      ></VerifyEmailComponent>
      <ForgotModalComponent
        showModal={showForgot}
        hideModal={() => setShowForgot(false)}
        handleShowLogin={handleShowLogin}
      />
      {loader}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OffersComponent);
