import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ClockIcon } from '../../../layouts/commons/icons';
import { ArrowRight } from '../../../assets/images/web-isn';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const BlockBlogComponent = props => {
  const { post } = props;
  const { t } = useTranslation();
  const { language } = useParams();

  if (post.blogTitle) post.blogTitle = post.blogTitle.replace('　', ' ');

  return (
    <>
      <div className="block-inner">
        <div className="block-image">
          <Link
            className="block-image-link block-handle-click"
            to={`/${language}/blog/${post.categorySlug}/detail/${post.id}/${post.slug}?home=true`}
          >
            <LazyLoadImage
              alt=""
              src={`${process.env.REACT_APP_ISN_IMAGE_URL}${post.blogThumnail}`}
              className="img-blog"
              effect="blur"
            />
          </Link>
        </div>
        <Link
          to={`/${language}/blog/${post.categorySlug}/detail/${post.id}/${post.slug}?home=true`}
          className="d-flex flex-column justify-content-between block-text blog-description text-decoration-none w-100"
        >
          <div>
            {!!post.date && (
              <div className="created">
                <ClockIcon />
                <span className="font-noto-sans-jp">{post.date}</span>
              </div>
            )}
            <div
              className="text-decoration-color-black-hover"
              to={`/${language}/blog/${post.categorySlug}/detail/${post.id}/${post.slug}?home=true`}
            >
              <h4 className="mt-3 text-decoration-none project-name">{post.blogTitle || 'No title'}</h4>
            </div>
          </div>
          <div
            className="text-decoration-color-black-hover pt-2"
            to={`/${language}/blog/${post.categorySlug}/detail/${post.id}/${post.slug}?home=true`}
          >
            <div className="d-flex mt-2 align-items-center">
              <span className="read-more mr-3 font-noto-sans-jp project-name">{t('home_page.btn_readmore')}</span>
              <img className="icon" src={ArrowRight} alt="arrow-icon"></img>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default BlockBlogComponent;
