import * as types from './signUp.types';

const initialState = {
  isExist: null,
  message: null
};

const verifyEmaildReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_VERIFY_EMAIL_REQUESTED:
      return { ...state };
    case types.FETCH_VERIFY_EMAIL_SUCCEEDED: {
      return {
        ...state, isExist: action.data ? action.data.isExist : null,
        message: action.data ? action.data.message : null
      };
    }
    case types.FETCH_VERIFY_EMAIL_FAILED:
      return { ...state, isExist: null, message: null };

    case types.FETCH_VERIFY_KEY_EMAIL_REQUESTED:
      return { ...state };
    case types.FETCH_VERIFY_KEY_EMAIL_SUCCEEDED: {
      return { ...state };
    }
    case types.FETCH_VERIFY_KEY_EMAIL_FAILED:
      return { ...state, isExist: null, message: null };

    case types.FETCH_COMPLETE_SIGNUP_REQUESTED:
      return { ...state };
    case types.FETCH_COMPLETE_SIGNUP_SUCCEEDED: {
      return { ...state };
    }
    case types.FETCH_COMPLETE_SIGNUP_FAILED:
      return { ...state };

    case types.FETCH_LOGIN_BY_GOOGLE_REQUESTED:
      return { ...state };
    case types.FETCH_LOGIN_BY_GOOGLE_SUCCEEDED: {
      return { ...state };
    }
    case types.FETCH_LOGIN_BY_GOOGLE_FAILED:
      return { ...state };

    default:
      return state;
  }
};
export default verifyEmaildReducer;