import { call, put, takeEvery } from 'redux-saga/effects';
import { setLoading } from '../../layouts/loading/loading.action';
import * as actions from './login.action';
import * as API from './login.api';
import * as types from './login.types';

export function* fetchLoginRequest(action) {
  const { info, handleSuccess, handleError } = action.params;
  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchLoginRequest, info);
    handleSuccess(data.data.token, data.data.userInfo);
    yield put(actions.fetchLoginSuccess(data.data));
  } catch (error) {
    yield put(actions.fetchLoginFailure(error));
    handleError(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchRefeshTokenRequest(action) {
  const { dataToken, handleSuccess, handleError } = action.params;
  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchRefreshTokenRequest, dataToken);
    yield put(actions.fetchRefreshTokenSuccess(data));
    handleSuccess(data.data.access_token, data.data.refresh_token);
  } catch (error) {
    yield put(actions.fetchRefreshTokenFailure(error));
    handleError(error);
  } finally {
    yield put(setLoading(false));
  }
}

export function* loginSaga() {
  yield takeEvery(types.FETCH_LOGIN_REQUEST, fetchLoginRequest);
  yield takeEvery(types.FETCH_REFRESH_TOKEN_REQUEST, fetchRefeshTokenRequest);
}
