import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as actions from '../special.action';
import { SpecialResutlsComponent } from '../common';
import { getPathLanguage, getLanguage } from '../../../utils/params';

const SpecialComponent = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const language = getLanguage(location);
  const params = useParams();

  useEffect(() => {
    props.fetchSpecialRequest({ language, specialId: params.id });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const { loading } = props.loading;

  return (
    <>
      <div className="section-block section-breadcrumb background-gray breadcrumb-special-list">
        <div className="container">
          <div className="row breadcrumb align-items-center">
            <div className="col-12">
              <div className="breadcrumb-item row">
                <ul>
                  <li className="first">
                    <Link className="home" to={pathLanguage}>
                      {t('search_page.home')}
                    </Link>
                  </li>
                  {/* <li className="item">
                    <span className="breadcrumb-dot">&#8226;</span>
                    <span className="normal">{t('search_page.special')}</span>
                  </li> */}
                  <li className="item">
                    <span className="breadcrumb-dot">&#8226;</span>
                    <span className="active">{t('home_page.special_list')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SpecialResutlsComponent
        loading={loading || false}
        data={props.special.data}
        title={props.special.specialName || ''}
      />
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSpecialRequest: actions.fetchSpecialRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SpecialComponent);
