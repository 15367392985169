import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const ResetCommon = props => {
  const { err, message, handleInput, title, mask, type, maxLength } = props;

  const handleChange = e => {
    e.preventDefault();
    handleInput(e.target.value);
  };

  return (
    <div className="form-group d-flex flex-column mt-4">
      <div className="d-flex">
        <label className="field-name">{title}</label>
        {mask === '1' ? <span className="required-mask">&nbsp;*</span> : ''}
      </div>
      <input
        onChange={handleChange}
        type={type}
        className={`${err ? 'reset-input-err' : 'reset-input'}`}
        maxLength={maxLength}
      />
      {err && <div className="error-message">{message}</div>}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetCommon);
