import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getQueryString } from '../../../utils/params';
import { getApartmentId, getDetailOffer } from '../detailApartment.api';

function MenuApartmentComponent(props) {
  const { t } = useTranslation();
  const { id, slug, typeCode } = useParams();
  const location = useLocation();
  const [isScrollUp, setIsScrollUp] = useState(false);
  const prevScrollY = useRef(0);
  const apartmentParentLink = localStorage.getItem('apartmentParentLink');
  const { language } = useParams();
  const [offerItems, setOfferItems] = useState(null);

  useEffect(() => {
    const getHref = window.location.href;
    if (getHref.includes('?home')) {
      localStorage.setItem('apartmentParentLink', '?home');
    } else localStorage.setItem('apartmentParentLink', '');

    const typeCodeId = typeCode === 'long' ? 1 : 2;

    getApartmentId(getQueryString({ commonId: id, typeId: typeCodeId })).then(res => {
      if (res && props.getApartmentId !== undefined) {
        props.getApartmentId(res);
      }

      getDetailOffer(getQueryString({ language, apartmentId: res, type: typeCodeId })).then(res => {
        if (res) {
          setOfferItems(res.services);
        }
      });
    });

    return function cleanup() {
      localStorage.removeItem('apartmentName');
      localStorage.removeItem('apartmentParentLink');
    };
    //eslint-disable-next-line
  }, [language, id]);

  const menuItems = [
    {
      to: window.location.pathname.includes('/serviced-apartments/')
        ? `/serviced-apartments/overview/${id}/${slug}/${typeCode}${apartmentParentLink}`
        : window.location.pathname.includes('/offers/')
        ? `/offers/overview/${id}/${slug}/${typeCode}`
        : window.location.pathname.includes('/search-result/')
        ? `/search-result/overview/${id}/${slug}/${typeCode}`
        : '',
      name: t('detailApartment.overview_title'),
      active: location.pathname.includes(`/overview`),
      isShow: true,
    },
    {
      to: window.location.pathname.includes('/serviced-apartments/')
        ? `/serviced-apartments/amenities/${id}/${slug}/${typeCode}${apartmentParentLink}`
        : window.location.pathname.includes('/offers/')
        ? `/offers/amenities/${id}/${slug}/${typeCode}`
        : window.location.pathname.includes('/search-result/')
        ? `/search-result/amenities/${id}/${slug}/${typeCode}`
        : '',
      name: t('detailApartment.amenities_title'),
      active: location.pathname.includes(`/amenities`),
      isShow: true,
    },
    {
      to: window.location.pathname.includes('/serviced-apartments/')
        ? `/serviced-apartments/location/${id}/${slug}/${typeCode}${apartmentParentLink}`
        : window.location.pathname.includes('/offers/')
        ? `/offers/location/${id}/${slug}/${typeCode}`
        : window.location.pathname.includes('/search-result/')
        ? `/search-result/location/${id}/${slug}/${typeCode}`
        : '',
      name: t('detailApartment.location_title'),
      active: location.pathname.includes(`/location`),
      isShow: true,
    },
    {
      to: window.location.pathname.includes('/serviced-apartments/')
        ? `/serviced-apartments/gallery/${id}/${slug}/${typeCode}${apartmentParentLink}`
        : window.location.pathname.includes('/offers/')
        ? `/offers/gallery/${id}/${slug}/${typeCode}`
        : window.location.pathname.includes('/search-result/')
        ? `/search-result/gallery/${id}/${slug}/${typeCode}`
        : '',
      name: t('detailApartment.gallery_title'),
      active: location.pathname.includes(`/gallery`),
      isShow: true,
    },
    {
      to: window.location.pathname.includes('/serviced-apartments/')
        ? `/serviced-apartments/ratings-reviews/${id}/${slug}/${typeCode}${apartmentParentLink}`
        : window.location.pathname.includes('/offers/')
        ? `/offers/ratings-reviews/${id}/${slug}/${typeCode}`
        : window.location.pathname.includes('/search-result/')
        ? `/search-result/ratings-reviews/${id}/${slug}/${typeCode}`
        : '',
      name: t('detailApartment.ratings_reviews_title'),
      active: location.pathname.includes(`/ratings-reviews`),
      isShow: true,
    },
    {
      to: window.location.pathname.includes('/serviced-apartments/')
        ? `/serviced-apartments/offer-sub/${id}/${slug}/${typeCode}${apartmentParentLink}`
        : window.location.pathname.includes('/offers/')
        ? `/offers/offers/${id}/${slug}/${typeCode}`
        : window.location.pathname.includes('/search-result/')
        ? `/search-result/offers/${id}/${slug}/${typeCode}`
        : '',
      name: t('detailApartment.offers_title'),
      active: location.pathname.includes(`/offer-sub`) || location.pathname.includes(`/offers/offers`) || location.pathname.includes(`/search-result/offers`),
      isShow: offerItems?.length > 0 ? true : false,
    },
  ];

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    prevScrollY.current >= 396 ? setIsScrollUp(true) : setIsScrollUp(false);
    prevScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [prevScrollY]);

  return (
    <div id="detailApartmentMenu" className={`${isScrollUp ? 'detailApartmentMenu' : 'detailApartmentMenuHide'}`}>
      <div className="container">
        <div className="row scroll-menu px-3 pb-3 pt-3">
          {menuItems.map((item, index) => {
            if (item.isShow) {
              return (
                <Link
                  key={index}
                  to={`/${language}` + item.to}
                  className={`menu-apartment-item right-space ${item.active ? 'active_item' : ''}`}
                >
                  {item.name}
                </Link>
              );
            } else {
              return '';
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default MenuApartmentComponent;
