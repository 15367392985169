import Service from '../../services';

export const getServiceApartment = async params => {
  const response = await Service.isnGet(`/api/apartment/list-apartment${params}`, res => res);
  return response;
};

export const getListTypeTerm = async params => {
  const response = await Service.isnGet(`/api/filters/get-long-short${params}`, res => res);
  return response;
};

export const getFavoriteApartment = async params => {
  //eslint-disable-next-line
  let token = JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token;
  await Service.setHeaderToken(token);
  const response = await Service.isnGet(`/api/userprofile/favorites${params}`, res => res);
  return response;
};

export const postNewFavorite = async payload => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnPost(`/api/userprofile/create-favorite`, payload, res => res);
  return response;
};
export const postRemoveFavorite = async payload => {
  await Service.setHeaderToken(JSON.parse(localStorage.getItem('authUserData'))?.token?.access_token);
  const response = await Service.isnPost(`/api/userprofile/delete-favorite`, payload, res => res);
  return response;
};
