import React from 'react';

const ResetIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
        <defs>
          <clipPath id="clip-path">
            <path
              id="Rectangle_862"
              fill="#fff"
              stroke="#707070"
              d="M0 0H24V24H0z"
              data-name="Rectangle 862"
              transform="translate(485 479)"
            />
          </clipPath>
        </defs>
        <g id="Mask_Group_55" clipPath="url(#clip-path)" data-name="Mask Group 55" transform="translate(-485 -479)">
          <path
            id="load"
            fill="#000002"
            d={`M4.67889 14.2382C4.80027 14.3867 4.99686 14.3867 
            5.11824 14.2382L7.73347 11.0384C7.85485 10.89 7.79768 10.7697 7.60596 10.7697H6.21735C6.02564 10.7697 5.89534 
            10.6161 5.93652 10.4289C6.65054 7.17714 9.55239 4.73558 13.0154 4.73558C17.0125 4.73558 20.2644 7.98748 20.2644 
            11.9846C20.2644 15.9818 17.0125 19.2336 13.0154 19.2336C12.5361 19.2336 12.1476 19.6222 12.1476 20.1014C12.1476 
            20.5806 12.5361 20.9692 13.0154 20.9692C17.9696 20.9692 22 16.9388 22 11.9846C22 7.03043 17.9695 3 13.0154 
            3C8.59273 3 4.90845 6.21287 4.1686 10.4268C4.1354 10.6157 3.95988 10.7697 3.76816 10.7697H2.19121C1.99946 
            10.7697 1.94243 10.89 2.06366 11.0385L4.67889 14.2382Z`}
            transform="translate(486 478)"
          />
        </g>
      </svg>
    </>
  );
};

export default ResetIcon;
