import { call, put, takeEvery } from 'redux-saga/effects';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';
import * as actions from './detailApartment.action';
import * as API from './detailApartment.api';
import * as types from './detailApartment.types';

export function* getBannerRequest(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getBannerRequest, getQueryString(params));
    yield put(actions.getBannerSuccess(data));
  } catch (error) {
    yield put(actions.getBannerFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getOverviewRequest(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getOverviewRequest, getQueryString(params));
    yield put(actions.getOverviewSuccess(data));
  } catch (error) {
    yield put(actions.getOverviewFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getAmenitiesRequest(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getAmenitiesRequest, getQueryString(params));
    yield put(actions.getAmenitiesSuccess(data));
  } catch (error) {
    yield put(actions.getAmenitiesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getLocationRequest(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getLocationRequest, getQueryString(params));
    yield put(actions.getLocationSuccess(data));
  } catch (error) {
    yield put(actions.getLocationFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getGalleryRequest(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getGalleryRequest, getQueryString(params));
    yield put(actions.getGallerySuccess(data));
  } catch (error) {
    yield put(actions.getGalleryFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getRatingRequest(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getRatingRequest, getQueryString(params));
    yield put(actions.getRatingSuccess(data));
  } catch (error) {
    yield put(actions.getRatingFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getReviewRequest(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getReviewRequest, getQueryString(params));
    yield put(actions.getReviewSuccess(data));
  } catch (error) {
    yield put(actions.getReviewFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* detailApartmentSaga() {
  yield takeEvery(types.GET_BANNER_REQUEST, getBannerRequest);
  yield takeEvery(types.GET_OVERVIEW_REQUEST, getOverviewRequest);
  yield takeEvery(types.GET_AMENITIES_REQUEST, getAmenitiesRequest);
  yield takeEvery(types.GET_LOCATION_REQUEST, getLocationRequest);
  yield takeEvery(types.GET_GALLERY_REQUEST, getGalleryRequest);
  yield takeEvery(types.GET_RATING_REQUEST, getRatingRequest);
  yield takeEvery(types.GET_REVIEW_REQUEST, getReviewRequest);
}
