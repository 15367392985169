import { get } from 'lodash';
import Service from '../../services';

export const fetchAboutUs = async params => {
  const response = await Service.isnGet(`/api/aboutus${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchBlogsHot = async params => {
  const response = await Service.isnGet(`/api/blogs/hot${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchEssentials = async params => {
  const response = await Service.isnGet(`/api/properties/essentials${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchFeaturedProjects = async params => {
  const response = await Service.isnGet(`/api/projects/hot-projects${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchServiced = async params => {
  const response = await Service.isnGet(`/api/apartment/list-apartment-hot${params}`, res => res);
  return response;
};