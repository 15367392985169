import * as types from './special.types';

const initialState = {
  data: null,
  specialName: '',
  error: null,
};

const specialReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SPECIAL_REQUEST:
      return { ...state };
    case types.FETCH_SPECIAL_SUCCESS: {
      const { data, specialName } = action.data;
      return { ...state, data, specialName };
    }
    case types.FETCH_SPECIAL_FAILURE:
      return { ...state, data: null, specialName: '', error: action.error };

    default:
      return state;
  }
};

export default specialReducer;
