import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Subtract } from '../../../assets/images/web-isn';
import { getPathLanguage } from '../../../utils/params';
// import * as actions from '../detailApartment.action';

function BreadcrumbApartmentComponent() {
  const { t } = useTranslation();
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const getHref = window.location.href;
  const { id, slug, typeCode } = useParams();
  const history = useHistory();
  const breadCumbParent = localStorage.getItem('breadCumbParent')
    ? localStorage.getItem('breadCumbParent')
    : '?pageIndex=1&pageSize=15&orderBy=desc&sortBy=0';

  const handleBreadOverview = () => {
    return (
      <li className="d-flex align-items-center text-nowrap mr-3">
        <Link
          className="child-item mr-3 text-truncate-breadcrumb-name"
          to={
            window.location.pathname.includes('/serviced-apartments/')
              ? `${pathLanguage}/serviced-apartments/overview/${id}/${slug}/${typeCode}`
              : window.location.pathname.includes('/offers/')
              ? `${pathLanguage}/offers/overview/${id}/${slug}/${typeCode}`
              : window.location.pathname.includes('/search-result/')
              ? `${pathLanguage}/offers/overview/${id}/${slug}/${typeCode}`
              : ''
          }
        >
          {localStorage.getItem('apartmentName')}
        </Link>
        <img src={Subtract} alt="subtract" />
      </li>
    );
  };

  let textBreadcrumb = '';
  if (location.pathname.includes('/overview')) {
    // textBreadcrumb = t('');
    textBreadcrumb = (
      <li className="d-flex align-items-center child-active mr-3 text-truncate-breadcrumb-name">
        {localStorage.getItem('apartmentName')}
      </li>
    );
  } else if (location.pathname.includes('/amenities/')) {
    textBreadcrumb = (
      <>
        {handleBreadOverview()}
        <li className="d-flex align-items-center child-active mr-3 text-truncate-breadcrumb-name">
          {t('detailApartment.amenities_title')}
        </li>
      </>
    );
  } else if (location.pathname.includes('/location/')) {
    textBreadcrumb = (
      <>
        {handleBreadOverview()}
        <li className="d-flex align-items-center child-active text-nowrap mr-3">
          {t('detailApartment.location_title')}
        </li>
      </>
    );
  } else if (location.pathname.includes('/gallery/')) {
    textBreadcrumb = (
      <>
        {handleBreadOverview()}
        <li className="d-flex align-items-center child-active text-nowrap mr-3">
          {t('detailApartment.gallery_title')}
        </li>
      </>
    );
  } else if (location.pathname.includes('/ratings-reviews/')) {
    textBreadcrumb = (
      <>
        {handleBreadOverview()}
        <li className="d-flex align-items-center child-active text-nowrap mr-3">
          {t('detailApartment.ratings_reviews_title')}
        </li>
      </>
    );
  } else if (location.pathname.includes('/offers/')) {
    textBreadcrumb = (
      <>
        {handleBreadOverview()}
        <li className="d-flex align-items-center child-active text-nowrap mr-3">{t('detailApartment.offers_title')}</li>
      </>
    );
  }

  const handleResultPage = () => {
    history.push(`${pathLanguage}/search-result`);
  };

  return (
    <div id="breadcrumbApartmentMenu">
      <div className="container">
        <div className="apartment-breadcrumb">
          <ul className="d-flex align-items-center">
            <li className="d-flex align-items-center mr-3 text-truncate-breadcrumb-name">
              <Link className="first-item mr-3" to={pathLanguage}>
                {t('detailApartment.home_label')}
              </Link>
              <img src={Subtract} alt="subtract" />
            </li>
            {getHref.includes('?home') ? (
              ''
            ) : (
              <li className="d-flex align-items-center mr-3  text-truncate-breadcrumb-name">
                {window.location.pathname.includes('/serviced-apartments/') ? (
                  <Link className="child-item mr-3" to={`${pathLanguage}/serviced-apartments${breadCumbParent}`}>
                    {t('detailApartment.serviced_apartment_label')}
                  </Link>
                ) : window.location.pathname.includes('/offers/') ? (
                  <Link className="child-item mr-3" to={`${pathLanguage}/offers`}>
                    {t('detailApartment.offers_title')}
                  </Link>
                ) : window.location.pathname.includes('/search-result/') ? (
                  <span
                    className="child-item mr-3"
                    // to={`${pathLanguage}/search-result`}
                    onClick={() => handleResultPage()}
                  >
                    {t('search_result.label_search_results')}
                  </span>
                ) : (
                  ''
                )}
                <img src={Subtract} alt="subtract" />
              </li>
            )}
            {/* {location.pathname.includes('/serviced-apartments/overview') ? (
              <li className="d-flex align-items-center child-active mr-3">{localStorage.getItem('apartmentName')}</li>
            ) : (
              ''
            )} */}

            {textBreadcrumb}
          </ul>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbApartmentComponent);
