import * as types from './forgotPassword.types';

export const fetchResetPasswordRequested = params => ({
  type: types.FETCH_RESET_PASSWORD_REQUESTED,
  params,
});
export const fetchResetPasswordSucceeded = data => ({
  type: types.FETCH_RESET_PASSWORD_SUCCEEDED,
  data,
});
export const fetchResetPasswordFailed = error => ({
  type: types.FETCH_RESET_PASSWORD_FAILED,
  error,
});

export const fetchVerifyKeyForgotRequested = params => ({
  type: types.FETCH_VERIFY_KEY_FORGOT_REQUESTED,
  params,
});
export const fetchVerifyKeyForgotSucceeded = data => ({
  type: types.FETCH_VERIFY_KEY_FORGOT_SUCCEEDED,
  data,
});
export const fetchVerifyKeyForgotFailed = error => ({
  type: types.FETCH_VERIFY_KEY_FORGOT_FAILED,
  error,
});

export const fetchNewPasswordRequested = params => ({
  type: types.FETCH_NEW_PASSWORD_REQUESTED,
  params,
});
export const fetchNewPasswordSucceeded = data => ({
  type: types.FETCH_NEW_PASSWORD_SUCCEEDED,
  data,
});
export const fetchNewPasswordFailed = error => ({
  type: types.FETCH_NEW_PASSWORD_FAILED,
  error,
});
