import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { useTranslation } from 'react-i18next';
import EventBus from 'eventing-bus';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ICON_LEFT, ICON_RIGHT } from '../../../utils/constants';
import { getPathLanguage } from '../../../utils/params';
import { Location, HeartIcon, SizeIcon, BedIcon } from '../../../layouts/commons/icons';
import { formatMoney } from '../../../utils/objects';
import favorite404 from '../../../assets/images/notfound-icon.svg';
import imageDefault from '../../../assets/images/loading-image.jpg';

const SpecialResutlsComponent = props => {
  const imageThumbnails = useRef(null);
  const pathLanguage = getPathLanguage(useLocation());
  const { t } = useTranslation();

  const options = {
    items: 1,
    margin: 0,
    nav: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    dots: true,
    autoplay: false,
    autoHeight: true,
    responsive: {},
    loop: true,
  };

  const residencesLike = localStorage.getItem('RESIDENCES_LIKE')
    ? localStorage.getItem('RESIDENCES_LIKE').split(',')
    : [];
  const officesLike = localStorage.getItem('OFFICES_LIKE') ? localStorage.getItem('OFFICES_LIKE').split(',') : [];

  const [likeData, setLikeData] = useState([]);

  useEffect(() => {
    setLikeData([...residencesLike, ...officesLike]);
    // eslint-disable-next-line
  }, []);

  const onClickLike = (id, propertyType) => {
    let idsResidence = localStorage.getItem('RESIDENCES_LIKE')
      ? localStorage.getItem('RESIDENCES_LIKE').split(',')
      : [];
    let idsOffice = localStorage.getItem('OFFICES_LIKE') ? localStorage.getItem('OFFICES_LIKE').split(',') : [];

    if (propertyType === 'residence') {
      if (idsResidence && idsResidence.includes(id)) idsResidence = idsResidence.filter(i => i !== id);
      else idsResidence = [...idsResidence, id];

      localStorage.setItem('RESIDENCES_LIKE', idsResidence);
    } else if (propertyType === 'office') {
      if (idsOffice && idsOffice.includes(id)) idsOffice = idsOffice.filter(i => i !== id);
      else idsOffice = [...idsOffice, id];

      localStorage.setItem('OFFICES_LIKE', idsOffice);
    }

    setLikeData([...idsResidence, ...idsOffice]);

    EventBus.publish('RESET_LIKE', true);
  };

  return (
    <div className="section-block background-gray pt-0">
      <div className="container">
        <div className="seach-results">
          <div className="title-special-list">{props.title}</div>
          <div className="row">
            {props.data && props.data.length
              ? props.data.map((item, index) => {
                  if (item.name) item.name = item.name.replace('　', ' ');
                  return (
                    <div key={index} className={'col-12 col-md-4 mb-4'}>
                      {item.propertyType === 'residence' ? (
                        <div className="block-inner favorite search element-carousel">
                          <div className="block-image">
                            {item.images && item.images.length ? (
                              <div className="thumbail-slides">
                                <OwlCarousel ref={imageThumbnails} options={options}>
                                  {item.images.map((image, i) => (
                                    <Link
                                      to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}
                                      key={i}
                                      className="w-100"
                                    >
                                      <div>
                                        {/* <LazyLoadImage
                                          alt=""
                                          src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${image}`}
                                          effect="blur"
                                        /> */}
                                        <img
                                          alt=""
                                          src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${image}`}
                                          className="w-100"
                                        />
                                      </div>
                                    </Link>
                                  ))}
                                </OwlCarousel>
                              </div>
                            ) : (
                              !props.loading && (
                                <div className="w-100">
                                  <img src={imageDefault} className="favourite-notfound" alt="" />
                                  {/* <LazyLoadImage src={imageDefault}
                                className="favourite-notfound" alt="" effect="blur" /> */}
                                </div>
                              )
                            )}
                            <span
                              onClick={() => onClickLike(item.id, item.propertyType)}
                              className={`heart circle ${likeData.includes(item.id) ? 'active-like' : ''}`}
                            >
                              <HeartIcon />
                            </span>
                          </div>
                          <Link className="w-100" to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}>
                            <div className="block-text none-split">
                              <h4>{item.name || ''}</h4>
                              <div className="property-info">
                                <ul>
                                  <li className="icon-district">
                                    {item.district ? (
                                      <>
                                        <Location />
                                        {item.district}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </li>
                                  <li className="bedroom-icon-detail icon-bedroom">
                                    <BedIcon />
                                    {item.bedroommin ? item.bedroommin : t('home_page.studio')}
                                    {item.bedroomMax ? ` ~ ${item.bedroomMax}` : ''}
                                    <span>&nbsp;{t('search_page.bedrooms')}</span>
                                  </li>
                                </ul>
                                <div className="info-residence">
                                  {item.leasePriceMin || item.leasePriceMax ? (
                                    <div className="price">
                                      {item.leasePriceMin || item.leasePriceMax
                                        ? `$${formatMoney(item.leasePriceMin, 0, 0)}`
                                        : ''}
                                      {item.leasePriceMax ? ` ~ $${formatMoney(item.leasePriceMax, 0, 0)}` : ''}
                                      {item.leasePriceMin || item.leasePriceMax ? (
                                        <span>/{t('search_page.month')}</span>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="size">
                                    {item.sqmmin || item.sqmMax ? <SizeIcon /> : ''}
                                    {item.sqmmin || item.sqmMax ? formatMoney(item.sqmmin, 0, 0) : ''}
                                    {item.sqmMax ? ' ~ ' : ''}
                                    {item.sqmMax ? formatMoney(item.sqmMax, 0, 0) : ''}
                                    {item.sqmmin || item.sqmMax ? (
                                      <>
                                        m<sup>2</sup>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <div className="block-inner">
                          <div className="block-image">
                            <div className="thumbail-slides">
                              {item.images && item.images.length ? (
                                <Link
                                  to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}
                                  className="w-100"
                                >
                                  <img
                                    alt=""
                                    src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${item.images[0]}`}
                                    className="w-100"
                                  />
                                  {/* <LazyLoadImage
                                  alt=""
                                  src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${item.images[0]}`}
                                  effect="blur"
                                /> */}
                                </Link>
                              ) : (
                                <Link to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}>
                                  <img alt="" src={imageDefault} />
                                  {/* <LazyLoadImage alt="" src={imageDefault} effect="blur" /> */}
                                </Link>
                              )}
                            </div>
                            <span
                              onClick={() => onClickLike(item.id, item.propertyType)}
                              className={`heart circle ${likeData.includes(item.id) ? 'active-like' : ''}`}
                            >
                              <HeartIcon />
                            </span>
                          </div>
                          <Link className="w-100" to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}>
                            <div className="block-text none-split">
                              <h4>{item.name || ''}</h4>
                              <div className="property-info">
                                <ul>
                                  <li className="icon-district">
                                    {item.district ? (
                                      <>
                                        <Location />
                                        {item.district}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </li>
                                  <li className="icon-size">
                                    {item.sqmmin || item.sqmMax ? <SizeIcon /> : ''}
                                    {item.sqmmin || item.sqmMax ? formatMoney(item.sqmmin, 0, 0) : ''}
                                    {item.sqmMax ? ' ~ ' : ''}
                                    {item.sqmMax ? formatMoney(item.sqmMax, 0, 0) : ''}
                                    {item.sqmmin || item.sqmMax ? (
                                      <>
                                        m<sup>2</sup>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </li>
                                </ul>
                                <div className="price">
                                  {item.leasePriceMin || item.leasePriceMax
                                    ? `$${formatMoney(item.leasePriceMin, 0, 0)}`
                                    : ''}
                                  {item.leasePriceMax ? ` ~ $${formatMoney(item.leasePriceMax, 0, 0)}` : ''}
                                  {item.leasePriceMin || item.leasePriceMax ? (
                                    <span>
                                      /m<sup>2</sup>/{t('search_page.month')}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                })
              : !props.loading && (
                  <div className="image-notfound">
                    <div className="not-found-message"> {t('search_page.not_found')}</div>
                    <img src={favorite404} className="favourite-notfound" alt="" />
                    {/* <LazyLoadImage src={favorite404} className="favourite-notfound"
                    alt="" effect="blur" /> */}
                  </div>
                )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialResutlsComponent;
