import { get } from 'lodash';
import Service from '../../services';

export const fetchLanguages = async () => {
  const response = await Service.isnGet(`/api/language/list-language`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchCities = async params => {
  const response = await Service.isnGet(`/api/cities/list-cities${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchDistrictsByCityId = async params => {
  const response = await Service.isnGet(`/api/districts/get-by-city${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchCurrency = async () => {
  const response = await Service.isnGet(`/api/filters/list-currency`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchProjects = async params => {
  const response = await Service.isnGet(`/api/projects/list-project${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getBannerHomePage = async params => {
  const response = await Service.isnGet(`/api/banners${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchProjectsSearch = async params => {
  const response = await Service.isnGet(`/api/projects/get-by-city-district${params}`, res => res);
  return response;
};

export const fetchListCondition = async params => {
  const response = await Service.isnGet(`/api/filters${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchRangeSetting = async () => {
  const response = await Service.isnGet(`/api/filters/range-setting`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchCustomerContact = async params => {
  const response = await Service.isnGet(`/api/customersupports${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

// export const getUnits = async params => {
//   const response = await Service.isnGet(`/api/properties/total-unit${params}`, res => res);
//   const data = get(response, 'message', null);
//   return data;
// };

// export const fetchCustomerContact = async params => {
//   const response = await Service.isnGet(`api/customersupports${params}`, res => res);
//   const data = get(response, 'data', null);
//   return data;
// };

// export const getOfficeInformation = async params => {
//   const response = await Service.isnGet(`/api/generalinformations/get-contact-us-no-long-lat${params}`, res => res);
//   const data = get(response, 'data', null);
//   return data;
// };

// export const downloadMagazine = async params => {
//   const response = await Service.isnGet(`/api/magazine/download-pdf${params}`, res => res);
//   return response;
// };

// export const fetchRange = async () => {
//   const response = await Service.isnGet(`api/filters/range-setting`, res => res);
//   const data = get(response, 'data', null);
//   return data;
// };

// export const getPartner = async () => {
//   const response = await Service.isnGet(`api/partners`, res => res);
//   const data = get(response, 'data', null);
//   return data;
// };
