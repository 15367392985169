import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './home.action';
import * as types from './home.types';
import * as API from './home.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* fetchFeaturedProjects(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchFeaturedProjects, getQueryString(params));
    yield put(actions.fetchFeaturedProjectsSuccess({ data }));
  } catch (error) {
    yield put(actions.fetchFeaturedProjectsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchBlogsHotSaga(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchBlogsHot, getQueryString(params));
    yield put(actions.fetchBlogsHotSuccess(data));
  } catch (error) {
    yield put(actions.fetchBlogsHotFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchAboutUs(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchAboutUs, getQueryString(params));
    yield put(actions.fetchAboutUsSuccess(data));
  } catch (error) {
    yield put(actions.fetchAboutUsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchEssentials(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchEssentials, getQueryString(params));
    yield put(actions.fetchEssentialsSuccess(data));
  } catch (error) {
    yield put(actions.fetchEssentialsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchServiced(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchServiced, getQueryString(params));
    yield put(actions.fetchServicedSuccess({ data }));
  } catch (error) {
    yield put(actions.fetchServicedFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* homeSaga() {
  yield takeEvery(types.FETCH_FEATURED_PROJECTS_REQUEST, fetchFeaturedProjects);
  yield takeEvery(types.FETCH_ESSENTIALS_REQUEST, fetchEssentials);
  yield takeEvery(types.FETCH_ABOUT_US_REQUEST, fetchAboutUs);
  yield takeEvery(types.FETCH_BLOGS_HOT_REQUEST, fetchBlogsHotSaga);
  yield takeEvery(types.FETCH_SERVICED_REQUEST, fetchServiced);
}
