import { put, takeEvery, call, all } from 'redux-saga/effects';
import * as actions from './special.action';
import * as types from './special.types';
import * as API from './special.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* fetchSpecial(action) {
  const { params } = action;

  try {
    yield put(setLoading(true));
    const [data, specialName] = yield all([
      call(API.fetchSpecial, getQueryString(params)),
      call(API.getSpecialName, getQueryString(params)),
    ]);
    yield put(actions.fetchSpecialSuccess({ data, specialName }));
  } catch (error) {
    yield put(actions.fetchSpecialFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* specialSaga() {
  yield takeEvery(types.FETCH_SPECIAL_REQUEST, fetchSpecial);
}
