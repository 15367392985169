import ForgotModalComponent from 'modules/forgotPassword/pages/ForgotModal.component';
import { LoginComponent } from 'modules/loginPage/pages';
import { VerifyEmailComponent } from 'modules/signUp/pages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { BannerApartment, BreadcumbApartment, MenuApartment } from '../common';
import * as actions from '../detailApartment.action';
import { ContactUs } from '../pages';

const AmenitiesComponent = props => {
  const { id, typeCode } = useParams();
  const { t } = useTranslation();
  const { language } = useParams();
  const { amenitiesInfo } = props.detailApartmentData;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const history = useHistory();
  const [apartmentId, setApartmentId] = useState(null);
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    showLoader();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    props.getAmenitiesRequest({ language, commonId: id });
    //eslint-disable-next-line
  }, []);

  const handleAmenities = () => {
    hideLoader();
    if (Object.keys(amenitiesInfo).length !== 0 && amenitiesInfo.facilities !== null) {
      return (
        <>
          <div className="detail-apartment-title">{amenitiesInfo.name}</div>
          <div className="apartment-amenities__body">
            {amenitiesInfo.facilities.map((item, index) => {
              return (
                <div key={index} className="apartment-amenities__body--title">
                  {item.name.toUpperCase()}
                  <ul className="row mt-2">
                    {item.items
                      .sort(({ id: a }, { id: b }) => a - b)
                      .map((item2, index2) => {
                        return (
                          <li
                            key={index2}
                            className={
                              item2.isShow
                                ? 'col-12 col-sm-4 d-flex align-items-center list-services'
                                : 'col-12 col-sm-4 align-items-center list-services service-disabled'
                            }
                          >
                            <img
                              className="pr-3"
                              src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item2.imageUrl}`}
                              alt=""
                            />
                            {item2.name}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
          </div>
        </>
      );
    }
  };

  const handleShowLogin = () => {
    setShowLogin(true);
    setShowForgot(false);
  };

  const handleShowForgot = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleShowSignUp = () => {
    setShowLogin(false);
    setShowVerifyPopup(true);
  };
  const closeVerifyEmailPopup = () => {
    setShowVerifyPopup(false);
  };

  const handleButtonBook = () => {
    if (typeCode === 'long') {
      setShowContact(true);
    } else {
      const authUserData = JSON.parse(localStorage.getItem('authUserData'));
      if (authUserData) {
        history.push(`/${language}/payment-summary/${apartmentId}`);
      } else {
        handleShowLogin();
      }
    }
  };

  const getApartmentId = apartmentId => {
    setApartmentId(apartmentId);
  };

  return (
    <>
      <BreadcumbApartment />
      <BannerApartment />
      <MenuApartment getApartmentId={getApartmentId} />
      <ContactUs
        showLogin={showContact}
        closeLogin={() => {
          setShowContact(false);
        }}
      />
      <div id="detailApartmentAmenities">
        <div className="container">
          <div className="apartment-amenities">
            {handleAmenities()}
            {typeCode === 'long' ?
              <div
              style={{
              marginTop: `${
                Object.keys(amenitiesInfo).length !== 0 && amenitiesInfo.facilities !== null ? '' : '143px'
              }`,
            }}
              className="d-flex justify-content-center mt-5"
              >
              <p>{t('detailApartment.contact_us_note')}</p>
              </div> : ''
            }
            <div
              style={{
                marginTop: `${
                  Object.keys(amenitiesInfo).length !== 0 && amenitiesInfo.facilities !== null ? '' : '143px'
                }`,
              }}
              className="d-flex justify-content-center mg-top-bottom"
            >
              <input
                className="btn-booknow"
                type="button"
                onClick={handleButtonBook}
                value={
                  typeCode === 'long' ? t('detailApartment.contact_us_title') : t('detailApartment.book_now_title')
                }
              />
            </div>
          </div>
        </div>
      </div>
      <LoginComponent
        closeLogin={() => {
          setShowLogin(false);
        }}
        showLogin={showLogin}
        handleShowForgot={handleShowForgot}
        handleShowSignUp={handleShowSignUp}
      />
      <VerifyEmailComponent
        showVerifyEmail={showVerifyPopup}
        hideVerifyEmail={closeVerifyEmailPopup}
      ></VerifyEmailComponent>
      <ForgotModalComponent
        showModal={showForgot}
        hideModal={() => setShowForgot(false)}
        handleShowLogin={handleShowLogin}
      />
      {loader}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAmenitiesRequest: actions.getAmenitiesRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AmenitiesComponent);
