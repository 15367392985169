import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../forgotPassword.action';
import { Card, Alert } from 'react-bootstrap';
import ResetCommon from '../common/Reset.component';
import { useHistory, useLocation } from 'react-router-dom';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { Expried } from '../../../assets/images/web-isn';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../../utils/params';

const EmailURLComponent = props => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const history = useHistory();
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');

  const [reEnterPassword, setReEnterPassword] = useState('');
  const [reEnterPasswordErr, setReEnterPasswordErr] = useState(false);
  const [reEnterPasswordMessage, setReEnterPasswordMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showExpriedPage, setShowExpriedPage] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const forgotKey = encodeURIComponent(params.get('verify-key'));

  const location = useLocation();
  const language = getLanguage(location);

  useEffect(() => {
    let key = {
      code: forgotKey,
    };
    props.fetchVerifyKeyForgotRequested({ key, verifySuccess, verifyError });
    showLoader();

    // eslint-disable-next-line
  }, []);

  const verifySuccess = () => {
    setShowExpriedPage(false);
    hideLoader();
  };

  const verifyError = error => {
    if (error === 400) {
      setShowExpriedPage(true);
    }
    hideLoader();
  };

  const handleToken = (token, dataInfo) => {
    const authData = {
      rememberMe: false,
      token: {
        access_token: token.access_token,
        refresh_token: token.refresh_token,
      },
      userInfo: dataInfo,
    };
    localStorage.setItem('authUserData', JSON.stringify(authData));
    history.push(`/${language}/`);
    hideLoader();
  };

  const handlePassword = value => {
    const regexPwd = /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,48}$/;
    if (value === '') {
      setPasswordErr(true);
      setPasswordMessage(t('forgot_password_isn.msg_new_password_required'));
    } else if (!regexPwd.test(value)) {
      setPasswordErr(true);
      setPasswordMessage(t('forgot_password_isn.msg_password_format'));
    } else {
      setPasswordErr(false);
      setPasswordMessage('');
    }
    setPassword(value);
  };

  const handleCheckPassword = reEnterValue => {
    if (reEnterValue === '') {
      setReEnterPasswordErr(true);
      setReEnterPasswordMessage(t('forgot_password_isn.msg_re_enter_password_required'));
    } else if (reEnterValue !== password) {
      setReEnterPasswordErr(true);
      setReEnterPasswordMessage(t('forgot_password_isn.msg_password_mismatch'));
    } else {
      setReEnterPasswordErr(false);
      setReEnterPasswordMessage('');
    }
    setReEnterPassword(reEnterValue);
  };

  const handleSubmitPassword = async e => {
    e.preventDefault();
    let info = JSON.stringify({
      password: password,
      verifyKey: forgotKey,
    });
    await props.fetchNewPasswordRequested({ info, handleToken, handleError });
    showLoader();
    setPassword('');
    setReEnterPassword('');
  };

  const handleError = error => {
    if (error === 400) {
      setSuccessMessage('');
      setErrorMessage(t('forgot_password_isn.msg_password_similar'));
    }
    hideLoader();
  };

  const disableButtonReset = () => {
    if (password === '' || reEnterPassword === '' || passwordMessage !== '' || reEnterPasswordMessage !== '') {
      return true;
    } else {
      return false;
    }
  };

  const redirectHome = () => {
    history.push(`/${language}/`);
  };

  const expriedPage = () => {
    return (
      <React.Fragment>
        <div className="expried-page px-3">
          <div className="items-expried d-flex flex-column align-items-center">
            <div className="expried-img">
              <img src={Expried} alt="expried-page"></img>
            </div>
            <p className="title-expried mt-3 text-center">{t('forgot_password_isn.label_URL_expired')}</p>
            <p className="text-expried">{t('forgot_password_isn.msg_URL_expired')}</p>
            <button className="button-expried" onClick={redirectHome}>
              {t('forgot_password_isn.btn_go_home_page')}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const resetPage = () => {
    return (
      <React.Fragment>
        <div id="forgotPassword">
          <div className="reset-password-form">
            <Card className="card">
              <form className="reset" onSubmit={handleSubmitPassword}>
                <h2 className="title text-center">{t('forgot_password_isn.reset_title')}</h2>
                {errorMessage && <Alert className="message-err mt-3 mb-0">{errorMessage}</Alert>}
                {successMessage && <Alert className="message-succ mt-3 mb-0">{successMessage}</Alert>}
                <ResetCommon
                  title={t('forgot_password_isn.label_new_password')}
                  type="password"
                  mask="1"
                  maxLength="48"
                  err={passwordErr}
                  message={passwordMessage}
                  handleInput={handlePassword}
                />
                <ResetCommon
                  title={t('forgot_password_isn.label_re_enter_password')}
                  type="password"
                  mask="1"
                  maxLength="48"
                  err={reEnterPasswordErr}
                  message={reEnterPasswordMessage}
                  handleInput={handleCheckPassword}
                />
                <button type="submit" className="save-button mt-4 align-self-center" disabled={disableButtonReset()}>
                  {t('forgot_password_isn.btn_save')}
                </button>
              </form>
            </Card>
          </div>
          {loader}
        </div>
      </React.Fragment>
    );
  };

  return <>{showExpriedPage === true ? expriedPage() : resetPage()}</>;
};

const mapStatetoProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchVerifyKeyForgotRequested: actions.fetchVerifyKeyForgotRequested,
      fetchNewPasswordRequested: actions.fetchNewPasswordRequested,
    },
    dispatch,
  );

export default connect(mapStatetoProps, mapDispatchToProps)(EmailURLComponent);
