const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE';

const GET_OVERVIEW_REQUEST = 'GET_OVERVIEW_REQUEST';
const GET_OVERVIEW_SUCCESS = 'GET_OVERVIEW_SUCCESS';
const GET_OVERVIEW_FAILURE = 'GET_OVERVIEW_FAILURE';

const GET_AMENITIES_REQUEST = 'GET_AMENITIES_REQUEST';
const GET_AMENITIES_SUCCESS = 'GET_AMENITIES_SUCCESS';
const GET_AMENITIES_FAILURE = 'GET_AMENITIES_FAILURE';

const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';

const GET_GALLERY_REQUEST = 'GET_GALLERY_REQUEST';
const GET_GALLERY_SUCCESS = 'GET_GALLERY_SUCCESS';
const GET_GALLERY_FAILURE = 'GET_GALLERY_FAILURE';

const GET_RATING_REQUEST = 'GET_RATING_REQUEST';
const GET_RATING_SUCCESS = 'GET_RATING_SUCCESS';
const GET_RATING_FAILURE = 'GET_RATING_FAILURE';

const GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST';
const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
const GET_REVIEW_FAILURE = 'GET_REVIEW_FAILURE';

export {
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAILURE,
  GET_OVERVIEW_REQUEST,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_FAILURE,
  GET_AMENITIES_REQUEST,
  GET_AMENITIES_SUCCESS,
  GET_AMENITIES_FAILURE,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  GET_GALLERY_REQUEST,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FAILURE,
  GET_RATING_REQUEST,
  GET_RATING_SUCCESS,
  GET_RATING_FAILURE,
  GET_REVIEW_REQUEST,
  GET_REVIEW_SUCCESS,
  GET_REVIEW_FAILURE,
};
