import Service from '../../services';

export const fetchResetPasswordRequested = async params => {
  const response = await Service.isnPost(`/api/user/forgot-password`, params, res => res);
  return response;
};

export const fetchVerifyKeyForgotRequested = async params => {
  const response = await Service.isnPost('/api/user/verify-key-forgot', params, res => res);
  return response;
};

export const fetchNewPasswordRequested = async params => {
  const response = await Service.isnPost('/api/user/new-password', params, res => res);
  return response;
};
