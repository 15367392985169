import login from './login.json';
import forgotPassword from './forgotPassword.json';
import signup from './signup.json';
import home from './home.json';
import serviceApartment from './serviceApartment.json';
import footer from './footer.json';
import detailApartment from './detailApartment.json';
import preparation from './preparation.json';
import offers from './offers.json';
import searchResult from './searchResult.json';
import featuredProjects from './featuredProjects.json';
import detailProject from './detailProject.json';
import servicedOffice from './servicedOffice.json';
import noResultFound from './noResultFound.json';
import blogs from './blogs.json';
import forOwners from './forOwners.json';
import helps from './helps.json';
import usefulPage from './usefulPage.json';
import aboutUs from './aboutUs.json';
import validate from './validate.json';
import title from './title.json';
import payment from './payment.json';
import location from './location.json';
import manageAccount from './manageAccount.json';

const vi = {
  ...forgotPassword,
  ...login,
  ...signup,
  ...home,
  ...serviceApartment,
  ...footer,
  ...detailApartment,
  ...blogs,
  ...title,
  ...validate,
  ...forOwners,
  ...helps,
  ...preparation,
  ...offers,
  ...searchResult,
  ...featuredProjects,
  ...detailProject,
  ...servicedOffice,
  ...noResultFound,
  ...usefulPage,
  ...aboutUs,
  ...payment,
  ...location,
  ...manageAccount,
};

export { vi };
