import ForgotModalComponent from 'modules/forgotPassword/pages/ForgotModal.component';
import { LoginComponent } from 'modules/loginPage/pages';
import { VerifyEmailComponent } from 'modules/signUp/pages';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { BannerApartment, BreadcumbApartment, MenuApartment } from '../common';
import * as actions from '../detailApartment.action';
import { getApartmentDetail, getOverviewRequest } from '../detailApartment.api';
import { getQueryString } from 'utils/params';
import OwlCarousel from 'react-owl-carousel2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ICON_LEFT, ICON_RIGHT } from '../../../utils/constants';
import { formatMoney } from '../../../utils/objects';
import { RedMapPin, Bedrooms, Area, Flag } from '../../../assets/images/web-isn';
import { ContactUs } from '../pages';

function OverviewComponent(props) {
  const { t } = useTranslation();
  const { language } = useParams();
  const [overviewInfo, setOverviewInfo] = useState(null);
  const { id, typeCode } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const history = useHistory();
  const [apartmentId, setApartmentId] = useState(null);
  const [apartmentInfo, setApartmentInfo] = useState(null);
  const { currentCurrency } = props.layout;
  const [showContact, setShowContact] = useState(false);
  const imageThumbnails = useRef(null);

  //Owl carousel options
  const option = {
    items: 1,
    autoplay: false,
    loop: false,
    dots: true,
    nav: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    autoHeight: true,
    responsive: {},
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    center: true,
  };

  useEffect(() => {
    showLoader();
    Promise.all([getOverviewRequest(getQueryString({ language, commonId: id }))]).then(values => {
      if (values) {
        setOverviewInfo(values[0] ?? null);
      }
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    hideLoader();
    //eslint-disable-next-line
  }, []);

  function createMarkup() {
    if (overviewInfo) {
      return { __html: overviewInfo.content };
    }
  }

  const handleOverview = () => {
    if (overviewInfo && apartmentInfo) {
      return (
        <div className="apartment-overview">
          <div className="row d-block d-md-flex">
            <div className="col-12 col-md-7">
              <div className="apartment-overview__title">{overviewInfo.name}</div>
              <div className="apartment-overview__description" dangerouslySetInnerHTML={createMarkup()}></div>
            </div>
            <div className="col-12 col-md-5 mb-5 mb-md-0">
              <div className="project-info justify-content-center align-items-center mt-3">
                <div className="container-slider">
                  <OwlCarousel ref={imageThumbnails} options={option}>
                    {apartmentInfo?.images?.map((item, i) => (
                      <div className="slide" key={i}>
                        <LazyLoadImage src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item.url}`} alt="" effect="blur" />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
                <div className="content" style={{ height: 220 }}>
                  <div className="project-name text-truncate-box-title-name ml-1">{apartmentInfo.name}</div>
                  {apartmentInfo.address ? (
                    <div className="mt-2">
                      <div className="d-flex align-items-start">
                        <img src={Flag} alt="location" />
                        <div className="project-location text-truncate-box-title-name mr-3">
                          {apartmentInfo.address}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="apartment-infomation d-flex mt-3 flex-column" style={{ height: 80 }}>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img src={RedMapPin} alt="location" />
                        <div className="project-location mr-3">{apartmentInfo.district}</div>
                      </div>
                      {apartmentInfo.bedRoomName ? (
                        <div className="bedroom-size d-flex">
                          <img src={Bedrooms} alt="bedrooms" />
                          <div className="project-bed">
                            <span>&nbsp;{apartmentInfo.bedRoomName}</span>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="mt-2">
                        <div className="project-price">
                          <span className="focus-price">
                            {currentCurrency?.code === 'USD' &&
                            apartmentInfo.priceOffer &&
                            apartmentInfo.isOffer === true
                              ? `${currentCurrency?.symbol}${formatMoney(apartmentInfo.priceOffer, 0, 0)}`
                              : ''}
                            {currentCurrency?.code === 'USD' &&
                            apartmentInfo.price &&
                            (apartmentInfo.isOffer === false || (apartmentInfo.isOffer && !apartmentInfo.priceOffer))
                              ? `${currentCurrency?.symbol}${formatMoney(apartmentInfo.price, 0, 0)}`
                              : ''}
                            {currentCurrency?.code === 'VND' &&
                            apartmentInfo.priceOfferVi &&
                            apartmentInfo.isOffer === true
                              ? `${formatMoney(apartmentInfo.priceOfferVi, 0, 0)}${currentCurrency?.symbol}`
                              : ''}
                            {currentCurrency?.code === 'VND' &&
                            apartmentInfo.priceVi &&
                            (apartmentInfo.isOffer === false || (apartmentInfo.isOffer && !apartmentInfo.priceOfferVi))
                              ? `${formatMoney(apartmentInfo.priceVi, 0, 0)}${currentCurrency?.symbol}`
                              : ''}
                          </span>
                          /
                          {apartmentInfo.typeCode === 'long' ? (
                            <span>{t('service_apartment_isn.label_month')}</span>
                          ) : (
                            <span>{t('service_apartment_isn.label_day')}</span>
                          )}
                        </div>
                        {apartmentInfo.priceOffer > 0 && apartmentInfo.priceOfferVi > 0 && (
                          <div className="project-price" style={{ color: '#6b6b6b' }}>
                            <span className="focus-price" style={{ textDecoration: 'line-through' }}>
                              {currentCurrency?.code === 'USD' &&
                              apartmentInfo.isOffer === true &&
                              apartmentInfo.priceOffer > 0
                                ? `${currentCurrency?.symbol}${formatMoney(apartmentInfo.price, 0, 0)}`
                                : ''}
                              {currentCurrency?.code === 'VND' &&
                              apartmentInfo.isOffer === true &&
                              apartmentInfo.priceOfferVi > 0
                                ? `${formatMoney(apartmentInfo.priceVi, 0, 0)}${currentCurrency?.symbol}`
                                : ''}
                            </span>
                            {apartmentInfo.typeCode === 'long' &&
                            apartmentInfo.priceOffer > 0 &&
                            apartmentInfo.priceOfferVi > 0 ? (
                              <span style={{ textDecoration: 'line-through' }}>/{t('offers.label_month')}</span>
                            ) : (
                              ''
                            )}
                            {apartmentInfo.typeCode === 'short' &&
                            apartmentInfo.priceOffer > 0 &&
                            apartmentInfo.priceOfferVi > 0 ? (
                              <span style={{ textDecoration: 'line-through' }}>/{t('offers.label_day')}</span>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <img src={Area} alt="area" />
                        <div className="project-area">
                          {apartmentInfo.size ? `${apartmentInfo.size}` : ''}
                            m<sup>2</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleShowLogin = () => {
    setShowLogin(true);
    setShowForgot(false);
  };

  const handleShowForgot = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleShowSignUp = () => {
    setShowLogin(false);
    setShowVerifyPopup(true);
  };
  const closeVerifyEmailPopup = () => {
    setShowVerifyPopup(false);
  };

  const handleButtonBook = () => {
    if (typeCode === 'long') {
      setShowContact(true);
    } else {
      const authUserData = JSON.parse(localStorage.getItem('authUserData'));
      if (authUserData) {
        history.push(`/${language}/payment-summary/${apartmentId}`);
      } else {
        handleShowLogin();
      }
    }
  };

  const getApartmentId1 = apartmentId => {
    if (!isNaN(apartmentId)) {
      setApartmentId(apartmentId);
      getApartmentDetail(getQueryString({ language, apartmentId: apartmentId })).then(res => {
        if (res) {
          setApartmentInfo(res);
        }
      });
    }
  };

  return (
    <>
      <BreadcumbApartment />
      <BannerApartment />
      <MenuApartment getApartmentId={getApartmentId1} />
      <ContactUs
        showLogin={showContact}
        closeLogin={() => {
          setShowContact(false);
        }}
      />
      <div id="detailApartmentOverview">
        <div className="container">
          {handleOverview()}
          {typeCode === 'long' ?
            <div
              style={{ marginTop: `${overviewInfo ? '' : '143px'}` }}
              className="d-flex justify-content-center"
            >
              <p>{t('detailApartment.contact_us_note')}</p>
            </div> : ''
          }
          <div
            style={{ marginTop: `${overviewInfo ? '' : '143px'}` }}
            className="d-flex justify-content-center mg-top-bottom"
          >
            <input
              className="btn-booknow"
              type="button"
              onClick={handleButtonBook}
              value={typeCode === 'long' ? t('detailApartment.contact_us_title') : t('detailApartment.book_now_title')}
            />
          </div>
        </div>
      </div>
      <LoginComponent
        closeLogin={() => {
          setShowLogin(false);
        }}
        showLogin={showLogin}
        handleShowForgot={handleShowForgot}
        handleShowSignUp={handleShowSignUp}
      />
      <VerifyEmailComponent
        showVerifyEmail={showVerifyPopup}
        hideVerifyEmail={closeVerifyEmailPopup}
      ></VerifyEmailComponent>
      <ForgotModalComponent
        showModal={showForgot}
        hideModal={() => setShowForgot(false)}
        handleShowLogin={handleShowLogin}
      />
      {loader}
    </>
  );
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOverviewRequest: actions.getOverviewRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OverviewComponent);
