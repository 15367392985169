import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbForOwners, HeaderForOwners, FormOwnerInfomation } from '../common';

import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { getBanner } from 'modules/forOwnersPage/forOwners.api';
import { useLocation } from 'react-router-dom';
import { getLanguage, getQueryString } from '../../../utils/params';

const FormCreateComponent = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const breadCrumb = [
    { title: t('for_owners.home'), url: '/' },
    { title: t('for_owners.for_owners'), url: '/for-owners' },
    { title: t('for_owners.create_your_property'), url: '/for-owners/create-your-property' },
  ];
  const [headerContent, setheaderContent] = useState('');


  useEffect(() => {
    showLoader();
    getBanner(getQueryString({ language, categoryId: 1 })).then(res => {
      setheaderContent(res)
      hideLoader();
    })
    //eslint-disable-next-line
  }, [language]);

  return (
    <>
      <BreadcrumbForOwners breadCrumb={breadCrumb} />
      <HeaderForOwners content={headerContent} />

      <div className="section-block background-gray section-padding-top">
        <div className="container">
          <div className="post-content-detail page-for-owners">
            <div className="row warning-message">
              <div className="col-12 text-center">
                <span className="link-brown-bold">
                  {t('for_owners.owner_register.fill_form')}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <FormOwnerInfomation showLoader={showLoader} hideLoader={hideLoader}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default FormCreateComponent;
