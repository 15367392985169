import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Modal } from 'react-bootstrap';
import { ISN, Facebook, Close } from '../../../assets/images/web-isn';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import * as actions from '../signUp.action';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { useTranslation } from 'react-i18next';

const VerifyEmailComponent = props => {
  const { t } = useTranslation();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorServer, setErrorServer] = useState('');
  const [successServer, setSuccessServer] = useState('');
  const { showVerifyEmail, hideVerifyEmail } = props;

  const validateEmail = e => {
    let emailInput = e.target.value.trim();
    let regexEmail = /^([0-9a-zA-Z]([+\-_.][0-9a-zA-Z])*)+@([a-zA-Z_]+?\.)+[a-zA-Z]{2,4}$/;
    if (emailInput.length > 255) {
      emailInput = emailInput.slice(0, 255);
    }
    setEmail(emailInput);
    if (emailInput === '' || emailInput.length === 0) {
      setErrorMessage(t('signup_isn.verify_email.email_required'));
    } else if (regexEmail.test(emailInput)) {
      setErrorMessage('');
    } else {
      setErrorMessage(t('signup_isn.verify_email.email_invalid'));
    }
  };

  const verifyEmail = e => {
    e.preventDefault();
    var info = {
      email: email,
      language: localStorage.getItem('language'),
    };
    props.fetchVerifyEmailRequested({ info, onSuccess, onError });
    showLoader();
    setEmail('');
  };

  const onError = error => {
    hideLoader();
    console.log('error: ', error);
  };

  const onSuccess = isExist => {
    hideLoader();
    if (isExist === 1) {
      setErrorServer(t('signup_isn.verify_email.email_used'));
      setSuccessServer('');
    } else if (isExist === 0) {
      setSuccessServer(t('signup_isn.verify_email.email_valid'));
      setErrorServer('');
    } else {
      setErrorServer('');
      setSuccessServer('');
    }
  };

  const responseFacebook = response => {
    var info = {
      firstName: response.name,
      appID: response.userID,
      email: response.email,
    };
    props.fetchLoginByGoogleRequested({ info, loginSocialOK, loginSocailFail });
    showLoader();
  };

  const responseGoogle = response => {
    let userInfo = response.profileObj;
    var info = {
      firstName: userInfo.givenName,
      lastName: userInfo.familyName,
      appID: userInfo.googleId,
      email: userInfo.email,
    };
    props.fetchLoginByGoogleRequested({ info, loginSocialOK, loginSocailFail });
    showLoader();
  };

  const loginSocailFail = error => {
    hideLoader();
    console.log('error: ', error);
  };

  const loginSocialOK = data => {
    hideVerifyEmail();
    const authUserData = {
      rememberMe: false,
      token: {
        access_token: data.token.access_token,
        refresh_token: data.token.refresh_token,
      },
      userInfo: data.userInfo,
    };
    localStorage.setItem('authUserData', JSON.stringify(authUserData));
    hideLoader();
  };

  const handleCloseModal = () => {
    setEmail('');

    setErrorMessage('');
    setErrorServer('');
    setSuccessServer('');
    hideVerifyEmail();
  };

  const disableButtonVerify = () => {
    if (email === '' || errorMessage !== '') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal centered show={showVerifyEmail} backdrop="static" onHide={handleCloseModal}>
        <form onSubmit={verifyEmail} id="verifyEmailModal" className="text-left container-fluid">
          <div className="close-btn" onClick={handleCloseModal}>
            <img src={Close} alt="close-icon" />
          </div>
          <div className="mx-5 my-4">
            <div className="text-center mb-4 mt-5">
              <img src={ISN} alt="isn-logo"></img>
            </div>
            <h3 className="title text-center">{t('signup_isn.verify_email.modal_title')}</h3>
            {errorServer && <Alert className="err-server mt-3 mb-0">{errorServer}</Alert>}
            {successServer && <Alert className="success-server mt-3 mb-0">{successServer}</Alert>}
            <div className="form-group mt-3 mb-2">
              <div className="text-left">
                <label className="email-input">{t('signup_isn.verify_email.label_email')}</label>
                <span className="required-mask">&nbsp;*</span>
              </div>
              <input
                type="text"
                value={email}
                onChange={e => validateEmail(e)}
                className={errorMessage !== '' ? 'invalid-email' : ''}
              />
            </div>
            {errorMessage !== '' ? (
              <div className="err-message">
                <p className="mt-0 mb-3">{errorMessage}</p>
              </div>
            ) : (
              ''
            )}
            <div className="get-email mt-3">
              <p>{t('signup_isn.verify_email.text_instruction_regist')}</p>
            </div>
            <button className="verify-email-btn signup-btn" disabled={disableButtonVerify()} type="submit">
              {t('signup_isn.verify_email.btn_verify_email')}
            </button>
            <h3 className="choose-method text-center my-3">{t('signup_isn.verify_email.label_or')}</h3>
            <div className="box-facebook d-flex justify-content-center align-items-center">
              <FacebookLogin
                appId="359587022240429"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                textButton={t('signup_isn.verify_email.btn_login_fb')}
              ></FacebookLogin>
              <img src={Facebook} alt="facebook-logo"></img>
            </div>
            <div className="box-google mb-5">
              <GoogleLogin
                className="google-btn signup-btn d-flex justify-content-center align-items-center mt-3"
                clientId="39305247639-s6l7opef8s5k350v34s38efhktn7sctv.apps.googleusercontent.com"
                buttonText={t('signup_isn.verify_email.btn_login_gg')}
                onSuccess={responseGoogle}
                cookiePolicy={'single_host_origin'}
              ></GoogleLogin>
            </div>
          </div>
        </form>
      </Modal>
      {loader}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchVerifyEmailRequested: actions.fetchVerifyEmailRequested,
      fetchLoginByGoogleRequested: actions.fetchLoginByGoogleRequested,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailComponent);
