import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import * as actions from '../detailApartment.action';

function BannerApartmentComponent(props) {
  const { id } = useParams();
  const { language } = useParams();
  const { bannerInfo } = props.detailApartmentData;

  useEffect(() => {
    props.getBannerRequest({ language, commonId: id });

    //eslint-disable-next-line
  }, []);

  const handleBanner = () => {
    if (bannerInfo) {
      localStorage.setItem('apartmentName', bannerInfo.name);
      return (
        <div
          className="aparment-banner"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_ISN_IMAGE_URL}${bannerInfo.imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center aparment-banner__info">
            <div className="aparment-banner__info--title text-truncate-banner-name text-center">{bannerInfo.name}</div>
            <div className="aparment-banner__info--address text-truncate-banner-name text-center pt-3">
              {bannerInfo.address}
            </div>
          </div>
          <div className="aparment-banner__color-overlay"></div>
        </div>
      );
    }
  };

  return <div id="detailApartmentBanner">{handleBanner()}</div>;
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBannerRequest: actions.getBannerRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BannerApartmentComponent);
