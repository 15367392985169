const FETCH_VERIFY_EMAIL_REQUESTED = 'FETCH_VERIFY_EMAIL_REQUESTED';
const FETCH_VERIFY_EMAIL_SUCCEEDED = 'FETCH_VERIFY_EMAIL_SUCCEEDED';
const FETCH_VERIFY_EMAIL_FAILED = 'FETCH_VERIFY_EMAIL_FAILED';

const FETCH_VERIFY_KEY_EMAIL_REQUESTED = 'FETCH_VERIFY_KEY_EMAIL_REQUESTED';
const FETCH_VERIFY_KEY_EMAIL_SUCCEEDED = 'FETCH_VERIFY_KEY_EMAIL_SUCCEEDED';
const FETCH_VERIFY_KEY_EMAIL_FAILED = 'FETCH_VERIFY_KEY_EMAIL_FAILED';

const FETCH_COMPLETE_SIGNUP_REQUESTED = 'FETCH_COMPLETE_SIGNUP_REQUESTED';
const FETCH_COMPLETE_SIGNUP_SUCCEEDED = 'FETCH_COMPLETE_SIGNUP_SUCCEEDED';
const FETCH_COMPLETE_SIGNUP_FAILED = 'FETCH_COMPLETE_SIGNUP_FAILED';

const FETCH_LOGIN_BY_GOOGLE_REQUESTED = 'FETCH_LOGIN_BY_GOOGLE_REQUESTED';
const FETCH_LOGIN_BY_GOOGLE_SUCCEEDED = 'FETCH_LOGIN_BY_GOOGLE_SUCCEEDED';
const FETCH_LOGIN_BY_GOOGLE_FAILED = 'FETCH_LOGIN_BY_GOOGLE_FAILED';

export { 
    FETCH_VERIFY_EMAIL_REQUESTED, 
    FETCH_VERIFY_EMAIL_SUCCEEDED, 
    FETCH_VERIFY_EMAIL_FAILED,
    FETCH_VERIFY_KEY_EMAIL_REQUESTED,
    FETCH_VERIFY_KEY_EMAIL_SUCCEEDED,
    FETCH_VERIFY_KEY_EMAIL_FAILED,
    FETCH_COMPLETE_SIGNUP_REQUESTED,
    FETCH_COMPLETE_SIGNUP_SUCCEEDED,
    FETCH_COMPLETE_SIGNUP_FAILED,
    FETCH_LOGIN_BY_GOOGLE_REQUESTED,
    FETCH_LOGIN_BY_GOOGLE_SUCCEEDED,
    FETCH_LOGIN_BY_GOOGLE_FAILED,
};
