import * as types from './home.types';

export const fetchAboutUsRequest = params => ({ type: types.FETCH_ABOUT_US_REQUEST, params });
export const fetchAboutUsSuccess = data => ({ type: types.FETCH_ABOUT_US_SUCCESS, data });
export const fetchAboutUsFailure = error => ({ type: types.FETCH_ABOUT_US_FAILURE, error });

export const fetchEssentialsRequest = params => ({ type: types.FETCH_ESSENTIALS_REQUEST, params });
export const fetchEssentialsSuccess = data => ({ type: types.FETCH_ESSENTIALS_SUCCESS, data });
export const fetchEssentialsFailure = error => ({ type: types.FETCH_ESSENTIALS_FAILURE, error });

export const fetchBlogsHotRequest = params => ({ type: types.FETCH_BLOGS_HOT_REQUEST, params });
export const fetchBlogsHotSuccess = data => ({ type: types.FETCH_BLOGS_HOT_SUCCESS, data });
export const fetchBlogsHotFailure = error => ({ type: types.FETCH_BLOGS_HOT_FAILURE, error });

export const fetchFeaturedProjectsRequest = params => ({ type: types.FETCH_FEATURED_PROJECTS_REQUEST, params });
export const fetchFeaturedProjectsSuccess = data => ({ type: types.FETCH_FEATURED_PROJECTS_SUCCESS, data });
export const fetchFeaturedProjectsFailure = error => ({ type: types.FETCH_FEATURED_PROJECTS_FAILURE, error });

export const fetchServicedRequest = params => ({ type: types.FETCH_SERVICED_REQUEST, params });
export const fetchServicedSuccess = data => ({ type: types.FETCH_SERVICED_SUCCESS, data });
export const fetchServicedFailure = error => ({ type: types.FETCH_SERVICED_FAILURE, error });
