import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';

// import * as actions from '../../redux/layout.action';
import { bindActionCreators } from 'redux';
import { isMobile } from '../../../utils/constants';

const PartnerSlides = props => {
  const options = {
    items: isMobile ? 1 : 4,
    margin: 10,
    loop: isMobile ? true : false,
    autoplay: true,
    autoHeight: true,
    dots: false,
    nav: false,
    slideTransition: 'linear',
    // autoplaySpeed: 500,
    animateOut: 'fadeOut',
  };

  useEffect(() => {
    // props.getPartnerRequest({ language: 'en', categorySlug: 'partner-list' });
    // eslint-disable-next-line
  }, []);

  const partners = props.layout.partner || null;

  const slideBanner = useMemo(() => {
    if (partners && partners.length) {
      return (
        <OwlCarousel options={options}>
          {partners.map((item, index) => (
            <div className="thumbnail" key={index}>
              <span>
                <img alt="" src={item.banner} />
              </span>
            </div>
          ))}
        </OwlCarousel>
      );
    }

    // eslint-disable-next-line
  }, [partners]);

  return (
    <>
      <div className="parners-slide">{slideBanner}</div>
    </>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => bindActionCreators({ 
  // getPartnerRequest: actions.getPartnerRequest 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSlides);
