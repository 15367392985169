import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { getLanguage, getQueryString } from '../../../../utils/params';
import { getFaqsOwners } from 'modules/forOwnersPage/forOwners.api';
import minus from '../../../../assets/images/minus.svg';
import plus from '../../../../assets/images/plus.svg';

const Questions = props => {
  const language = getLanguage(useLocation());
  const [isShowCollapse, setIsShowCollapse] = useState({ key: '', value: false });
  const [questions, setQuestions] = useState();

  useEffect(() => {
    props.showLoader()
    getFaqsOwners(getQueryString({ language})).then(res => {
      if (res) {
        setQuestions(res);
      }
      props.hideLoader();
    });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [language]);

  return (
    <div className="box-white">
      <div id="accordion">
        {questions && questions.length &&
          questions.map((item, index) => {
            let description = item.content || '';
            // description = description.replace(/\n/gi, '<br />');
            // description = description.replace(/\r\n/gi, 'ABC');
            return (
              <div className="card" key={index}>
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <div
                      className={`faqs-collapse ${!(item.id === isShowCollapse.key && isShowCollapse.value) ? '' : 'actived'
                        }`}
                      onClick={() =>
                        setIsShowCollapse({
                          key: item.id,
                          value: !(item.id === isShowCollapse.key && isShowCollapse.value),
                        })
                      }
                    >
                      <div className="faqs-collapse-title jp-font-sawarabi-gothic">{item.title || ''}</div>
                      <img
                        src={isShowCollapse.key === item.id && isShowCollapse.value ? minus : plus}
                        className="img-plus"
                        alt=""
                      />
                      <span></span>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className={`collapse  ${item.id === isShowCollapse.key && isShowCollapse.value ? 'show' : ''}`}
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({ }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
