import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { IconSearch } from 'assets/images/icon';
import { getLanguage, getQueryString } from '../../../utils/params';
import SearchPopup from './SearchPopup.component';
import { MailSupports, MailSupportsActive, PhoneSupports, PhoneSupportsActive } from 'assets/images/web-isn';
import { getContactByType } from 'modules/helpPage/helps.api';

const Supports = () => {
  const location = useLocation();
  const { t } = useTranslation();
  // const pathLanguage = getPathLanguage(location);
  const language = getLanguage(location);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [showIssuePopup, setShowIssuePopup] = useState(false);
  const history = useHistory();
  const [contact, setContact] = useState({});

  useEffect(() => {
    const city = localStorage.getItem('LOCATION_CITY');
    //eslint-disable-next-line
    getContactByType(getQueryString({ cityId: city ?? 1, language: language, type: 1 })).then(res => {
      if (res) {
        setContact(res[0] ?? {});
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleShowSearchPopup = () => {
    if (location.pathname === '/' + language) {
      document.getElementById('searchForm').focus();
      window.scrollTo(0, 0);
    } else {
      setShowSearchPopup(!showSearchPopup);
    }
  };

  const useOutsideAlerter = ref => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowPhonePopup(false);
          setShowIssuePopup(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <React.Fragment>
      <div ref={wrapperRef} className={`supports show d-md-block no-printme`}>
        <ul>
          <li>
            <div onClick={() => handleShowSearchPopup()} className="icon-search">
              <img src={IconSearch} alt="" />
            </div>
            {showSearchPopup ? <SearchPopup isShow={showSearchPopup} handleShow={() => handleShowSearchPopup()} /> : ''}
          </li>
          <li className={`${showPhonePopup ? 'active' : ''}`}>
            <div
              onClick={() => {
                setShowPhonePopup(!showPhonePopup);
                if (showIssuePopup) {
                  setShowIssuePopup(false);
                }
              }}
            >
              <img src={showPhonePopup === true ? PhoneSupportsActive : PhoneSupports} alt="" />
            </div>
            {showPhonePopup ? (
              <div className={`buttons phone ${showPhonePopup ? 'active' : ''}`}>
                <div className="button button-red">
                  <span className="capitalize">{t('search_property.for_inquiry')}</span>
                  <a href={`tel: ${contact?.phone}`}>
                    <span>{contact?.phone}</span>
                  </a>
                </div>
                {/* <div className="button button-red button-gray">
                  <span className="capitalize">customer support</span>
                  <a href="tel: 091-868-4649">
                    <span>091-868-4649</span>
                  </a>
                </div> */}
              </div>
            ) : (
              ''
            )}
          </li>
          <li className="box-email">
            <div
              onClick={() => {
                setShowIssuePopup(!showIssuePopup);
                if (showPhonePopup) {
                  setShowPhonePopup(false);
                }
              }}
            >
              <img src={showIssuePopup ? MailSupportsActive : MailSupports} alt="" />
            </div>
            {showIssuePopup ? (
              <div className={`buttons phone ${showIssuePopup ? 'active' : ''}`}>
                <div
                  className="button button-red"
                  onClick={() => {
                    setShowIssuePopup(false);
                    history.push(`/${language}/about-us/contact-us`);
                  }}
                >
                  <span className="capitalize">{t('search_property.for_inquiry')}</span>
                  <div href={`mailto: ${contact?.emailSupport}`}>
                    <span> {contact?.emailSupport}</span>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </li>
        </ul>
      </div>
      <div className={`overlay ${showPhonePopup === true || showIssuePopup === true ? 'active' : ''}`}></div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      /*fetchCustomerContactRequest*/
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(Supports);
