import { get } from 'lodash';
import Service from '../../services';

export const getBannerRequest = async params => {
  const response = await Service.isnGet(`/api/apartment/banner-apartment${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getOverviewRequest = async params => {
  const response = await Service.isnGet(`/api/apartment/overview-apartment${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getAmenitiesRequest = async params => {
  const response = await Service.isnGet(`/api/apartment/amenities-apartment${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getLocationRequest = async params => {
  const response = await Service.isnGet(`/api/apartment/location-apartment${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getGalleryRequest = async params => {
  const response = await Service.isnGet(`/api/apartment/gallery-apartment${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const checkTokenReview = async params => {
  const response = await Service.isnPost(`/api/review/check-verify`, params, res => res);
  // const data = get(response, 'data', null);
  return response;
};

export const getTagsReview = async params => {
  const response = await Service.isnGet(`/api/review/get-tags${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const createReview = async params => {
  const response = await Service.isnPost(`/api/review/update-review`, params, res => res);
  // const data = get(response);
  return response;
};

export const getRatingRequest = async params => {
  const response = await Service.isnGet(`/api/review/review-total${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getReviewRequest = async params => {
  const response = await Service.isnGet(`/api/review/reviews${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getDetailOffer = async params => {
  const response = await Service.isnGet(`/api/apartment/details-offer${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getApartmentId = async params => {
  const response = await Service.isnGet(`/api/apartment/get-apartment-id${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getApartmentDetail = async params => {
  const response = await Service.isnGet(`/api/apartment/details-apartment${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const postContactUsRequest = async params => {
  const response = await Service.isnPostFormData(`/api/booking/submit-long-term`, params, res => res);
  const data = get(response, 'data', null);
  return data;
};