import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { getLanguage, getQueryString } from '../../../../utils/params';
import { ResetIcon, SearchIcon, CloseIcon } from '../../icons';
import { Input, Select, Checkbox } from '../../../../components';
import {
  SearchLub,
  Buildings,
  ArmChair,
  DoubleBed,
  Location,
  EyeIcon,
} from '../../../../assets/images/web-isn';
import ArrowsSize from '../../../../assets/images/web-isn/arrows-size.svg';
import * as actions from '../../../redux/layout.action';
import debounce from 'lodash.debounce';
import { getTotalUnitServicedApartment } from '../../../../modules/searchResultPage/searchResult.api';

const SearchResidence = props => {
  const { t } = useTranslation();
  const language = getLanguage(useLocation());
  const { register, errors, watch } = useForm();
  const history = useHistory();
  const locationPath = useLocation();
  const searchParams = new URLSearchParams(useLocation().search);
  let residence_keyword = watch('residence_keyword', '');
  const [showMoreConditions, setShowMoreConditions] = useState(false);
  const [isShowMore, setIsShowMore] = useState({ key: '', value: false });
  const { listDistricts, listCity, listCondition, listRangeSetting } = props?.layout;
  const currency = props?.layout?.currentCurrency;
  const { residencePriceMax, residencePriceMin, residencePriceMaxVi, residencePriceMinVi } = listRangeSetting || {};
  const [units, setUnits] = useState([0, 5000]);
  const { bedrooms, sizes, facilities, views } = listCondition || {};
  const { locations } = listCity || {};
  const location = localStorage.getItem('LOCATION_CITY') ?? localStorage.getItem('DEFAULT_CITY');
  const [city, setCity] = useState(location);
  const [formData, setFormData] = useState({
    districts: [],
    bedrooms: [],
    projects: [],
    sizes: [],
    types: [],
    facilities: {
      buildingFacilities: [],
      interiorFacilities: [],
    },
    views: []
  });

  const [displayOption, setDisplayOption] = useState({
    districts: t('home_page.search.district'),
    bedrooms: t('home_page.search.label_rooms'),
    city: t('home_page.search.city'),
    projects: t('home_page.search.building_name'),
    sizes: t('home_page.search.size_range'),
    facilities: t('home_page.search.facilities'),
    views: t('home_page.search.views'),
  });

  const interiorFacilities = facilities?.find(element => {
    return element.id === 2;
  });
  const buildingFacilities = facilities?.find(element => {
    return element.id === 3;
  });

  useEffect(() => {
    if (residencePriceMax !== undefined && residencePriceMaxVi !== undefined && residencePriceMin !== undefined && residencePriceMinVi !== undefined) {
      let maxRange = currency?.code === 'USD' ? residencePriceMax : residencePriceMaxVi;
      let minRange = currency?.code === 'USD' ? residencePriceMin : residencePriceMinVi;
      setUnits([minRange, maxRange]);
    }
    if (props.isShowModal) {
      setShowMoreConditions(true);
    }
    // eslint-disable-next-line
  }, [currency, listRangeSetting]);

  useEffect(() => {
    if (isShowMore.value) document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

    // eslint-disable-next-line
  }, [isShowMore]);

  useEffect(() => {
    props.fetchDistrictsByCityIdRequest({ language: language, cityId: city });
    setFormData({ ...formData, districts: [] })
    //eslint-disable-next-line
  }, [city])


  useEffect(() => {
    const textDistrict = returnTextOption(
      formData.districts,
      listDistricts,
      'districtName',
      t('home_page.search.district'),
    );
    const textBedroom = returnTextOption(formData.bedrooms, bedrooms, 'name', t('home_page.search.label_rooms'));
    const textBuildingName = returnTextOption(formData.projects, 'projectName', t('home_page.search.building_name'));
    const textSize = returnTextOption(formData.sizes, sizes, 'name', t('home_page.search.size_range'));
    const textViews = returnTextOption(formData.views, views, 'name', t('home_page.search.views'));

    let textFacilities = t('home_page.search.facilities');
    const listFacilities = [...formData.facilities.buildingFacilities, ...formData.facilities.interiorFacilities];
    if (listFacilities.length === 1) {
      const detail = [...facilities[2].items, ...facilities[1].items].find(i => i.id === listFacilities[0]);
      textFacilities = detail && detail.name ? detail.name : t('home_page.search.facilities');
    } else if (listFacilities.length > 1) {
      textFacilities = t('home_page.search.selected');
    }

    setDisplayOption({
      ...displayOption,
      districts: textDistrict,
      bedrooms: textBedroom,
      projects: textBuildingName,
      sizes: textSize,
      facilities: textFacilities,
      views: textViews,
    });
    // eslint-disable-next-line
  }, [formData, units, residence_keyword]);

  useEffect(() => {
    let filter = {
      language: language,
      cityId: city,
      keyword: residence_keyword.trim(),
      currencyUnit: currency?.code,
      districtId: formData?.districts?.join(','),
      type: 1, //long term
      bedroom: formData?.bedrooms?.join(','),
      leasePriceMin: units[0],
      leasePriceMax: units[1],
      size: formData?.sizes?.join(','),
      view: formData?.views?.join(','),
      facilities: [...formData?.facilities?.buildingFacilities, ...formData?.facilities?.interiorFacilities]?.join(','),
    }
    debounceTotalUnit(filter);
    return () => {
      debounceTotalUnit.cancel();
    }
    // eslint-disable-next-line
  }, [city, formData, units, residence_keyword])

  const handleOnChange = value => {
    if (value)
      setCity(value);
  };

  const handleShowMore = () => {
    setShowMoreConditions(true);
  };

  const returnTextOption = (option, listData, name, defaultText) => {
    if (option.length === 1) {
      const detail = listData.find(i => i.id === option[0]);
      return detail && detail[name] ? detail[name] : defaultText;
    } else if (option.length > 1) {
      return t('home_page.search.selected');
    }
    return defaultText;
  };

  const onChangeFormData = (key, value) => {
    const cloneFormData = { ...formData };
    if (Object.keys(cloneFormData).includes(key) && value.length < 5) {
      if (cloneFormData[key].includes(parseInt(value))) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== parseInt(value));
      } else cloneFormData[key].push(parseInt(value));
    } else if (Object.keys(cloneFormData.facilities).includes(key)) {
      if (cloneFormData.facilities[key].includes(Number.parseInt(value))) {
        cloneFormData.facilities[key] = cloneFormData.facilities[key].filter(item => item !== Number.parseInt(value));
      } else cloneFormData.facilities[key].push(Number.parseInt(value));
    } else {
      if (cloneFormData[key].includes(value)) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== value);
      } else cloneFormData[key].push(value);
    }
    setFormData(cloneFormData);
  };

  const handleClickOutside = e => {
    if (isShowMore.value) {
      if (e.target.className && !e.target.className.toString().includes('flag-dropdown')) {
        setIsShowMore({ key: '', value: false });
      }
    }
  };

  const resetForm = (e) => {
    e.preventDefault();
    setFormData({
      districts: [],
      bedrooms: [],
      projects: [],
      sizes: [],
      types: [],
      facilities: {
        buildingFacilities: [],
        interiorFacilities: [],
      },
      views: [],
    });
    document.getElementsByName('residence_keyword')[0].value = '';
    let maxRange = currency?.code === 'USD' ? residencePriceMax : residencePriceMaxVi;
    let minRange = currency?.code === 'USD' ? residencePriceMin : residencePriceMinVi;
    setCity(location);
    setUnits([minRange, maxRange]);
  };

  const selectCityRef = useRef(null);

  // const onClickCity = () => {
  //   selectCityRef.current.mouseDown();
  //   console.log(selectCityRef.current)
  // }
  const listCityDropdown = useMemo(() => {
    if (locations && locations.length) {
      return (
        <Select
          options={locations.map(item => ({ value: item.cityId, label: item.cityName })) || []}
          class_name="form-control font-noto-sans-jp"
          src={Buildings}
          onChange={handleOnChange}
          defaultValue={city}
          register={selectCityRef}
        // onClickIcon={onClickCity}
        />
      );
    }
    return (
      <Select
        disabled
        options={[]}
        isAll={{ value: '', label: t('home_page.search.city') }}
        class_name="form-control"
        src={Buildings}
      />
    );
    // eslint-disable-next-line
  }, [locations, city]);

  const listBedroomsDropdown = useMemo(() => {
    if (bedrooms && bedrooms.length) {
      return bedrooms.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.bedrooms}
            onChangeFormData={onChangeFormData}
            formName="bedrooms"
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [bedrooms, formData]);

  const listBuildingsDropdown = useMemo(() => {
    if (buildingFacilities?.items && buildingFacilities?.items.length > 0) {
      return buildingFacilities?.items.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.facilities.buildingFacilities}
            onChangeFormData={onChangeFormData}
            formName="buildingFacilities"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [buildingFacilities, formData]);

  const listDistrictsDropdown = useMemo(() => {
    if (listDistricts && listDistricts.length) {
      return listDistricts.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown sub-text" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.districtName}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="districts"
            onChangeFormData={onChangeFormData}
            defaultValue={formData.districts}
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [listDistricts, formData]);

  const listViewsDropdown = useMemo(() => {
    if (views && views.length) {
      return views.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'views'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.views}
            onChangeFormData={onChangeFormData}
            formName="views"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [views, formData]);

  const listInteriorsDropdown = useMemo(() => {
    if (interiorFacilities && interiorFacilities) {
      return interiorFacilities.items.map((chk, i) => (
        <div className="col-12 col-md-4 flag-dropdown" key={i}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'interior'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            defaultValue={formData.facilities.interiorFacilities}
            onChangeFormData={onChangeFormData}
            formName="interiorFacilities"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [facilities, formData]);

  const listSizesDropdown = useMemo(() => {
    if (sizes && sizes.length) {
      return sizes.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'size'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.sizes}
            onChangeFormData={onChangeFormData}
            formName="sizes"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [sizes, formData]);

  const debounceTotalUnit = useCallback(
    debounce(
      (nextValue) =>
        fetchTotalUnit(nextValue), 1000
    ),
    [])

  const fetchTotalUnit = async (params) => {
    await getTotalUnitServicedApartment(getQueryString(params))
  }

  const onSearchClick = (e) => {
    //pass form search to component by state of history
    e.preventDefault();
    let filter = {
      language: language,
      cityId: city,
      keyword: residence_keyword.trim(),
      currencyUnit: currency?.code,
      districtId: formData?.districts?.join(','),
      type: 1, //long term
      bedroom: formData?.bedrooms?.join(','),
      leasePriceMin: units[0],
      leasePriceMax: units[1],
      size: formData?.sizes?.join(','),
      view: formData?.views?.join(','),
      facilities: [...formData?.facilities?.buildingFacilities, ...formData?.facilities?.interiorFacilities]?.join(','),
    }
    sessionStorage.setItem('filter_search', JSON.stringify(filter));
    let queryString = '';
      if (locationPath.pathname.includes('search-result')) {
        // setOrderBy(searchParams.get('searchGlobal'))
        const searchGlobal = searchParams.get('searchGlobal') === 'false' ? 'true' : 'false'
        queryString = getQueryString({ pageIndex: 1, pageSize: 15, orderBy: 'desc', searchGlobal: searchGlobal });
      } else {
        queryString = getQueryString({ pageIndex: 1, pageSize: 15, orderBy: 'desc' });
      }

    if(props.isShowModal) {
      props.hanldeCloseModal();
    }
    history.push({
      pathname: `/${language}/search-result`,
      search: queryString,
      state: { filter: filter }
    });
  }
  return (
    <>
      <div className="tab-content" id="searchFormContent">
        <div className="tab-pane fade show active" id="Residence" role="tabpanel" aria-labelledby="Residence-tab">
          {/* <SearchResidenceForm /> */}
          <Form>
            <div className="group-control">
              <div className="row align-items-end d-block d-md-flex">
                <div onClick={() => handleShowMore()} className="col-md-5 col-12 pl-0 pl-md-3 mb-2 pr-0">
                  <Input
                    class_name="form-control font-noto-sans-jp"
                    type="text"
                    placeholder={t('home_page.search.key_word_vinhomes')}
                    errors={errors.residence_keyword}
                    register={register({})}
                    name="residence_keyword"
                    id="mySearch"
                    src={SearchLub}
                    maxLength={255}
                  />
                </div>
                <div className="col-md-5 col-12 pr-0 pl-0 d-block d-md-flex">
                  <div className="col-md-6 col-12  pl-0 pl-md-3 mb-2 pr-0 pr-0">
                    <div className="d-flex flex-column text-left">
                      <div>{listCityDropdown}</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  pl-0 pl-md-3 mb-2 pr-0">
                    <div className="d-flex flex-column text-left">
                      <div className="form-input-group"
                        id="facilities"
                        onClick={() => {
                          setIsShowMore({ key: 'showMoreBedRoom', value: true });
                        }}>
                        <div
                          className={`form-control font-noto-sans-jp select-control ${displayOption.bedrooms !== t('home_page.search.label_rooms') ? 'search-selected' : ''
                            }`}>
                          {displayOption.bedrooms}
                        </div>
                        <img src={DoubleBed} alt="bed-icon"></img>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`facilities fade-in flag-dropdown ${(isShowMore.key === 'showMoreBedRoom' && isShowMore.value ? 'show' : '') || ''
                      }`}
                    id="fcDropDown"
                  >
                    <div className="row flag-dropdown">
                      <div className="col-10 flag-dropdown">
                        <span className="link-red uppercase flag-dropdown">{t('home_page.search.label_rooms')}</span>
                      </div>
                      <div className="col-2 text-right flag-dropdown">
                        <span
                          className="close-more flag-dropdown"
                          onClick={() => {
                            setIsShowMore({ key: 'showMoreBedRoom', value: false });
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div className="row building flag-dropdown">{listBedroomsDropdown}</div>
                  </div>
                </div>

                <div className="d-none d-md-block col-2 mb-2 pr-0">
                  <div className="d-flex flex-column text-left">
                    <div className="col-12 btn-search-home">
                      <button className="btn-submit-home mr-3" onClick={(e) => onSearchClick(e)}>
                        <SearchIcon />
                      </button>
                      <button className="btn-reset-home"
                        onClick={(e) => resetForm(e)}>
                        <ResetIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* district */}
              <div className={`row align-items-end ${(!showMoreConditions && 'more') || ''}`}>
                <div className="col-md-5 pl-0 pl-md-3 col-12 mb-1 pr-0 d-block d-md-flex">
                  <div className="d-flex flex-column col-md-6 col-12  pl-0 mb-2 pr-0 text-left">
                    <div className="form-input-group font-noto-sans-jp"
                      id="facilities"
                      onClick={() => {
                        if (listDistricts && listDistricts.length)
                          setIsShowMore({ key: 'showMoreDistrict', value: true });
                      }}>
                      <div
                        className={`form-control select-control font-noto-sans-jp${displayOption.districts !== t('home_page.search.district') ? ' search-selected' : ''
                          }`}
                      >
                        {displayOption.districts}
                      </div>
                      <img src={Location} alt="location-icon"></img>
                    </div>
                  </div>
                  <div
                    className={`district fade-in flag-dropdown ${(isShowMore.key === 'showMoreDistrict' && isShowMore.value ? 'show ' : '') || ''
                      }`}
                    id="fcDropDown"
                  >
                    <div className="row flag-dropdown">
                      <div className="col-10 flag-dropdown">
                        <span className="link-red uppercase flag-dropdown">{t('home_page.search.district')}</span>
                      </div>
                      <div className="col-2 text-right flag-dropdown">
                        <span
                          className="close-more flag-dropdown"
                          onClick={() => {
                            setIsShowMore({ key: 'showMoreDistrict', value: false });
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div className="row building flag-dropdown">{listDistrictsDropdown}</div>
                  </div>
                  {/* views */}
                  <div className="d-flex flex-column mb-2 col-md-6 col-12  pl-0 pl-md-1 pr-0 text-left">
                    <div className="form-input-group font-noto-sans-jp"
                      id="facilities"
                      onClick={() => setIsShowMore({ key: 'showMoreViews', value: true })}>
                      <div
                        className={`form-control select-control font-noto-sans-jp${displayOption.views !== t('home_page.search.views') ? ' search-selected' : ''
                          }`}
                      >
                        {displayOption.views}
                      </div>
                      <img src={EyeIcon} alt="view-icon"></img>
                    </div>
                  </div>
                  <div
                    className={`views add-width fade-in flag-dropdown ${(isShowMore.key === 'showMoreViews' && isShowMore.value ? 'show' : '') || ''
                      }`}
                    id="fcDropDown"
                  >
                    <div className="row flag-dropdown">
                      <div className="col-10 flag-dropdown">
                        <span className="link-red uppercase flag-dropdown">{t('home_page.search.views')}</span>
                      </div>
                      <div className="col-2 text-right flag-dropdown">
                        <span
                          className="close-more flag-dropdown"
                          onClick={() => setIsShowMore({ key: 'showMoreViews', value: false })}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div className="row building flag-dropdown">{listViewsDropdown}</div>
                  </div>
                </div>
                {/* size */}
                <div className="col-md-5 col-12 mb-1 pr-0 pl-0 d-block d-md-flex">
                  <div className="d-flex flex-column mb-2 col-md-6 col-12  pl-0 pl-md-3 pr-0 text-left">
                    <div className="form-input-group font-noto-sans-jp"
                      id="facilities"
                      onClick={() => setIsShowMore({ key: 'showMoreSize', value: true })}>
                      <div
                        className={`form-control select-control font-noto-sans-jp${displayOption.sizes !== t('home_page.search.size_range') ? ' search-selected' : ''
                          }`}
                      >
                        {displayOption.sizes}
                      </div>
                      <img src={ArrowsSize} alt="arrows-size"></img>
                    </div>
                  </div>

                  <div
                    className={`facilities fade-in flag-dropdown ${(isShowMore.key === 'showMoreSize' && isShowMore.value ? 'show' : '') || ''
                      }`}
                    id="fcDropDown"
                  >
                    <div className="row flag-dropdown">
                      <div className="col-10 flag-dropdown">
                        <span className="link-red uppercase flag-dropdown">{t('home_page.search.size_range')}</span>
                      </div>
                      <div className="col-2 text-right flag-dropdown">
                        <span
                          className="close-more flag-dropdown"
                          onClick={() => setIsShowMore({ key: 'showMoreSize', value: false })}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div className="row building flag-dropdown">{listSizesDropdown}</div>
                  </div>
                  {/* facilities */}
                  <div className="d-flex flex-column pr-0 mb-2 col-md-6 col-12  pl-0 pl-md-3 text-left">
                    <div className="form-input-group font-noto-sans-jp"
                      id="facilities"
                      onClick={() => setIsShowMore({ key: 'showMoreFacilities', value: true })}>
                      <div
                        className={`form-control select-control font-noto-sans-jp${displayOption.facilities !== t('home_page.search.facilities') ? ' search-selected' : ''
                          }`}
                      >
                        {displayOption.facilities}
                      </div>
                      <img src={ArmChair} alt="chair-icon"></img>
                    </div>
                  </div>
                  <div
                    className={`facilities add-width fade-in flag-dropdown ${(isShowMore.key === 'showMoreFacilities' && isShowMore.value ? 'show' : '') || ''
                      }`}
                    id="fcDropDown"
                  >
                    <div className="row flag-dropdown">
                      <div className="col-10 flag-dropdown">
                        <span className="link-red uppercase flag-dropdown">
                          {t('home_page.search.building_facilities')}
                        </span>
                      </div>
                      <div className="col-2 text-right flag-dropdown">
                        <span
                          className="close-more flag-dropdown"
                          onClick={() => setIsShowMore({ key: 'showMoreFacilities', value: false })}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div className="row building flag-dropdown">{listBuildingsDropdown}</div>
                    <div className="row interior flag-dropdown">
                      <div className="col-12 flag-dropdown">
                        <span className="link-red uppercase flag-dropdown">
                          {t('home_page.search.interior_facilities')}
                        </span>
                      </div>
                    </div>
                    <div className="row flag-dropdown">{listInteriorsDropdown}</div>
                  </div>
                </div>

              </div>
              <div className="d-md-none col-12 mt-3 pl-0 pr-0 btn-search-home d-block">
                <div className="col-12 mb-4 pl-0 pr-0">
                  <button className="btn-submit-home w-100" onClick={(e) => onSearchClick(e)}>
                    <SearchIcon /> {t('home_page.search.text_btn_search')}
                  </button>
                </div>
                <div className="col-12 pl-0 pr-0">
                  <button className="btn-reset-home w-100"
                    onClick={(e) => resetForm(e)}>
                    <ResetIcon /> {t('home_page.search.reset_criteria')}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

// export default SearchResidence;
const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchDistrictsByCityIdRequest: actions.fetchDistrictsByCityIdRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchResidence);
