// eslint-disable-next-line no-unused-vars
import { get } from 'lodash';
import Service from '../../services';

export const fetchListInfomation = async params => {
  const response = await Service.isnGet(`/api/filters${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const postInformation = async params => {
  const response = await Service.isnPost(`/api/owners/`, params, res => res);
  // const data = get(response);
  return response;
};

export const getOwnerCategories = async params => {
  const response = await Service.isnGet(`/api/owners/get-categories${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getFaqsOwners = async params => {
  const response = await Service.isnGet(`/api/owners/get-asked${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchCities = async params => {
  const response = await Service.isnGet(`/api/cities/list-cities${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchBedrooms = async params => {
  const response = await Service.isnGet(`/api/owners/get-bedrooms${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchBathrooms = async params => {
  const response = await Service.isnGet(`/api/owners/get-bathrooms${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchPropertyTypes = async params => {
  const response = await Service.isnGet(`/api/owners/get-types${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getBanner = async params => {
  const response = await Service.isnGet(`/api/owners/get-banner${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};