import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Close, Facebook, ISN } from '../../../assets/images/web-isn';
import * as actions from '../login.action';
import * as actionsSignup from '../../signUp/signUp.action';
import useFullPageLoader from '../../commons/isn/useFullPageLoader';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const LoginComponent = props => {
  const { t } = useTranslation();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [passwordErrMsg, setPasswordErrMsg] = useState('');
  const [errorServer, setErrorServer] = useState('');
  const authUserData = JSON.parse(localStorage.getItem('authUserData'));
  const { language } = useParams();

  const handleCheckbox = e => {
    let rememberCheck = e.target.checked;
    const newErrors = { ...errors };
    setErrors(newErrors);
    setRememberMe(rememberCheck);
  };

  const validateEmail = e => {
    let emailInput = e.target.value.trim();
    let regexEmail = /^([0-9a-zA-Z]([+\-_.][0-9a-zA-Z])*)+@([a-zA-Z_]+?\.)+[a-zA-Z]{2,4}$/;
    if (emailInput.length > 255) {
      emailInput = emailInput.slice(0, 255);
    }
    if (emailInput === '' || emailInput.length === 0) {
      setEmailErrMsg(t('login_isn.message_email_required'));
      setErrorServer('');
    } else if (regexEmail.test(emailInput)) {
      setEmailErrMsg('');
    } else {
      setEmailErrMsg(t('login_isn.message_email_invalid'));
    }
    setEmail(emailInput);
  };

  const validatePassword = e => {
    let password = e.target.value.trim();
    if (password.length > 48) {
      password = password.slice(0, 48);
    }
    if (password === '') {
      setPasswordErrMsg(t('login_isn.message_password_required'));
    } else {
      setPasswordErrMsg('');
    }
    setPassword(password);
  };

  const handleSubmitForm = e => {
    e.preventDefault();
    const newErrors = { ...errors };
    setErrors(newErrors);
    let info = JSON.stringify({
      email: email,
      password: password,
    });
    props.fetchLoginRequest({ info, handleSuccess, handleError });
    showLoader();
  };

  const handleDisableLogin = () => {
    if (password === '' || email === '' || emailErrMsg !== '' || passwordErrMsg !== '') {
      return true;
    } else {
      return false;
    }
  };

  const handleSuccess = (data, data2) => {
    const authUserData = {
      rememberMe: rememberMe,
      token: {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      },
      userInfo: data2,
    };
    localStorage.setItem('authUserData', JSON.stringify(authUserData));
    props.closeLogin();
    hideLoader();
    window.location.reload();
  };

  const handleError = error => {
    switch (error) {
      case 400:
        setErrorServer(t('login_isn.message_faild'));
        setPassword('');
        setRememberMe(false);
        break;

      case 500:
        setErrorServer(t('login_isn.message_faild'));
        setPassword('');
        setRememberMe(false);
        break;

      default:
        break;
    }
    hideLoader();
  };

  const responseFacebook = response => {
    if (response.status === 'unknown') {
      return;
    } else {
      var info = {
        fullName: response.name,
        appID: response.userID,
        email: response.email ? response.email : '',
      };
      props.fetchLoginByGoogleRequested({ info, loginSocialOK, loginSocailFail });
      showLoader();
    }
  };

  const responseGoogle = response => {
    let userInfo = response.profileObj;
    var info = {
      fullName: userInfo.name,
      appID: userInfo.googleId,
      email: userInfo.email,
    };
    props.fetchLoginByGoogleRequested({ info, loginSocialOK, loginSocailFail });
    showLoader();
  };

  const loginSocailFail = () => {
    hideLoader();
  };

  const loginSocialOK = data => {
    props.closeLogin();
    const authUserData = {
      rememberMe: false,
      token: {
        access_token: data.token.access_token,
        refresh_token: data.token.refresh_token,
      },
      userInfo: data.userInfo,
    };
    localStorage.setItem('authUserData', JSON.stringify(authUserData));
    hideLoader();
    window.location.reload();
  };

  const redirectHome = () => {
    history.push(`/${language}`);
  };

  const handleCloseModal = () => {
    setErrorServer('');
    setEmailErrMsg('');
    setPasswordErrMsg('');

    setEmail('');
    setPassword('');
    setRememberMe(false);
    props.closeLogin();
  };

  useEffect(() => {
    if (authUserData === null) {
      setErrorServer('');
      setEmailErrMsg('');
      setPasswordErrMsg('');

      setEmail('');
      setPassword('');
      setRememberMe(false);
    }
  }, [authUserData]);

  return (
    <>
      <Modal className="modal-login" centered show={props.showLogin} backdrop="static" onHide={handleCloseModal}>
        <div className="close-btn" onClick={handleCloseModal}>
          <img src={Close} alt="close-icon" />
        </div>
        <div id="loginForm" className="container-fluid">
          <form onSubmit={handleSubmitForm} className="login-form mx-5">
            <div onClick={redirectHome} className="text-center mb-4 mt-5">
              <img src={ISN} alt="isn-logo"></img>
            </div>
            <h4 className="login-form__title">{t('login_isn.modal_title')}</h4>

            {/* Error returned from server */}
            {errorServer !== '' ? (
              <div className="alert alert-server alert-danger mt-3 mb-2">
                <p>{errorServer}</p>
              </div>
            ) : (
              ''
            )}

            <div className="form-group d-flex flex-column mt-3 mb-2">
              <label className="mb-2">
                {t('login_isn.label_email')} <span className="required-mask">*</span>
              </label>
              <input
                id="emailField"
                className={emailErrMsg === '' ? 'form-group__input' : 'form-group__input input-error'}
                name="email"
                value={email}
                onChange={e => validateEmail(e)}
                maxLength="255"
              />
            </div>
            {emailErrMsg !== '' ? (
              <div className="mt-0">
                <p className="aler-error">{emailErrMsg}</p>
              </div>
            ) : (
              ''
            )}

            <div className="form-group d-flex flex-column mt-3 mb-2">
              <label className="mb-2">
                {t('login_isn.label_password')} <span className="required-mask">*</span>
              </label>
              <input
                id="passwordField"
                className={passwordErrMsg === '' ? 'form-group__input' : 'form-group__input input-error'}
                name="password"
                type="password"
                value={password}
                onChange={e => validatePassword(e)}
                maxLength="48"
              />
            </div>
            {passwordErrMsg !== '' ? (
              <div className="mt-0 mb-3">
                <p className="aler-error">{passwordErrMsg}</p>
              </div>
            ) : (
              ''
            )}

            <div className="form-group clearfix my-3">
              <div className="float-left">
                <input
                  className="form-group__checkbox"
                  checked={rememberMe}
                  type="checkbox"
                  onChange={handleCheckbox}
                />
                <label className="form-group__label">&nbsp;&nbsp;{t('login_isn.label_rememberMe')}</label>
              </div>

              <div className="float-right">
                <label className="form-group__label mr-2">{t('login_isn.label_forgotPass')}</label>
                <Link
                  to="/"
                  className="form-group__label link"
                  onClick={e => {
                    e.preventDefault();
                    handleCloseModal();
                    props.handleShowForgot();
                  }}
                >
                  {t('login_isn.label_click')}
                </Link>
              </div>
            </div>

            <div className="form-group d-flex flex-column">
              <button className="form-group__button btn-default" type="submit" disabled={handleDisableLogin()}>
                {t('login_isn.btn_login')}
              </button>
            </div>

            <p className="font-weight-normal text-center my-3">{t('login_isn.label_or')}</p>

            <div className="form-group d-flex flex-column">
              <FacebookLogin
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="form-group__button btn-facebook"
                textButton={t('login_isn.btn_login_fb')}
                icon={<img className="icon-size-btn" src={Facebook} alt="facebook-logo"></img>}
              />
            </div>
            <div className="form-group d-flex flex-column">
              <GoogleLogin
                className="google-btn signup-btn d-flex justify-content-center align-items-center mt-3"
                clientId="39305247639-s6l7opef8s5k350v34s38efhktn7sctv.apps.googleusercontent.com"
                buttonText={t('login_isn.btn_login_gg')}
                onSuccess={responseGoogle}
                cookiePolicy={'single_host_origin'}
              ></GoogleLogin>
            </div>

            <p className="text-center my-2" style={{ fontSize: '14px' }}>
              {t('login_isn.label_text_desc')}
            </p>

            <div className="form-group d-flex flex-column mb-5">
              <input
                className="form-group__button btn-signup"
                type="button"
                onClick={props.handleShowSignUp}
                value={t('login_isn.btn_signUp')}
              />
            </div>
          </form>
        </div>
      </Modal>
      {loader}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLoginRequest: actions.fetchLoginRequest,
      fetchLoginByGoogleRequested: actionsSignup.fetchLoginByGoogleRequested,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
