// import React, { useState, useEffect, useMemo, useRef } from 'react';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// import { fromPairs, get } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { getLanguage } from '../../../utils/params';
import SearchResidence from '../searchs/residence/Residence';
import SearchOffices from '../searchs/office/Office';

const tab_home_active = 'tab_home_active';
const long_term = 'long_term';
const short_term = 'short_term';

const SearchPopup = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const [showSearch, setShowSearch] = useState(true);
  // const [height, setHeight] = useState(true);

  const onClickShortTermTab = () => {
    sessionStorage.setItem(tab_home_active, short_term);
    setShowSearch(false);
    // setHeight(true);
  };

  const onClickLongTermTab = () => {
    sessionStorage.setItem(tab_home_active, long_term);
    setShowSearch(true);
    // setHeight(true);
  };

  return (
    <Modal  dialogClassName="popup-search " animation={false} show={props.isShow} onHide={() => props.handleShow()}>
      <div className={`container search-wrapper px-0 ${language === 'jp' ? 'jp' : ''}`}>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="search-form jp-font-sawarabi-gothic">
              <ul className="nav nav-tabs search-tab-margin" id="searchForm" role="tablist">
                <li className="nav-item" role="presentation">
                  <span
                    onClick={() => {
                      onClickLongTermTab();
                    }}
                    className={showSearch === true ? 'nav-link popup-active' : 'nav-link disable'}
                    id="Residence-tab"
                    data-toggle="tab"
                    role="tab"
                  >
                    {t('home_page.search.tab_long_term')}
                  </span>
                </li>
                <li className="nav-item" role="presentation">
                  <span
                    onClick={() => {
                      onClickShortTermTab();
                    }}
                    className={showSearch === false ? 'nav-link popup-active' : 'nav-link disable'}
                    id="Office-tab"
                    data-toggle="tab"
                    role="tab"
                  >
                    {t('home_page.search.tab_short_term')}
                  </span>
                </li>
              </ul>
              {showSearch && <SearchResidence isShowModal={true} hanldeCloseModal={() => props.handleShow()}/>}
              {!showSearch && <SearchOffices isShowModal={true} hanldeCloseModal={() => props.handleShow()}/>}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(SearchPopup);
