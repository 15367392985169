import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import BlockResidenceComponent from '../../commons/block/BlockResidence.component';
import { ARROW_LEFT, ARROW_RIGHT } from '../../../utils/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLanguage, getQueryString } from '../../../utils/params';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchServiced } from '../home.api';

const HomeServiced = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const history = useHistory();
  const { currentCurrency } = props.layout;
  const [listServiced, setListServiced] = useState([]);


  const currentLocale = localStorage.getItem('LOCATION_CITY')
    ? localStorage.getItem('LOCATION_CITY')
    : localStorage.getItem('DEFAULT_CITY');

  const gotoServicedApartmentPage = () => {
    history.push(`/${language}/serviced-apartments?pageIndex=1&pageSize=15&orderBy=desc&sortBy=0`);
  };

  const options = {
    items: 1,
    nav: false,
    dots: false,
    loop: false,
    mouseDrag: false,
    navText: [ARROW_LEFT, ARROW_RIGHT],
    responsive: {},
  };

  useEffect(() => {
    fetchServiced(getQueryString({ language, cityId: currentLocale, pageSize: '3', pageIndex: '1' })).then(res => {
      if (res) {
        setListServiced(res?.data);
      }
    });
    //eslint-disable-next-line
  }, []);

  return (
    <div className="container block-home-properties mt-5">
      <div className="row">
        <div className="col-12 service-apartment">
          <h3 className={`service-title ${language === 'jp' ? 'font-noto-serif-cjk-jp' : ''}`}>{t('home_page.label_serviced_apartment')}</h3>
          <div className="under-line mt-3 mb-5 mx-auto">
            <span className="middle-line"></span>
          </div>
        </div>
        <div className="col-12">
          {listServiced?.items && listServiced?.items.length ? (
            <div className="row">
              {listServiced?.items.map((element, index) => (
                <div key={index} className="p-3 col-xl-4 col-md-6 col-sm-12 col-12">
                  <OwlCarousel
                    options={options}
                    className="nav-top owl-carousel owl-theme owl-loaded owl-drag owl-home-properties"
                  >
                    <div className="col-item">
                      <BlockResidenceComponent
                        metaKey={props.metaKey}
                        section={props.section}
                        post={element}
                        currency={currentCurrency}
                      />
                    </div>
                  </OwlCarousel>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="col-12 mt-5 text-center">
          <button onClick={() => gotoServicedApartmentPage()} className="btn-showmore">
            {t('home_page.btn_showmore')}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeServiced);
