import * as types from './login.types';

const initialState = {
  error: null,
  token: null,
  userInfo: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    /*<---- Fetch Login data---->*/
    case types.FETCH_LOGIN_REQUEST:
      return { ...state };

    case types.FETCH_LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.data.token,
        userInfo: action.data.userInfo ? action.data.userInfo : null,
      };
    }
    case types.FETCH_LOGIN_BY_GOOGLE_SUCCEEDED: {
      return {
        ...state,
        token: action.data.data.token ? action.data.data.token : null,
        userInfo: action.data.data.userInfo ? action.data.data.userInfo : null,
      };
    }

    case types.FETCH_LOGIN_FAILURE:
      return { ...state, error: action.error, token: null, userInfo: null };

    /*<---- Refresh Token ---->*/
    case types.FETCH_REFRESH_TOKEN_REQUEST:
      return { ...state };

    case types.FETCH_REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.data ? action.data : null,
      };
    }

    case types.FETCH_REFRESH_TOKEN_FAILURE:
      return { ...state, error: action };

    case types.FETCH_LOGOUT_SUCCESS:
      return { ...state, token: null, userInfo: null };

    default:
      return state;
  }
};

export default loginReducer;
