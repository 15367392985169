import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../home.action';
import { getLanguage } from '../../../utils/params';
import { useLocation } from 'react-router-dom';
import { MaskGroup } from '../../../assets/images/web-isn';
import { useTranslation } from 'react-i18next';

const HomeEssentials = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const { items } = props?.home.listContent || [];
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchEssentialsRequest({ language, pageSize: 6, pageIndex: 1 });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="container essentials">
      <div className="row px-3 essentials-container">
        <div className="col-10 pt-3 bg-essentials">
          <h3 className={`title ${language === 'jp' ? 'font-noto-serif-cjk-jp' : ''}`}>{t('home_page.label_ij_essentials')}</h3>
          <div className="under-line my-3">
            <span className="middle-line"></span>
          </div>
          <p className="font-noto-sans-jp">{t('home_page.message_content')}</p>
          <div className="image-ess">
            <img src={MaskGroup} alt="mask-group"></img>
          </div>
          <div className="row d-sm-block d-md-flex mb-5">
            {items &&
              items.length > 0 &&
              items.map((blog, i) => (
                <div key={i} className="col-md-6 ml-auto mr-auto col-sm-10 mt-5 essential-item">
                  <div className="d-flex align-items-center">
                    <img
                      className="mr-4"
                      src={`${process.env.REACT_APP_ISN_IMAGE_URL}${blog.imageUrl}`}
                      alt="icon-essentials"
                    ></img>
                    <div className="description">
                      <div dangerouslySetInnerHTML={{ __html: blog.title }} className="title-ess mb-0"></div>
                      <p className="mb-0 content-ess">{blog.content}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

    </div>
  );
};
const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEssentialsRequest: actions.fetchEssentialsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeEssentials);
