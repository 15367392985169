import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getLanguage } from '../../../utils/params';
import { fetchFeaturedProjectsRequest } from '../home.action';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HomeProjectComponent = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const { items } = props.home.featuredProjects;
  const longImages = items ? items.slice(0, 1) : [];
  const shortImages = items ? items.slice(1, 5) : [];
  const history = useHistory();

  const gotoFeaturedProjects = () => {
    history.push(`/${language}/featured-projects`);
  };

  const gotoProjectsDetail = prjectDetail => {
    history.push(`${language}/featured-projects/detail/overview/${prjectDetail.id}/${prjectDetail.slug}?home=true`);
  };

  useEffect(() => {
    const cityId = localStorage.getItem('LOCATION_CITY')
      ? localStorage.getItem('LOCATION_CITY')
      : localStorage.getItem('DEFAULT_CITY');
    if (cityId) {
      props.fetchFeaturedProjectsRequest({ language, cityId, pageSize: 5, pageIndex: 1 });
    }
    //eslint-disable-next-line
  }, []);

  const handleFeaturedProjects = () => {
    if (items && items.length !== 0) {
      return (
        <div className="row featured-image d-block d-sm-flex">
          {longImages.map((element, index) => {
            return (
              <div onClick={() => gotoProjectsDetail(element)} key={index} className="col-12 col-sm-4 under-path">
                <img src={`${process.env.REACT_APP_ISN_IMAGE_URL}${element.imageUrl}`} alt="features"></img>
                <div className="text-bottom">
                  <span className="name text-truncate-box-title-name font-noto-sans-jp">{element.projectName}</span>
                  {element.quantity < 2 && (
                    <span className="listing font-noto-sans-jp">
                      {element.quantity} {t('home_page.listing')}
                    </span>
                  )}
                  {element.quantity >= 2 && (
                    <span className="listing font-noto-sans-jp">
                      {element.quantity} {t('home_page.listings')}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
          <div className="col-12 col-sm-8 d-flex flex-wrap px-0">
            {shortImages.map((element, index) => {
              return (
                <div onClick={() => gotoProjectsDetail(element)} className="col-12 mt-4 mt-sm-0 col-sm-6 under-path" key={index}>
                  <img src={`${process.env.REACT_APP_ISN_IMAGE_URL}${element.imageUrl}`} alt="features"></img>
                  <div className="text-bottom">
                    <span className="name text-truncate-box-title-name font-noto-sans-jp">{element.projectName}</span>
                    {element.quantity < 2 && (
                      <span className="listing font-noto-sans-jp">
                        {element.quantity} {t('home_page.listing')}
                      </span>
                    )}
                    {element.quantity >= 2 && (
                      <span className="listing font-noto-sans-jp">
                        {element.quantity} {t('home_page.listings')}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="featured-project py-5">
        <div className="container properties-title">
          <div className="col-12 service-apartment">
            <h3 className={`service-title ${language === 'jp' ? 'font-noto-serif-cjk-jp' : ''}`}>{t('home_page.label_featured_project')}</h3>
            <div className="under-line mt-3 mb-5 mx-auto">
              <span className="middle-line"></span>
            </div>
          </div>
          {handleFeaturedProjects()}
        </div>
        <div className="col-12 mt-5 text-center">
          <button onClick={() => gotoFeaturedProjects()} className="btn-showmore">
            {t('home_page.btn_showmore')}
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchFeaturedProjectsRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeProjectComponent);
