import * as types from './home.types';

const initialState = {
  blogs: [],
  featuredProjects: [],
  error: null,
  listBlogsHot: [],
  listContent: null,
  aboutUsContent: null,
  listServiced: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {

    // FETCH ESSENTIALS BY ID
    case types.FETCH_ESSENTIALS_REQUEST:
      return { ...state };
    case types.FETCH_ESSENTIALS_SUCCESS:
      return { ...state, listContent: action.data };
    case types.FETCH_ESSENTIALS_FAILURE:
      return { ...state, error: action.error };

    // FETCH ABOUT_US
    case types.FETCH_ABOUT_US_REQUEST:
      return { ...state };
    case types.FETCH_ABOUT_US_SUCCESS:
      return { ...state, aboutUsContent: action.data };
    case types.FETCH_ABOUT_US_FAILURE:
      return { ...state, error: action.error };

    // FETCH BLOGS HOT
    case types.FETCH_BLOGS_HOT_REQUEST:
      return { ...state };
    case types.FETCH_BLOGS_HOT_SUCCESS:
      return { ...state, listBlogsHot: action.data };
    case types.FETCH_BLOGS_HOT_FAILURE:
      return { ...state, error: action.error };

    // FETCH FEATURED_PROJECTS_REQUEST:
    case types.FETCH_FEATURED_PROJECTS_REQUEST:
      return { ...state };
    case types.FETCH_FEATURED_PROJECTS_SUCCESS:
      return { ...state, featuredProjects: action.data.data };
    case types.FETCH_FEATURED_PROJECTS_FAILURE:
      return { ...state, error: action.error };

    // FETCH SERVICED_REQUEST:
    case types.FETCH_SERVICED_REQUEST:
      return { ...state };
    case types.FETCH_SERVICED_SUCCESS:
      return { ...state, listServiced: action.data.data };
    case types.FETCH_SERVICED_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default homeReducer;
